// login.tsx
import { FormEvent, useState, ChangeEvent } from 'react';
import { setUserInLocalStorageWithResponseData } from '../../services';
import { loginUser } from '../../api/users/authentication';
import { Link, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import TypewriterComponent from 'typewriter-effect';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import ReCAPTCHA from 'react-google-recaptcha';

import logoNeuronLab from '../../logo-neuronlab.png';

const Login = () => {
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);

    const togglePasswordVisibility = () => {
        setShowPassword(prevShowPassword => !prevShowPassword);
    };

    const onRecaptchaChange = (value: string | null) => {
        setRecaptchaValue(value);
    };

    const isEmailValid = (email: string) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRegex.test(email);
    };

    const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const onFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (recaptchaValue === null) {
            setError('Por favor, complete a validação reCAPTCHA.');
            return;
        }

        const trimmedEmail = email.trim();
        const trimmedPassword = password.trim();

        if (trimmedEmail !== '' && trimmedPassword !== '') {
            if (!isEmailValid(trimmedEmail)) {
                setError('Por favor, insira um endereço de e-mail válido!');
                return;
            }

            try {
                const response = await loginUser({
                    email: trimmedEmail,
                    password: trimmedPassword,
                });

                if (response) {
                    setUserInLocalStorageWithResponseData(response.data);
                    navigate('/');
                }
            } catch (err) {
                console.log(err);
                if (err instanceof AxiosError && err.response) {
                    if (err.response.status === 404) {
                        setError('Usuário não existe. Verifique suas credenciais.');
                    } else if (err.response.status === 401) {
                        setError('Senha incorreta. Por favor, tente novamente.');
                    } else {
                        setError(err.response.data.error);
                    }
                } else if (err instanceof Error) {
                    setError(err.message);
                }
            }
        } else {
            setError('Por favor, preencha todos os campos!');
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-[#111827]">
            <div className="text-white font-bold py-20 text-left space-y-5 -mt-10 ml-10">
                <img
                    src={logoNeuronLab}
                    alt="Descrição da imagem"
                    className="h-200 w-60 object-cover mb-5"
                />
                <div className="text-xl sm:text-xl md:text-xl lg:text-7xl space-y-5 font-extrabold mt-7">
                    <h1>
                        Assistentes de IA
                        <br />
                        <span>
                            para
                            <div
                                className="gradient-container mt-2 text-black"
                                style={{
                                    background:
                                        'linear-gradient(to right, #7ad9de, #03989E)',
                                    display: 'inline-block',
                                    padding: '5px',
                                    borderRadius: '4px',
                                    marginLeft: '10px',
                                }}
                            >
                                <TypewriterComponent
                                    options={{
                                        strings: [
                                            'Classificar.',
                                            'Analisar.',
                                            'Extrair.',
                                            'Contar.',
                                            'Medir.',
                                            'Detectar.',
                                            'Otimizar.',
                                            'Agrupar.',
                                            'Alertar.',
                                        ],
                                        autoStart: true,
                                        loop: true,
                                    }}
                                />
                            </div>
                        </span>
                    </h1>
                </div>
            </div>
            <div className="w-full max-w-lg p-8 bg-[#192339] rounded-lg shadow-lg ml-14 mb-14 mt-14 mr-10">
                <h1 className="text-4xl font-semibold mb-6 text-[#B3D8D9] font-montserrat">
                    Faça seu login!
                </h1>
                {error && (
                    <p className="bg-red-400 text-white px-4 py-2 rounded mt-1 mb-4">
                        {error}
                    </p>
                )}
                <form onSubmit={onFormSubmit}>
                    <input
                        type="email"
                        placeholder="Email"
                        onChange={onEmailChange}
                        className="bg-white block w-full text-xl text-black py-3 px-4 rounded-lg shadow focus:outline-none focus:ring focus:border-emeraldo-300 font-montserrat"
                    />
                    <div className="relative w-full mt-4">
                        <input
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Senha"
                            onChange={onPasswordChange}
                            className="bg-white block w-full text-xl text-black py-3 px-4 rounded-lg shadow focus:outline-none focus:ring focus:border-emeraldo-300 font-montserrat"
                        />
                        <button
                            type="button"
                            onClick={togglePasswordVisibility}
                            className="absolute top-1/2 right-2 transform -translate-y-1/2 text-black text-sm mt-2 cursor-pointer"
                        >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}{' '}
                        </button>
                    </div>
                    <ReCAPTCHA
                        className="mt-6"
                        sitekey="6Ld39SwpAAAAAIImNGHiWCjHd3lyUTbiQ9cBAXQf"
                        onChange={onRecaptchaChange}
                    />

                    <div className="flex items-center mt-4 font-montserrat">
                        <input
                            type="submit"
                            value="Entrar"
                            className="bg-[#03989E] text-white py-2 px-6 rounded-lg font-semibold cursor-pointer transition duration-300 hover:bg-[#047B84]"
                        />

                        <span className="text-white ml-5 font-montserrat text-sm">
                            Não tem uma conta?
                        </span>
                        <Link
                            to="/signup"
                            className="ml-1 text-emerald-500 font-montserrat text-base"
                        >
                            Cadastre-se aqui!
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
