// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body,
html {
    padding: 0;
    margin: 0;
    height: 100%;
}

body {
    min-height: 100%;
}

main {
    height: auto;
}

html {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

* {
    box-sizing: border-box;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;IAEI,UAAU;IACV,SAAS;IACT,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;;AAGI;IAAA,kBAAe;IAAf;AAAe;;AAGnB;IACI,sBAAsB;AAC1B","sourcesContent":["body,\nhtml {\n    padding: 0;\n    margin: 0;\n    height: 100%;\n}\n\nbody {\n    min-height: 100%;\n}\n\nmain {\n    height: auto;\n}\n\nhtml {\n    @apply bg-white;\n}\n\n* {\n    box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
