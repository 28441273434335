import React, { useState, ChangeEvent, useEffect } from 'react';
import axios from 'axios';
import { Heading } from '../components/Global/Heading';
import { Sheet } from 'lucide-react';
import { Link } from 'react-router-dom';
import { IoMdAnalytics } from 'react-icons/io';// Import the specific icon you want to use
import { SlGraph } from 'react-icons/sl'
import { AiFillTags } from 'react-icons/ai'
import { TagPopup } from "./TagPopup"
import InputDialogPopup from './Global/InputDialogPopup';
import MessageDialogPopup from './Global/MessageDialogPopup';

import { REACT_APP_SERVER_URL, REACT_APP_API_URL_MS_SPREADSHEET } from '../config/keys';
import { getUserFromLocalStorage } from '../services';

interface UserInfo {
  userId: string;
  name: string;
  availableCredits: number;
}

interface TagToken {
  tag: string;
  token: string;
}

interface Tool {
  name: string;
  icon: JSX.Element; // SVG icon represented as JSX
  creditsRequired: number;
  onClick: () => void; // Function to handle when the button is clicked
}

const Spreadsheet: React.FC = () => {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [processedData, setProcessedData] = useState<any[]>([]);
  const [selectedFileIndex, setSelectedFileIndex] = useState(-1);
  const [currentPage, setCurrentPage] = useState(1);
  const [analyzing, setAnalyzing] = useState(false);
  const itemsPerPage = 10;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [tagTokens, setTagTokens] = useState<TagToken[]>([])

  const [openTagPopup, setOpenTagPopup] = useState<boolean>(false);
  
  const [isAskPopupOpen, setIsAskPopupOpen] = useState<boolean>(false);  
  const [askLoading, setAskLoading] = useState<boolean>(false);
  const [aiAnswer, setAiAnswer] = useState<string>("")


  const tools: Tool[] = [
    {
      name: 'Rotular',
      icon: <AiFillTags />,
      creditsRequired: 0,
      onClick: () => {
        //setIsPopupOpen(true);
        setOpenTagPopup(true);
      },
    },
    {
      name: 'Perguntar',
      icon: <IoMdAnalytics />,
      creditsRequired: 2,
      onClick: () => {
        setIsAskPopupOpen(true)
      },
    },
  ];

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setSelectedFiles(Array.from(files));
    }
  };

  const columnOptions =
    processedData.length > 0 && Object.keys(processedData[0]?.data['1']).map((column, index) => (
      <option key={index} value={column}>
        {processedData[0]?.data['1'][column]}
      </option>
    ));


  const handleUpload = async () => {
    const endpoint = `${REACT_APP_API_URL_MS_SPREADSHEET}/api/upload-excel/`;
    const token = 'f32x1v3mYTI7HzPwjqsq5FyINwNhxRugFHf6A8z61dE';

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('files', file);
    });

    try {
      setLoading(true);

      const response = await axios.post(endpoint, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data.data)
      setProcessedData(response.data.data);
    } catch (error) {
      console.error('Error in request:', error);
    } finally {
      setLoading(false);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems =
    processedData[selectedFileIndex]?.data?.data?.slice(
      indexOfFirstItem,
      indexOfLastItem
    ) || [];



  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');

    if (storedUserId) {
      axios
        .get<UserInfo>(`${REACT_APP_SERVER_URL}/api/users/infos/${JSON.parse(storedUserId)}`)
        .then((response) => {
          setUserInfo(response.data);
        })
        .catch((error) => {
          console.error('Error fetching user information:', error);
        });
    }
  }, []);

  const sendDataToEndpoint = async () => {
    try {
      const selectedData = {
        column1: selectedColumns[0], // Assume que selectedColumns[0] contém os dados da column1
        column2: selectedColumns[1]  // Assume que selectedColumns[1] contém os dados da column2
      };

      const endpoint = 'SEU_ENDPOINT_AQUI'; // Substitua pelo seu endpoint real
      const response = await axios.post(endpoint, selectedData);

      console.log('Dados enviados com sucesso:', response.data);
    } catch (error) {
      console.error('Erro ao enviar os dados:', error);
    }
  };

  // Dentro do bloco `isPopupOpen` após o clique em "Confirmar"
  <button
    className="bg-[#03989E] text-white font-montserrat font-medium py-2 px-4 rounded mr-4"
    onClick={() => {
      // Lógica para lidar com as colunas selecionadas e enviar para o endpoint
      console.log('Colunas selecionadas:', selectedColumns);
      sendDataToEndpoint();
      setIsPopupOpen(false);
    }}
  >
    Confirmar
  </button>

  const handleAsk = async (ask: string) => {
    setIsAskPopupOpen(false)
    const endpoint = `${REACT_APP_API_URL_MS_SPREADSHEET}/api/process_query/`;
    const token = 'f32x1v3mYTI7HzPwjqsq5FyINwNhxRugFHf6A8z61dE';

      
    //formData.append('data', processedData[0].data)
     const data = {
       question: ask,
        data: {...processedData[0].data}
     }         

    try {      
      setAskLoading(true)
      const response = await axios.post(endpoint, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      console.log(response.data)
      setAskLoading(false)
      setAiAnswer(response.data.result)      
      
    } catch (error) {
      alert("OCORREU O SEGUINTE ERRO: " + error)
        console.log(error)
    } finally {
      
    }
  }

  return (
    <div className="min-h-screen bg-[#111827] overflow-auto">
      <div className="mx-auto max-w-screen-xl h-full w-full">
        <div className="mt-1 mb-8 -ml-6">
          <div className="mb-12 ml-6 mt-14">
            <Link
              to="/"
              className="text-white hover:text-[#57B4B9] transition duration-300 flex items-center font-montserrat font-bold text-sm"
            >
              <span className="mr-1">&#8592;</span> Voltar
            </Link>
          </div>
          <Heading
            title="Planilhas"
            description="Assistentes de IA para planilhas!"
            icon={Sheet}
            iconColor="text-green-500"
            bgColor="bg-green-500/10"
          />
        </div>
        <div>
          <div className="bg-white border rounded-lg p-6 shadow-md mt-8 w-full mb-10">
            <div className="mb-4">
              <h2 className="text-lg font-medium mb-2 font-montserrat">
                Passo 1: Inserir Arquivos do Excel
              </h2>
              <p className="text-gray-600 font-montserrat">
                Selecione os arquivos do Excel que deseja analisar.
              </p>
              <input
                type="file"
                accept=".xlsx, xls"
                multiple
                className="mt-2 border rounded p-2 w-full font-montserrat"
                onChange={handleFileChange}
              />
            </div>
            <button
              className={`font-montserrat mt-1 bg-[#03989E] hover:bg-[#03989E]/90 text-white font-semibold py-2 px-4 rounded ${loading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              onClick={handleUpload}
              disabled={loading}
            >
              {loading ? (
                <svg
                  className="animate-spin h-5 w-5 mr-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018 8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                'Enviar'
              )}
            </button>
          </div>
        </div>
        {loading === false && processedData.length > 0 ? (
          <div className="bg-white border rounded-lg p-6 shadow-md mt-8 w-full mb-10">
            <div className="mb-4">
              <p className="text-gray-600 font-montserrat">Arquivos disponíveis:</p>
              <select
                className="mb-4 border rounded p-2 w-full mt-2 font-montserrat"
                value={selectedFileIndex}
                onChange={(e) => {
                  setSelectedFileIndex(Number(e.target.value));
                }}
              >
                <option className="font-montserrat" value={-1}>
                  Qual arquivo deseja analisar?
                </option>
                {processedData.map((fileData, index) => (
                  <option key={index} value={index}>
                    {fileData.filename}
                  </option>
                ))}
              </select>
            </div>
            {selectedFileIndex !== -1 && (
              <div className="overflow-x-auto min-h-20 max-h-96 border-gray-300">
                <table className="min-w-full bg-white ">
                  <thead>
                    <tr>
                      <th className="py-2 px-1 text-center font-montserrat text-xs font-medium" style={{ width: '1%' }}>Posição</th>
                      {Object.keys(processedData[0]?.data['1']).map((column, index) => (
                        <th
                          key={index}
                          className="py-2 px-4 bg-[#57B4B9] text-center text-xs border font-medium text-black font-montserrat uppercase"
                        >
                          {processedData[0]?.data['1'][column]}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(processedData[0]?.data).map((rowIndex) => {
                      if (rowIndex === '1') return null; // Skip the header row

                      const isEvenRow = parseInt(rowIndex) % 2 === 0;

                      return (
                        <tr key={rowIndex} className={isEvenRow ? 'bg-gray-50' : ''}>
                          <td className="py-1 px-1 border-r border-gray-300 text-center text-black text-sm font-medium font-montserrat">
                            {rowIndex}
                          </td>
                          {Object.keys(processedData[0]?.data[rowIndex]).map((key, cellIndex) => (
                            <td
                              key={cellIndex}
                              className={`py-2 px-4 max-h-20 overflow-hidden whitespace-no-wrap text-sm text-gray-700 font-montserrat border-b text-center ${isEvenRow ? 'bg-[#B3D8D9]/10' : 'bg-gray-300'
                                }`}
                            >
                              {typeof processedData[0]?.data[rowIndex][key] === 'object'
                                ? JSON.stringify(processedData[0]?.data[rowIndex][key])
                                : processedData[0]?.data[rowIndex][key]}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
            {selectedFileIndex !== -1 && (
              <div className="flex justify-center mt-6 space-x-4">
                {tools.map((tool, index) => (
                  <div key={index} className="text-center">
                    <button
                      className="flex flex-col items-center px-4 py-2 bg-[#03989E] text-white rounded-lg hover:bg-[#03989E]/90 transition duration-300"
                      onClick={tool.onClick}
                    >
                      {analyzing ? (
                        <p className="mt-1 text-white font-medium font-montserrat text-sm">
                          Estou pensando...
                        </p>
                      ) : (
                        <>
                          {tool.icon}
                          <span className="mt-1 text-white font-montserrat text-base">
                            {tool.name}
                          </span>
                          <span className="text-gray-200 font-montserrat text-xs">
                            {tool.creditsRequired} créditos
                          </span>
                        </>
                      )}
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : null}
      </div>
      {isPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="mb-4">
              <label htmlFor="column1" className="block font-montserrat font-medium mb-2">
                Selecione a coluna com os valores:
              </label>
              <select
                id="column1"
                className="border rounded p-2 w-full font-montserrat"
                onChange={(e) => {
                  const value = e.target.value;
                  setSelectedColumns((prevSelectedColumns) => [value, prevSelectedColumns[1]]);
                }}
              >
                <option value="">Selecione uma coluna</option>
                {Object.keys(processedData[0]?.data['1']).map((column, index) => (
                  <option key={index} value={column}>
                    {processedData[0]?.data['1'][column]}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label htmlFor="column2" className="block font-montserrat font-medium mb-2">
                Selecione a coluna com os rótulos:
              </label>
              <select
                id="column2"
                className="border rounded p-2 w-full font-montserrat"
                onChange={(e) => {
                  const value = e.target.value;
                  setSelectedColumns((prevSelectedColumns) => [prevSelectedColumns[0], value]);
                }}
              >
                <option value="">Selecione uma coluna</option>
                {Object.keys(processedData[0]?.data['1']).map((column, index) => (
                  <option key={index} value={column}>
                    {processedData[0]?.data['1'][column]}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex justify-between">
              <button
                className="bg-[#03989E] text-white font-montserrat font-medium py-2 px-4 rounded mr-4"
                onClick={() => {
                  // Lógica para lidar com as colunas selecionadas
                  console.log('Colunas selecionadas:', selectedColumns);
                  setIsPopupOpen(false);
                }}
              >
                Confirmar
              </button>
              <button
                className="bg-[#E53E3E] text-white font-montserrat font-medium py-2 px-4 rounded"
                onClick={() => setIsPopupOpen(false)}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}

      {openTagPopup && <TagPopup
        open={openTagPopup}
        setOpen={setOpenTagPopup}
        processedData={processedData}
        tagTokens={tagTokens}
        setTagTokens={setTagTokens}
      />}
       <InputDialogPopup
        open={isAskPopupOpen}
        title="Pergunte à planilha"
        inputLabel="Pergunta"
        //content={true}
        onSubmit={handleAsk}
        handleClose={() => setIsAskPopupOpen(false)}
      />
      <MessageDialogPopup
        loading={askLoading}
        open={askLoading || aiAnswer.length > 0}
        title="Planilhas"
        content={aiAnswer}
        handleClose={()=>{
          setAskLoading(false)
          setAiAnswer("")
        }}
      />
    </div>
  );
};

export default Spreadsheet;
