import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import { X } from 'lucide-react';
import { IoDocumentText } from 'react-icons/io5';
import { RiRobot2Line } from 'react-icons/ri';
import { IoChatboxEllipses } from 'react-icons/io5';
import axios from 'axios';
import coin from '../../src/coin.png';
import { FaCheckCircle, FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { SlDocs } from 'react-icons/sl';
import { MdOutlineNewLabel } from 'react-icons/md';
import { MdOutlineCancel } from 'react-icons/md';
import { FaRegCheckCircle } from 'react-icons/fa';
import { GoPlusCircle } from 'react-icons/go';
import { Download } from 'lucide-react';

import { REACT_APP_SERVER_URL } from '../config/keys';
import { getUserFromLocalStorage } from '../services';
import ConfirmationPopup from './Global/ConfirmationPopup';

interface UserInfo {
    userId: string;
    name: string;
    availableCredits: number;
}

interface InvoiceResponse {
    _id: string;
    name: string;
    content: string;
    createdAt: string;
    hasSummary: boolean;
}

interface DownloadData {
    taxId: string;
    name: string;
}

const Summarization: React.FC = () => {
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [inputValue, setInputValue] = useState('');

    const [companies, setCompanies] = useState<InvoiceResponse[]>([]);
    const [allCompanies, setAllCompanies] = useState<InvoiceResponse[]>([]);
    const [download, setDownload] = useState<DownloadData[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [kpisData, setKpisData] = useState<any>({});
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [copiedText, setCopiedText] = useState('');
    const [companyIdToDelete, setCompanyIdToDelete] = useState<string | null>(
        null
    );
    const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false); // Novo estado para controlar se o botão "Salvar" está habilitado
    const [downloading, setDownloading] = useState(false);
    const [isFolderSelected, setIsFolderSelected] = useState(true);
    const [folders, setFolders] = useState<string[]>([]);
    const [foldersSave, setFoldersSave] = useState<string[]>([]);
    const [documents, setDocuments] = useState<string[]>([]);
    const [loadingSummarize, setLoadingSummarize] = useState(false);
    const companiesPerPage = 3;
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [filteredCompanies, setFilteredCompanies] = useState<
        InvoiceResponse[]
    >([]);
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
    const [comparing, setComparing] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupDownload, setIsPopupDownload] = useState(false);
    const [showNewFolderInput, setShowNewFolderInput] = useState(false);

    const indexOfLastCompany = currentPage * companiesPerPage;
    const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
    const currentFilteredCompanies = filteredCompanies.slice(
        indexOfFirstCompany,
        indexOfLastCompany
    );

    const totalPages = Math.ceil(filteredCompanies.length / companiesPerPage);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedFilesCount, setSelectedFilesCount] = useState(0);
    const [folderName, setFolderName] = useState<string>('');

    const [analysisCompleted, setAnalysisCompleted] = useState(false);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [updatedUserCredits, setUpdatedUserCredits] = useState(0);
    const [executionTime, setExecutionTime] = useState(0);
    const [selectedFolder, setSelectedFolder] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState('');

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        setSelectedFiles(files);
        if (files) {
            setSelectedFilesCount(files.length);
            // Your existing code for handling file change
        }
    };

    useEffect(() => {
        const filtered = companies.filter(
            company =>
                company.name
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase()) ||
                (company.content &&
                    company.content
                        .toLowerCase()
                        .includes(searchFilter.toLowerCase()))
        );
        setFilteredCompanies(filtered);
    }, [companies, searchFilter]);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get<UserInfo>(
                    `${REACT_APP_SERVER_URL}/api/users/infos/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    setUserInfo(response.data);
                })
                .catch(error => {
                    console.error('Error fetching user information:', error);
                });
        }
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/summarization/folders/${JSON.parse(
                        storedUserId
                    )}`
                )

                .then(response => {
                    setFolders(response.data); // Supondo que a resposta seja diretamente o array
                })
                .catch(error => {
                    console.error('Error fetching folders:', error);
                });
        }
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/summarization/folders-save/${JSON.parse(
                        storedUserId
                    )}`
                )

                .then(response => {
                    setFoldersSave(response.data); // Supondo que a resposta seja diretamente o array
                })
                .catch(error => {
                    console.error('Error fetching folders:', error);
                });
        }
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/summarization/folders-save/${JSON.parse(
                        storedUserId
                    )}`
                )

                .then(response => {
                    setDocuments(response.data); // Supondo que a resposta seja diretamente o array
                })
                .catch(error => {
                    console.error('Error fetching folders:', error);
                });
        }
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();
        const formattedToday = `${dd}/${mm}/${yyyy}`;

        axios
            .get<DownloadData[]>(
                `${REACT_APP_SERVER_URL}/api/datastore/download-company-data/${JSON.parse(
                    storedUserId!
                )}?startDate=${formattedToday}`
            )
            .then(response => {
                setDownload(response.data);
            })
            .catch(error => {
                console.error('Error fetching user information:', error);
            });
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        axios
            .get<InvoiceResponse[]>(
                `${REACT_APP_SERVER_URL}/api/summarization/summarization-data/${JSON.parse(
                    storedUserId!
                )}`
            )
            .then(response => {
                setAllCompanies(response.data);
                setCompanies(response.data);
            })
            .catch(error => {
                console.error('Error fetching user information:', error);
            });
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/datastore/kpis-company-data/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    console.log('KPIs Data:', response.data);
                    setKpisData(response.data);
                })
                .catch(error => {
                    console.error('Error fetching KPIs data:', error);
                });
        }
    }, []);

    const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const handlePrevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleDeleteCompany = (companyId: string) => {
        setCompanyIdToDelete(companyId);
        setShowConfirmationPopup(true);
    };

    const handleCancelDelete = () => {
        setCompanyIdToDelete(null);
        setShowConfirmationPopup(false);
    };

    const handleConfirmDelete = async () => {
        try {
            console.log('Deleting Document with ID:', companyIdToDelete);
            await axios.delete(
                `${REACT_APP_SERVER_URL}/api/summarization/delete-summarization-data`,
                {
                    params: {
                        _id: companyIdToDelete,
                    },
                }
            );

            console.log('Document deleted successfully');
        } catch (error) {
            console.error('Error deleting company:', error);
        } finally {
            setCompanyIdToDelete(null);
            setShowConfirmationPopup(false);
            window.location.reload();
        }
    };

    const handleCopyClick = (text: string) => {
        navigator.clipboard.writeText(text);
        setCopiedText(`Texto copiado: ${text}`);

        // Limpar a mensagem após alguns segundos (opcional)
        setTimeout(() => {
            setCopiedText('');
        }, 3000);
    };

    const [showInputPopup, setShowInputPopup] = useState(false);

    const handleSearchClick = () => {
        if (!selectedFiles || selectedFiles.length === 0) {
            console.error('Nenhum arquivo selecionado.');
            return;
        }
        setShowInputPopup(true);
    };

    const handleConfirm = async () => {
        // Certifica-se de que selectedFiles não é null
        if (!selectedFiles) {
            console.error('Nenhum arquivo selecionado.');
            setShowInputPopup(false); // Fecha o popup se desejar manter a UX consistente
            return;
        }

        setShowInputPopup(false); // Fecha o popup
        const start = new Date().getTime();

        const user = getUserFromLocalStorage();
        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');
            try {
                setLoading(true);

                const formData = new FormData();
                // Já verificamos selectedFiles acima, então podemos usá-lo diretamente
                for (let i = 0; i < selectedFiles.length; i++) {
                    formData.append('pdfFiles', selectedFiles[i]);
                }

                // Inclui o valor do input no corpo da requisição
                formData.append('inputValue', inputValue);

                const endpoint = `${REACT_APP_SERVER_URL}/api/summarization/create?author_id=${userId}`;

                // Sua lógica de envio permanece inalterada
                const response = await axios.post(endpoint, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                const { availableCredits, updatedUserCredits } = response.data;

                setNumberOfPages(availableCredits);
                setUpdatedUserCredits(updatedUserCredits);

                setLoading(false);
                setAnalysisCompleted(true);

                const end = new Date().getTime();
                const elapsedTime = end - start;
                setExecutionTime(elapsedTime);
            } catch (error) {
                console.error('Erro durante o envio de arquivos:', error);
            } finally {
                setLoading(false);

                const end = new Date().getTime();
                const elapsedTime = end - start;
                setExecutionTime(elapsedTime);
            }
        }
    };

    const handleSearchClickOld = async () => {
        const start = new Date().getTime();

        const user = getUserFromLocalStorage();

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');

            try {
                if (!selectedFiles || selectedFiles.length === 0) {
                    console.error('Nenhum arquivo selecionado.');
                    return;
                }

                setLoading(true);

                const formData = new FormData();

                for (let i = 0; i < selectedFiles.length; i++) {
                    formData.append('pdfFiles', selectedFiles[i]);
                }

                const endpoint = `${REACT_APP_SERVER_URL}/api/summarization/create?author_id=${userId}`;

                const response = await axios.post(endpoint, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                const { availableCredits, updatedUserCredits } = response.data;

                setNumberOfPages(availableCredits);
                setUpdatedUserCredits(updatedUserCredits);

                setLoading(false);
                setAnalysisCompleted(true);

                const end = new Date().getTime();
                const elapsedTime = end - start;
                setExecutionTime(elapsedTime);
            } catch (error) {
                console.error('Erro durante o envio de arquivos:', error);

                setLoading(false);
            }
        }
    };

    const handleComparison = async (companyId: string) => {
        const user = getUserFromLocalStorage();

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');

            try {
                if (!selectedFiles || selectedFiles.length === 0) {
                    console.error('Nenhum arquivo selecionado.');
                    return;
                }

                setComparing(true);
                const formData = new FormData();

                for (let i = 0; i < selectedFiles.length; i++) {
                    formData.append('pdfFiles', selectedFiles[i]);
                }

                const endpoint = `${REACT_APP_SERVER_URL}/api/summarization/comparison?_id=${companyId}`;

                const response = await axios.post(endpoint, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                setComparing(false);

                // Abre uma nova aba com a estrutura HTML semelhante ao exemplo fornecido
                const { comparison } = response.data;

                const htmlContent = `
                    <html>
                        <head>
                            <title>Neuron Lab | Comparação de Documentos</title>
                            <style>
                                body {
                                    font-family: 'Montserrat', sans-serif;
                                    background-color: #111827;
                                    color: #ffffff;
                                    padding: 20px;
                                    line-height: 1.8;
                                }
                                h2 {
                                    color: #39A2A7;
                                    margin-bottom: 15px;
                                }
                                h4 {
                                    color: #D9D9D9;
                                    margin-bottom: 20px;
                                }
                                p {
                                    margin-bottom: 25px;
                                    font-size: 16px;
                                }
                            </style>
                        </head>
                        <body>
                            <h2>Comparação de Documentos</h2>
                            <div>
                                ${comparison.replace(/\n/g, '<br>')}
                            </div>
                        </body>
                    </html>
                `;

                // Verifica se newTab não é null antes de acessar suas propriedades
                const newTab = window.open();
                if (newTab) {
                    newTab.document.write(htmlContent);
                } else {
                    console.error(
                        'Não foi possível abrir a nova aba do navegador.'
                    );
                }
            } catch (error) {
                console.error('Erro durante o envio de arquivos:', error);
                setComparing(false);
            }
        }
    };

    const handleTabulation = async (companyId: string) => {
        try {
            setLoadingSummarize(true);

            // Assuming you have a function to get user data from local storage
            const user = getUserFromLocalStorage();

            if (user && user.userId) {
                setLoadingSummarize(true);

                const userId = user.userId.replace(/^"(.*)"$/, '$1');

                const response = await axios.post(
                    `${REACT_APP_SERVER_URL}/api/summarization/summarize/${companyId}?author_id=${userId}`
                );

                console.log('Resposta da sumarização:', response.data);

                window.location.reload();
            } else {
                console.error(
                    'Usuário não encontrado ou sem ID no armazenamento local.'
                );
            }
        } catch (error) {
            console.error('Erro ao chamar a API de sumarização:', error);
        } finally {
            setLoadingSummarize(false);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputText = e.target.value;
        const filteredText = inputText.replace(/[^a-zA-Z0-9\s]/g, ''); // Filtra apenas letras, números e espaços

        if (inputText !== filteredText) {
            setErrorMessage('Caracteres especiais não são permitidos.');
        } else {
            setErrorMessage('');
        }

        setFolderName(filteredText);
        setIsFolderSelected(filteredText.trim() !== '');
    };

    const handleSummarize = async (companyId: string, content: string) => {
        try {
            setLoadingSummarize(true);

            // Assuming you have a function to get user data from local storage
            const user = getUserFromLocalStorage();

            if (user && user.userId) {
                setLoadingSummarize(true);

                const userId = user.userId.replace(/^"(.*)"$/, '$1');

                const response = await axios.post(
                    `${REACT_APP_SERVER_URL}/api/summarization/summarize/${companyId}?author_id=${userId}`,
                    { text: content }
                );

                console.log('Resposta da sumarização:', response.data);

                window.location.reload();
            } else {
                console.error(
                    'Usuário não encontrado ou sem ID no armazenamento local.'
                );
            }
        } catch (error) {
            console.error('Erro ao chamar a API de sumarização:', error);
        } finally {
            setLoadingSummarize(false);
        }
    };

    const handleDownloadClick = (companyId: string) => {
        const user = getUserFromLocalStorage();

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');
            const authorId = encodeURIComponent(userId); // Certifique-se de codificar o authorId corretamente

            setDownloading(true);
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/summarization/download-sample/${companyId}?author_id=${authorId}`
                )
                .then(response => {
                    // Lógica para processar a resposta
                    console.log(
                        'Download realizado com sucesso:',
                        response.data
                    );

                    // Abre uma nova aba do navegador com os campos da resposta
                    const { createdAt, summary, name } = response.data;
                    setDownloading(false);

                    const htmlContent = `
                <html>
                    <head>
                        <title>Neuron Lab | Resumo Gerado</title>
                        <style>
                            body {
                                font-family: 'Montserrat', sans-serif;
                                background-color: #111827;
                                color: #ffffff;
                                padding: 20px;
                                line-height: 1.8;
                            }
                            h2 {
                                color: #39A2A7;
                                margin-bottom: 15px;
                            }
                            h4 {
                                color: #D9D9D9;
                                margin-bottom: 20px;
                            }
                            p {
                                margin-bottom: 25px;
                                font-size: 16px;
                            }
                        </style>
                    </head>
                    <body>
                        <h2>${name}</h2>
                        <h4>Data: ${createdAt}</h4>
                        <p>${summary.replace(/\n/g, '<br>')}</p>
                    </body>
                </html>
            `;

                    // Verifica se newTab não é null antes de acessar suas propriedades
                    const newTab = window.open();
                    if (newTab) {
                        newTab.document.write(htmlContent);
                    } else {
                        console.error(
                            'Não foi possível abrir a nova aba do navegador.'
                        );
                    }
                })
                .catch(error => {
                    console.error('Erro durante o download:', error);
                    setDownloading(false);
                });
        }
    };

    const handlePopupOkClick = () => {
        setAnalysisCompleted(false);
        window.location.reload();
    };

    const formatTime = (milliseconds: number) => {
        const minutes = Math.floor(milliseconds / 60000);
        const seconds = ((milliseconds % 60000) / 1000).toFixed(0);

        if (minutes === 0) {
            return `${seconds} segundos`;
        } else {
            return `${minutes} minutos ${seconds} segundos`;
        }
    };

    const navigate = useNavigate();

    const handleDocumentClick = (companyId: string) => {
        navigate(`/summarization-docs/${companyId}`);
    };
    const [questions, setQuestions] = useState([
        { question: '', responseFormat: '' },
    ]);
    const [description, setDescription] = useState('');
    const [previewData, setPreviewData] = useState<
        { question: string; responseFormat: string }[]
    >([]);
    const [successMessage, setSuccessMessage] = useState('');

    const handleAddQuestion = () => {
        setQuestions([...questions, { question: '', responseFormat: '' }]);
    };

    const handleQuestionChange = (
        index: number,
        field: string,
        value: string
    ) => {
        const newQuestions = questions.map((q, i) =>
            i === index ? { ...q, [field]: value } : q
        );
        setQuestions(newQuestions);
    };

    const handleDeleteQuestion = (index: number) => {
        const newQuestions = questions.filter((_, i) => i !== index);
        setQuestions(newQuestions);
    };

    const handleTestTemplate = () => {
        setPreviewData(questions);
    };

    const [nomeDoTemplate, setNomeDoTemplate] = useState(''); // Estado para armazenar o valor do campo "Nome do Template"

    const handleSave = async () => {
        const start = new Date().getTime();

        const user = getUserFromLocalStorage();

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');

            try {
                setLoading(true);

                const formattedQuestions = questions.reduce(
                    (acc, curr, index) => {
                        acc[`Pergunta${index + 1}: ${curr.question}`] =
                            curr.responseFormat;
                        return acc;
                    },
                    {} as { [key: string]: string }
                );

                const formData = {
                    nomeDoTemplate: nomeDoTemplate, // Adicionando o campo "Nome do Template"
                    description,
                    questions: formattedQuestions,
                };

                const endpoint = `${REACT_APP_SERVER_URL}/api/summarization/tabulation?author_id=${userId}`;

                const response = await axios.post(endpoint, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                setSuccessMessage('Template adicionado com sucesso!');

                const end = new Date().getTime();
                console.log('Tempo de execução:', end - start);
                console.log('Resposta do servidor:', response.data);
            } catch (error) {
                console.error('Erro durante o envio de arquivos:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (successMessage) {
            const timer = setTimeout(() => {
                setSuccessMessage('');
                window.location.reload(); // Recarregar a página após 3 segundos
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [successMessage]);

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    type HandleSearchClickDownloadProps = {
        selectedFolder: string;
        selectedTemplate: string;
    };

    const handleSearchClickDownload = async ({
        selectedFolder,
        selectedTemplate,
    }: HandleSearchClickDownloadProps) => {
        try {
            setLoading(true);

            const user = getUserFromLocalStorage();

            if (!user || !user.userId) {
                console.error('Usuário não autenticado.');
                return;
            }

            const userId = user.userId.replace(/^"(.*)"$/, '$1');

            if (!selectedFolder || !selectedTemplate) {
                console.error('Selecione uma pasta e um template.');
                return;
            }

            const requestData = {
                inputValue: selectedFolder,
                nomeDoTemplate: selectedTemplate,
            };

            const endpoint = `${REACT_APP_SERVER_URL}/api/summarization/download-tabulation?author_id=${userId}`;

            await axios.post(endpoint, requestData);

            // Aqui você pode adicionar lógica adicional, como fechar o modal após salvar
            setIsPopupDownload(false);
        } catch (error) {
            console.error('Erro durante o envio da requisição:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-[#111827] py-10">
            <div>
                <Link
                    to="/"
                    className="text-white hover:text-[#39A2A7]/80 font-semibold text-sm font-montserrat"
                >
                    &#8592; Voltar
                </Link>
            </div>
            <div className="flex mt-8 space-x-4 -ml-4">
                <div className="bg-[#1f2937] rounded-lg p-6  w-1/3 h-full">
                    <p className="text-2xl text-white font-semibold cursor-default font-montserrat rounded">
                        Assistente de IA para <br />
                        <span className="bg-[#02989E] px-2 py-1 animate-pulse font-montserrat rounded text-black text-2xl">
                            Documentos
                        </span>
                    </p>
                    <p className="mb-4 text-sm font-montserrat text-white mt-2">
                        Extraia resumos inteligentes de seus <br /> documentos
                        com facilidade.
                    </p>
                    <div className="flex flex-col items-center mb-2">
                        <label
                            htmlFor="fileInput"
                            className="cursor-pointer w-full"
                        >
                            <div
                                className="bg-[#1f2937] border-dashed border border-white py-2 px-4 rounded-md text-white flex items-center justify-center font-montserrat"
                                id="inputDocument"
                            >
                                Selecionar Documentos
                            </div>
                        </label>
                        <input
                            type="file"
                            accept=".pdf, .docx"
                            multiple
                            className="hidden"
                            id="fileInput"
                            onChange={handleFileChange}
                        />
                        {selectedFiles && selectedFiles.length > 0 && (
                            <p className="mt-2 text-base font-light font-montserrat text-yellow-300 italic">
                                {`${selectedFiles.length} ${
                                    selectedFiles.length === 1
                                        ? 'documento selecionado'
                                        : 'documentos selecionados'
                                }`}
                            </p>
                        )}
                    </div>
                    <button
                        onClick={() => handleSearchClick()}
                        id="search-button"
                        className={`mt-3 bg-[#111827] text-white px-6 py-2 rounded font-medium hover:bg-[#111827]/50 focus:outline-none focus:ring focus:border-blue-300 font-montserrat ${
                            loading ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                        disabled={loading}
                    >
                        <RiRobot2Line size={24} />
                    </button>

                    {loading && (
                        <div className="absolute inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                            <div className="bg-white border py-2 px-4 rounded-lg flex items-center space-x-2">
                                <RiRobot2Line className="w-6 h-6 text-[#00B3BE]" />
                                <span className="text-base text-gray-700 font-montserrat">
                                    Salvando...
                                </span>
                            </div>
                        </div>
                    )}

                    <button
                        onClick={() => {
                            setIsPopupOpen(true);
                        }}
                        id="search-button"
                        className={`mt-3 ml-4 bg-[#111827] text-white px-6 py-2 rounded font-medium hover:bg-[#111827]/50 focus:outline-none focus:ring focus:border-blue-300 font-montserrat ${
                            loading ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                        disabled={loading}
                    >
                        <MdOutlineNewLabel size={24} />
                    </button>

                    <button
                        onClick={() => {
                            setIsPopupDownload(true);
                        }}
                        id="download-button"
                        className={`mt-3 ml-4 bg-[#111827] text-white px-6 py-2 rounded font-medium hover:bg-[#111827]/50 focus:outline-none focus:ring focus:border-blue-300 font-montserrat ${
                            loading ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                        disabled={loading}
                    >
                        <Download size={24} />
                    </button>
                </div>
                <div>
                    <div
                        className="flex justify-center -mt-14 text-sm font-montserrat ml-5"
                        id="kpis"
                    >
                        <div>
                            <div className="mx-0 md:max-w-8xl lg:max-w-8xl xl:max-w-12xl">
                                <div>
                                    <div className="container mx-auto p-4 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-12">
                                        <div className="mt-0 mb-0">
                                            <input
                                                type="text"
                                                id="search"
                                                value={searchFilter}
                                                onChange={e =>
                                                    setSearchFilter(
                                                        e.target.value
                                                    )
                                                }
                                                placeholder="Pesquisar Documento..."
                                                className="w-[900px] ml-0 h-8 px-4 pr-10 text-sm placeholder-gray-400 bg-[#1f2937] border rounded focus:outline-none focus:border-[#39A2A7] text-white"
                                            />
                                        </div>
                                        <div
                                            className="w-[900px] -mt-14"
                                            id="companies"
                                        >
                                            {currentFilteredCompanies.length >
                                            0 ? (
                                                currentFilteredCompanies.map(
                                                    company => (
                                                        <div
                                                            key={company._id}
                                                            className="bg-[#1f2937]  text-white rounded-lg p-6 cursor-pointer mr-0 w-full h-auto mb-2 mt-6"
                                                        >
                                                            <div className="flex items-center justify-between">
                                                                <div className="cursor-pointer flex items-center font -mt-6 -mb-2 -ml-11">
                                                                    <IoDocumentText
                                                                        size={
                                                                            40
                                                                        }
                                                                        className="text-yellow-500 mb-0 ml-0"
                                                                    />
                                                                    <p className="text-sm mb-0 overflow-hidden overflow-ellipsis max-w-full whitespace-nowrap ml-1">
                                                                        <span className="text-sm"></span>
                                                                        <strong className="font-bold text-white text-sm font-montserrat px-2 py-1 rounded-full">
                                                                            {company
                                                                                .name
                                                                                .length >
                                                                            50
                                                                                ? company.name.substring(
                                                                                      0,
                                                                                      50
                                                                                  ) +
                                                                                  '...'
                                                                                : company.name}
                                                                        </strong>
                                                                    </p>

                                                                    <button
                                                                        className={`mt-0 ml-2 mr-2 py-2 px-4 rounded-md text-white flex items-center justify-center font-montserrat ${
                                                                            selectedFilesCount >
                                                                            0
                                                                                ? ''
                                                                                : 'cursor-not-allowed opacity-50'
                                                                        }`}
                                                                        onClick={() =>
                                                                            handleComparison(
                                                                                company._id
                                                                            )
                                                                        }
                                                                        disabled={
                                                                            selectedFilesCount ===
                                                                                0 ||
                                                                            comparing
                                                                        }
                                                                    >
                                                                        <div
                                                                            className=" py-2 px-4 rounded-md text-white flex items-center justify-center font-montserrat -ml-6"
                                                                            id="inputDocument"
                                                                        >
                                                                            <SlDocs
                                                                                size={
                                                                                    24
                                                                                }
                                                                                className="text-[#02989E] hover:text-[#02989E] mb-0 mr-1"
                                                                            />
                                                                            <span className="text-sm text-gray-200 font-montserrat mr-0 ml-0">
                                                                                {comparing
                                                                                    ? 'Aguarde...'
                                                                                    : 'Comparar'}
                                                                            </span>
                                                                        </div>
                                                                    </button>
                                                                    <div
                                                                        className=" py-2 px-4 rounded-md text-white flex items-center justify-center font-montserrat -ml-10"
                                                                        id="chat"
                                                                    >
                                                                        <IoChatboxEllipses
                                                                            size={
                                                                                24
                                                                            }
                                                                            className="text-[#02989E] hover:text-[#02989E] mb-0 mr-1"
                                                                        />
                                                                        <span
                                                                            className="text-sm text-gray-200 font-montserrat mr-0 ml-0"
                                                                            onClick={() =>
                                                                                handleDocumentClick(
                                                                                    company._id
                                                                                )
                                                                            }
                                                                        >
                                                                            Perguntar
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <X
                                                                    size={24}
                                                                    className="cursor-pointer text-red-500 hover:text-red-700 mb-0 -mt-2"
                                                                    onClick={() =>
                                                                        handleDeleteCompany(
                                                                            company._id
                                                                        )
                                                                    }
                                                                />
                                                            </div>

                                                            <hr className="my-2 border-t border-gray-300 mt-" />
                                                            <div className="relative">
                                                                <div className="flex items-center">
                                                                    <div className="w-full mt-2">
                                                                        <div className="flex items-center space-x-2 ">
                                                                            <div className="flex items-center space-x-2">
                                                                                <span className="font-montserrat font-bold text-white text-sm">
                                                                                    Texto:{' '}
                                                                                </span>
                                                                                <div
                                                                                    className="border border-solid border-gray-400 p-2 mb-2 mt-0 rounded bg-[#1f2937] opacity-0.3"
                                                                                    style={{
                                                                                        overflowY:
                                                                                            'auto',
                                                                                        maxHeight:
                                                                                            '200px',
                                                                                    }}
                                                                                >
                                                                                    <p
                                                                                        className="text-white text-xs font-montserrat mb-2"
                                                                                        onClick={() =>
                                                                                            handleCopyClick(
                                                                                                `Atividade 1: ${
                                                                                                    company.content ||
                                                                                                    'N/A'
                                                                                                }`
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {company.content ||
                                                                                            'N/A'}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex mt-2">
                                                                            <div>
                                                                                {' '}
                                                                                <p className="text-sm mb-0 overflow-hidden overflow-ellipsis max-w-full whitespace-nowraptext-gray-400">
                                                                                    <span className="text-sm text-gray-400">
                                                                                        Data:{' '}
                                                                                    </span>
                                                                                    <strong className="text-sm">
                                                                                        {
                                                                                            company.createdAt
                                                                                        }
                                                                                    </strong>
                                                                                </p>
                                                                            </div>

                                                                            <div>
                                                                                <button
                                                                                    className={`ml-[550px] text-center bg-[#111827] py-2 px-4 rounded-md text-white flex items-center justify-center font-montserrat ${
                                                                                        company.hasSummary
                                                                                            ? 'cursor-not-allowed opacity-50'
                                                                                            : ''
                                                                                    }`}
                                                                                    onClick={() =>
                                                                                        !company.hasSummary &&
                                                                                        !loadingSummarize &&
                                                                                        handleSummarize(
                                                                                            company._id,
                                                                                            company.content
                                                                                        )
                                                                                    }
                                                                                    disabled={
                                                                                        company.hasSummary ||
                                                                                        loading
                                                                                    }
                                                                                >
                                                                                    {loadingSummarize
                                                                                        ? 'Aguarde...'
                                                                                        : 'Resumir'}
                                                                                </button>
                                                                            </div>

                                                                            <div>
                                                                                <button
                                                                                    className={`mt-0 ml-2 mr-2 bg-[#111827] py-2 px-4 rounded-md text-white flex items-center justify-center font-montserrat ${
                                                                                        !company.hasSummary
                                                                                            ? 'cursor-not-allowed opacity-50'
                                                                                            : ''
                                                                                    }`}
                                                                                    onClick={() =>
                                                                                        handleDownloadClick(
                                                                                            company._id
                                                                                        )
                                                                                    }
                                                                                    disabled={
                                                                                        !company.hasSummary
                                                                                    }
                                                                                >
                                                                                    {downloading
                                                                                        ? 'Aguarde...'
                                                                                        : 'Abrir'}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                            ) : (
                                                <div className="text-white text-center mt-6">
                                                    Nenhum Documento adicionado
                                                </div>
                                            )}
                                            {copiedText && (
                                                <div className="fixed bottom-4 right-4 bg-[#03689E] text-white font-semibold px-4 py-2 rounded">
                                                    {copiedText}
                                                </div>
                                            )}
                                            {currentFilteredCompanies.length >
                                                0 && (
                                                <div className="flex justify-center mt-8  text-gray-100 text-sm font-montserrat">
                                                    <button
                                                        onClick={handlePrevPage}
                                                        disabled={
                                                            currentPage === 1
                                                        }
                                                    >
                                                        Anterior
                                                    </button>
                                                    <span className="mx-2 text-gray-100 text-xs font-montserrat">
                                                        {currentPage}
                                                    </span>
                                                    <button
                                                        onClick={handleNextPage}
                                                        disabled={
                                                            currentPage ===
                                                            totalPages
                                                        }
                                                    >
                                                        Próxima
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <>
                {analysisCompleted && (
                    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-75">
                        <div className="bg-white rounded-lg shadow-md overflow-hidden w-96">
                            <div className="px-6 py-4 bg-[#02989E] text-white flex justify-between items-center">
                                <div className="flex items-center">
                                    <FaCheckCircle size={24} className="mr-2" />
                                    <h2 className="text-2xl font-semibold font-montserrat">
                                        Tarefa Concluída
                                    </h2>
                                </div>
                            </div>
                            <div className="px-6 py-4">
                                <p className="text-gray-400 text-2xl font-bold mb-2 font-montserrat">
                                    Relatório
                                </p>
                                <div className="flex items-center mb-0">
                                    <p className="text-gray-700 mr-2 text-lg font-montserrat">
                                        Créditos Utilizados:
                                    </p>
                                    <p className="text-gray-800 font-semibold font-montserrat text-lg">
                                        {numberOfPages}
                                    </p>
                                </div>
                                <div className="flex items-center mb-4">
                                    <p className="text-gray-700 mr-2 text-lg font-montserrat">
                                        Duração:
                                    </p>
                                    <p className="text-gray-800 font-semibold font-montserrat text-lg">
                                        {formatTime(executionTime)}
                                    </p>
                                </div>

                                <div className="flex items-center mb-6">
                                    <img
                                        src={coin}
                                        alt="Moeda"
                                        className="w-10 h-10 mr-2"
                                    />
                                    <p className="text-gray-700 mr-2 font-montserrat text-xl">
                                        Créditos Disponíveis:
                                    </p>
                                    <p className="text-gray-800 font-semibold font-montserrat text-lg">
                                        {updatedUserCredits}
                                    </p>
                                </div>
                                <div className="flex justify-end">
                                    <button
                                        className="text-white bg-[#02989E] px-6 py-2 rounded-md hover:bg-[#02807a] font-semibold focus:outline-none font-montserrat"
                                        onClick={handlePopupOkClick}
                                    >
                                        Ok
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
            {showConfirmationPopup && (
                <ConfirmationPopup
                    message="Tem certeza de que deseja excluir este documento?"
                    onCancel={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />
            )}

            {isPopupOpen && (
                <>
                    <div className="fixed inset-0 flex items-center justify-center bg-[#192339] bg-opacity-85">
                        <div className="relative w-full max-w-3xl p-6 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339] text-gray-300">
                            <h2 className="text-[#57B4B9] font-montserrat text-2xl font-semibold mb-4">
                                Nome do Template
                            </h2>
                            <input
                                type="text"
                                value={nomeDoTemplate} // Ligando o valor do campo ao estado
                                onChange={e =>
                                    setNomeDoTemplate(e.target.value)
                                } // Atualizando o estado com o valor do campo
                                className="w-full mb-4 px-4 py-2 rounded-lg bg-gray-700 text-gray-300 font-montserrat"
                                placeholder="Nome do Template"
                            />
                            <h2 className="text-[#57B4B9] font-montserrat text-2xl font-semibold mb-4">
                                Descrição
                            </h2>
                            <input
                                type="text"
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                                className="w-full mb-6 px-4 py-2 rounded-lg bg-gray-700 text-gray-300 font-montserrat"
                                placeholder="Descrição"
                            />
                            <div className="border-t border-gray-500 pt-4 mb-4">
                                <h3 className="text-[#57B4B9] font-montserrat text-xl font-semibold mb-2">
                                    Perguntas
                                </h3>
                                {questions.map((q, index) => (
                                    <div
                                        key={index}
                                        className="flex items-center mb-2"
                                    >
                                        <input
                                            type="text"
                                            value={q.question}
                                            onChange={e =>
                                                handleQuestionChange(
                                                    index,
                                                    'question',
                                                    e.target.value
                                                )
                                            }
                                            placeholder="Pergunta"
                                            className="w-3/4 px-4 py-2 mr-2 rounded-lg bg-gray-700 text-gray-300 font-montserrat"
                                        />
                                        <input
                                            type="text"
                                            value={q.responseFormat}
                                            onChange={e =>
                                                handleQuestionChange(
                                                    index,
                                                    'responseFormat',
                                                    e.target.value
                                                )
                                            }
                                            placeholder="Resposta"
                                            className="w-1/4 px-4 py-2 mr-2 rounded-lg bg-gray-700 text-gray-300 font-montserrat"
                                        />
                                        <button
                                            onClick={() =>
                                                handleDeleteQuestion(index)
                                            }
                                            className="text-red-500 hover:text-red-700"
                                        >
                                            <FaTrash />
                                        </button>
                                    </div>
                                ))}
                                <button
                                    onClick={handleAddQuestion}
                                    className="w-full px-4 py-2 mt-2 text-gray-300 bg-[#2a3e5e] hover:bg-[#192339] hover:border-[#00B3BE] rounded-lg font-montserrat"
                                >
                                    Adicionar Nova Pergunta
                                </button>
                            </div>
                            <div className="border-t border-gray-500 pt-4 mb-4">
                                <h3 className="text-[#57B4B9] font-montserrat text-xl font-semibold mb-2">
                                    Pré-visualização dos Dados
                                </h3>
                                <div className="w-full h-32 bg-gray-700 rounded-lg text-center flex items-center justify-center text-gray-500 font-montserrat">
                                    {previewData.length > 0 ? (
                                        <ul className="list-disc list-inside">
                                            {previewData.map((data, index) => (
                                                <li key={index}>
                                                    {data.question} -{' '}
                                                    {data.responseFormat}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        'Pré-visualização dos Dados'
                                    )}
                                </div>
                            </div>
                            <div className="flex justify-between mt-4 border-t border-gray-500 pt-4">
                                <button
                                    onClick={handleClosePopup}
                                    className="flex items-center px-6 py-3 text-white bg-red-600 hover:bg-red-700 rounded-lg font-montserrat text-base font-semibold"
                                >
                                    <MdOutlineCancel className="w-6 h-6 mr-2" />
                                    Cancelar
                                </button>
                                <button
                                    onClick={handleTestTemplate}
                                    className="flex items-center px-6 py-3 text-white bg-green-600 hover:bg-green-700 rounded-lg font-montserrat text-base font-semibold"
                                >
                                    Testar Template
                                </button>
                                <button
                                    onClick={handleSave}
                                    className="flex items-center px-6 py-3 text-white bg-blue-600 hover:bg-blue-700 rounded-lg  font-montserrat text-base font-semibold"
                                >
                                    <FaRegCheckCircle className="w-6 h-6 mr-2" />
                                    Salvar
                                </button>
                            </div>
                            {loading && (
                                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                                    Loading...
                                </div>
                            )}
                        </div>
                    </div>
                    {successMessage && (
                        <div className="absolute bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded">
                            {successMessage}
                        </div>
                    )}
                </>
            )}
            {isPopupDownload && (
                <div className="fixed inset-0 flex items-center justify-center bg-[#192339] bg-opacity-85">
                    <div className="relative w-full max-w-lg p-6 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339]">
                        <h2 className="text-[#57B4B9] font-montserrat text-2xl font-semibold mb-1">
                            Fazer Download
                        </h2>
                        <p className="text-gray-300 font-montserrat text-sm mb-6">
                            Faça o download dos dados em formato Excel
                        </p>
                        <div className="">
                            <label
                                htmlFor="folderInput"
                                className="block mb-2 text-white font-montserrat text-lg font-bold"
                            >
                                Selecione a pasta que deseja analisar
                            </label>
                            <select
                                id="existingFolders"
                                className="mb-4 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 bg-white leading-tight focus:outline-none focus:shadow-outline font-montserrat focus:border-gray-300 focus:ring focus:ring-gray-200"
                                value={selectedFolder}
                                onChange={e =>
                                    setSelectedFolder(e.target.value)
                                }
                            >
                                <option value="">Selecione a pasta</option>
                                {documents.map(document => (
                                    <option key={document} value={document}>
                                        {document}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="">
                            <label
                                htmlFor="folderInput"
                                className="block mb-2 text-white font-montserrat text-lg font-bold"
                            >
                                Selecione um template existente
                            </label>
                            <select
                                id="existingTemplates"
                                className="mb-4 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 bg-white leading-tight focus:outline-none focus:shadow-outline font-montserrat focus:border-gray-300 focus:ring focus:ring-gray-200"
                                value={selectedTemplate}
                                onChange={e =>
                                    setSelectedTemplate(e.target.value)
                                }
                            >
                                <option value="">Selecione o template</option>
                                {folders.map(folder => (
                                    <option key={folder} value={folder}>
                                        {folder}
                                    </option>
                                ))}
                            </select>

                            <div className="flex items-center">
                                <button
                                    className="text-blue-500 hover:text-blue-700 focus:outline-none"
                                    onClick={() =>
                                        setShowNewFolderInput(
                                            !showNewFolderInput
                                        )
                                    }
                                >
                                    <GoPlusCircle className="w-6 h-6 text-[#03989E]" />
                                </button>
                                <span className="ml-2 font-montserrat text-white text-sm font-medium">
                                    ou repita downloads anteriores
                                </span>
                            </div>

                            {showNewFolderInput && (
                                <div className="mt-4">
                                    <input
                                        type="text"
                                        id="folderInput"
                                        className="w-full p-3 border border-gray-400 rounded-lg focus:outline-none focus:border-gray-300 focus:ring focus:ring-gray-200 font-montserrat"
                                        placeholder="Digite aqui o nome da pasta"
                                        value={folderName}
                                        onChange={handleInputChange}
                                    />
                                    {errorMessage && (
                                        <p className="text-red-500 italic mt-2 font-montserrat text-base">
                                            {errorMessage}
                                        </p>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="flex justify-between mt-10">
                            <button
                                onClick={() => setIsPopupDownload(false)}
                                className="flex items-center px-6 py-3 text-white bg-red-600 hover:bg-red-700 rounded-lg font-montserrat text-base font-semibold"
                            >
                                <MdOutlineCancel className="w-6 h-6 mr-2" />
                                Cancelar
                            </button>
                            <button
                                onClick={() =>
                                    handleSearchClickDownload({
                                        selectedFolder,
                                        selectedTemplate,
                                    })
                                }
                                disabled={!selectedFolder || !selectedTemplate}
                                className={`flex items-center px-6 py-3 text-white ${
                                    !selectedFolder || !selectedTemplate
                                        ? 'bg-[#0E1621] rounded-lg cursor-not-allowed opacity-50'
                                        : 'bg-blue-600 hover:bg-blue-700'
                                } rounded-lg px-10 py-3 font-montserrat text-base font-semibold`}
                            >
                                <FaRegCheckCircle className="w-6 h-6 mr-2" />
                                Salvar
                            </button>
                        </div>
                        {loading && (
                            <div className="absolute inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
                                <div className="bg-white border py-2 px-4 rounded-lg flex items-center space-x-2">
                                    <RiRobot2Line className="w-6 h-6 text-[#00B3BE]" />
                                    <span className="text-base text-gray-700 font-montserrat">
                                        Salvando...
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {showInputPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-[#192339] bg-opacity-85">
                    <div className="relative w-full max-w-lg p-6 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339]">
                        <h2 className="text-[#57B4B9] font-montserrat text-2xl font-semibold mb-1">
                            Selecionar Pasta
                        </h2>
                        <p className="text-gray-300 font-montserrat text-sm mb-4">
                            Escolha uma pasta existente ou insira uma nova pasta
                        </p>
                        <div className="">
                            <label
                                htmlFor="existingFolders"
                                className="block text-white text-lg font-montserrat mb-2 font-semibold"
                            >
                                Pastas Existentes:
                            </label>
                            <select
                                id="existingFolders"
                                className="mb-4 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 bg-white leading-tight focus:outline-none focus:shadow-outline font-montserrat"
                                onChange={e => {
                                    setInputValue(e.target.value);
                                    setIsFolderSelected(e.target.value !== '');
                                }}
                            >
                                <option value="">
                                    Selecione uma pasta existente
                                </option>
                                {foldersSave.map(folder => (
                                    <option key={folder} value={folder}>
                                        {folder}
                                    </option>
                                ))}
                            </select>
                            <label
                                htmlFor="inputValue"
                                className="block text-white text-lg font-montserrat mb-2 font-semibold"
                            >
                                {isFolderSelected
                                    ? 'A pasta selecionada foi:'
                                    : 'Insira uma nova pasta:'}
                            </label>
                            <input
                                type="text"
                                value={inputValue}
                                onChange={e => setInputValue(e.target.value)}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline font-montserrat"
                                id="inputValue"
                            />
                        </div>
                        <div className="flex justify-end mt-6">
                            <button
                                onClick={() => setShowInputPopup(false)}
                                className="mr-2 flex items-center px-6 py-3 bg-red-600 hover:bg-red-700 text-white rounded-lg font-montserrat text-base font-semibold transition-colors duration-150"
                            >
                                <MdOutlineCancel className="w-6 h-6 mr-2" />
                                Cancelar
                            </button>
                            <button
                                disabled={!inputValue}
                                onClick={() => inputValue && handleConfirm()}
                                className={`flex items-center px-6 py-3 text-white rounded-lg font-montserrat text-base font-semibold transition-colors duration-150 ${
                                    inputValue
                                        ? 'bg-[#02989E] hover:bg-[#02989E]/90'
                                        : 'bg-gray-500 cursor-not-allowed'
                                }`}
                            >
                                <FaRegCheckCircle className="w-6 h-6 mr-2" />
                                Salvar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Summarization;
