import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts'; // Import ReactApexChart
import { REACT_APP_SERVER_URL } from '../config/keys';
import { CreditCard, User } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import coin from '../../src/coin.png';

interface UserInfo {
  name: string;
  email: string;
  companyName: string;
  availableCredits: number;
}

const Settings: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [editedUserInfo, setEditedUserInfo] = useState<UserInfo>({
    name: '',
    email: '',
    companyName: '',
    availableCredits: 0, // You can set the initial value as needed
  });

  const history = useNavigate();

  const [chartData, setChartData] = useState<any>({
    options: {
      colors: ['#39A2A7'],
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: 'Créditos Por Dia',
        },
      },
    },
    series: [
      {
        name: 'Créditos Por Dia',
        data: [],
      },
    ],
  });

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');

    axios
      .get<UserInfo>(`${REACT_APP_SERVER_URL}/api/users/settings/${JSON.parse(storedUserId!)}`)
      .then((response) => {
        setUserInfo(response.data);
        setEditedUserInfo(response.data); // Initialize editedUserInfo with user data
      })
      .catch((error) => {
        console.error('Error fetching user information:', error);
      });
  }, []);

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    
    axios
    .get(`${REACT_APP_SERVER_URL}/api/users/credit-history/${JSON.parse(storedUserId!)}/credits-by-day/`)
    .then((response) => {
      const data = response.data;

      const dates = data.map((item: any) => item._id);
      const credits = data.map((item: any) => item.totalCredits);

      console.log('Dates:', dates);
      console.log('Credits:', credits);

      setChartData((prevChartData: any) => ({
        ...prevChartData,
        options: {
          ...prevChartData.options,
          xaxis: {
            categories: dates,
          },
        },
        series: [
          {
            data: credits,
          },
        ],
      }));
    })
    .catch((error) => {
      console.error('Error fetching credit history:', error);
    });
}, [userId]);

  const handleSaveProfile = () => {
    const storedUserId = localStorage.getItem('userId');
  
    axios
      .put(`${REACT_APP_SERVER_URL}/api/users/update-profile/${JSON.parse(storedUserId!)}`, editedUserInfo)
      .then((response) => {
        // Handle the response if needed
        console.log('Perfil atualizado com sucesso:', response.data);
        setIsEditing(false); // Turn off editing mode after successful update
        window.location.reload(); // Reload the page to update input values
      })
      .catch((error) => {
        console.error('Erro ao atualizar o perfil:', error);
      });
  };
  

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setEditedUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      [name]: value,
    }));
  };

  const handleLogout = () => {
    // Add your logout logic here
  };

  return (
    <div className="min-h-screen bg-[#111827] py-10">
      <div className="container mx-auto p-4 bg-white rounded-lg shadow-lg">
        <div className="flex justify-between items-center mb-8">
          <div>
            <Link
              to="/"
              className="text-[#03989E] hover:text-[#39A2A7] font-semibold text-sm font-montserrat"
            >
              &#8592; Voltar
            </Link>
          </div>
          <div className="flex space-x-2">
            <button
                onClick={() => {
                    history('/payment');
                }}
                className="bg-white text-[#03989E] text-base font-bold font-montserrat py-4 px-4 rounded-lg hover:text-[#03989E]/90 transition duration-300 ease-in-out"
                style={{ height: '30px' }}
                >
            <CreditCard className="h-7 w-7" />
            <span className="font-montserrat text-lg">Pagamento</span>
            </button>
            <button
              onClick={() => setIsEditing(!isEditing)} // Toggle editing mode
              className="bg-white text-[#000000] text-base font-bold font-montserrat py-4 px-4 rounded-lg transition duration-300 ease-in-out"
              style={{ height: '30px' }}
            >
              <User className="h-7 w-7" />
              <span className="font-montserrat text-lg text-blue">
                {isEditing ? 'Cancelar' : 'Editar Perfil'}
              </span>
            </button>
            {isEditing && (
              <button
                onClick={handleSaveProfile}
                className="bg-white text-[#000000] text-base font-bold font-montserrat py-4 px-4 rounded-lg transition duration-300 ease-in-out"
                style={{ height: '30px' }}
              >
                <User className="h-7 w-7" />
                <span className="font-montserrat text-lg">Salvar</span>
              </button>
            )}
          </div>
        </div>
        <div className="text-gray-800">
          {userInfo ? (
            <div>
              <h2 className="text-2xl font-semibold font-montserrat mb-4">
                Informações Pessoais
              </h2>
              <div className="mb-4">
                <label className="text-xl font-semibold font-montserrat">Nome:</label>
                <input
                  type="text"
                  name="name"
                  className={`bg-gray-200 w-full text-lg py-2 px-3 rounded-lg shadow mt-2 font-montserrat ${
                    isEditing ? 'border border-[#39A2A7]' : 'border-none'
                  }`}
                  value={isEditing ? editedUserInfo.name : userInfo.name}
                  readOnly={!isEditing}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-4">
                <label className="text-xl font-semibold font-montserrat">Email:</label>
                <input
                  type="text"
                  name="email"
                  className={`bg-gray-200 w-full text-lg py-2 px-3 rounded-lg shadow mt-2 font-montserrat ${
                    isEditing ? 'border border-[#39A2A7]' : 'border-none'
                  }`}
                  value={isEditing ? editedUserInfo.email : userInfo.email}
                  readOnly={!isEditing}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-1 flex items-center">
                <img src={coin} alt="Moeda" className="w-9 h-9 mr-2" />
                <p className="text-2xl font-semibold font-montserrat text-[#39A2A7]">
                  Créditos Disponíveis: {userInfo.availableCredits}
                </p>
              </div>
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
      <div className="container mx-auto p-4 bg-white rounded-lg shadow-lg mt-4">
        <h2 className="text-2xl font-semibold font-montserrat mb-2">
          Gráfico de Créditos Por Dia
        </h2>
        <div className="w-1/2">
          <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height="250" />
        </div>
      </div>
    </div>
  );
};

export default Settings;
