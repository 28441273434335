import React, { useState, ChangeEvent, useEffect } from 'react';
import axios from 'axios';
import { Heading } from '../components/Global/Heading';
import { Sheet } from 'lucide-react';
import { Link } from 'react-router-dom';
import { AiOutlineTool } from 'react-icons/ai'; 
import { IoMdAnalytics } from 'react-icons/io';// Import the specific icon you want to use
import { SlGraph } from 'react-icons/sl'

import { REACT_APP_SERVER_URL, REACT_APP_API_URL_MS_SPREADSHEET } from '../config/keys';
import { getUserFromLocalStorage } from '../services';
import TypingEffect from '../components/TypingEffect';

interface UserInfo {
  userId: string;
  name: string;
  availableCredits: number;
}

interface Tool {
  name: string;
  icon: JSX.Element; // SVG icon represented as JSX
  creditsRequired: number;
  onClick: () => void; // Function to handle when the button is clicked
}

const SheetPage: React.FC = () => {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [processedData, setProcessedData] = useState<any[]>([]);
  const [selectedFileIndex, setSelectedFileIndex] = useState(-1);
  const [currentPage, setCurrentPage] = useState(1);
  const [analyzing, setAnalyzing] = useState(false);
  const [analysisResult, setAnalysisResult] = useState<any | null>(null);
  const [correlationResults, setCorrelationResults] = useState<any>(null);
  const [showPopup, setShowPopup] = useState(false);
  const [isTypingMessage, setIsTypingMessage] = useState(false);
  const itemsPerPage = 10;

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setSelectedFiles(Array.from(files));
    }
  };

  const handleUpload = async () => {
    const endpoint = `${REACT_APP_API_URL_MS_SPREADSHEET}/api/upload_file_excel`;
    const token = 'f32x1v3mYTI7HzPwjqsq5FyINwNhxRugFHf6A8z61dE';

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('files', file);
    });

    try {
      setLoading(true);

      const response = await axios.post(endpoint, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      setProcessedData(response.data.data);
    } catch (error) {
      console.error('Error in request:', error);
    } finally {
      setLoading(false);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = processedData[selectedFileIndex]?.data.data.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');

    if (storedUserId) {
      axios
        .get<UserInfo>(`${REACT_APP_SERVER_URL}/api/users/infos/${JSON.parse(storedUserId)}`)
        .then((response) => {
          setUserInfo(response.data);
        })
        .catch((error) => {
          console.error('Error fetching user information:', error);
        });
    }
  }, []);

  const handleShowAnalysisPopup = async (fileData: any[]) => {
    const user = getUserFromLocalStorage();
    
    if (user && user.userId) {
      try {
        setAnalyzing(true);
        const requestBody = {
          fileData: {
            columns: processedData[selectedFileIndex]?.data.columns,
            data: fileData,
          },
        };
  
        const token = 'f32x1v3mYTI7HzPwjqsq5FyINwNhxRugFHf6A8z61dE';
        let userId = user.userId;
        userId = userId.replace(/^"(.*)"$/, '$1');
  
        const response = await axios.post(
          `${REACT_APP_SERVER_URL}/api/spreadsheets/analytics/${userId}`,
          requestBody,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
  
        // Exibir a mensagem de resposta do segundo endpoint no popup
        const analyzeResponse = await axios.post(
          'http://127.0.0.1:8000/api/analyze_dataframe',
          requestBody,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
  
        if (analyzeResponse.data && analyzeResponse.data.message) {
          // Define a mensagem no estado analysisResult
          setAnalysisResult({ message: analyzeResponse.data.message });
          setShowPopup(true);
        } else {
          console.error('Resposta inválida do endpoint de análise');
        }
  
        // Deduct 1 credit from the user's credits
        const decrementAmount = 1;
        const updateResponse = await fetch(
          `${REACT_APP_SERVER_URL}/api/users/update-credits/${userId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              decrementAmount,
            }),
          }
        );
  
        if (updateResponse.ok) {
          console.log('Crédito deduzido com sucesso');
        } else {
          console.error('Erro ao deduzir crédito do usuário');
        }
      } catch (error) {
        console.error('Erro na análise:', error);
      } finally {
        setAnalyzing(false);
      }
    } else {
      console.error('userId não encontrado no localStorage');
    }
  };
  
  
  

  const tools: Tool[] = [
    {
        name: 'Análise',
        icon: <SlGraph />,
        creditsRequired: 2,
        onClick:() => {
            handleShowAnalysisPopup(processedData[selectedFileIndex]?.data.data);
            setAnalyzing(true); // Set analyzing state to true when the button is clicked
          },
      },
      
    {
      name: 'Gráficos',
      icon: <IoMdAnalytics />,
      creditsRequired: 2,
      onClick: () => {
        // Logic to apply Tool 2
      },
    },
    {
      name: 'Tool 3',
      icon: <AiOutlineTool />,
      creditsRequired: 1,
      onClick: () => {
        // Logic to apply Tool 3
      },
    },
  ];


  return (
    <div className="min-h-screen bg-[#111827] overflow-auto">
      <div className="mx-auto max-w-screen-xl h-full w-full">
        <div className="mt-1 mb-8 -ml-6">
          <div className="mb-12 ml-6 mt-14">
            <Link
              to="/"
              className="text-white hover:text-[#57B4B9] transition duration-300 flex items-center font-montserrat font-bold text-sm"
            >
              <span className="mr-1">&#8592;</span> Back
            </Link>
          </div>
          <Heading
            title="Spreadsheets"
            description="Access your Excel files without complications!"
            icon={Sheet}
            iconColor="text-green-500"
            bgColor="bg-green-500/10"
          />
        </div>
        <div>
          <div className="bg-white border rounded-lg p-6 shadow-md mt-8 w-full mb-10">
            <div className="mb-4">
              <h2 className="text-lg font-medium mb-2 font-montserrat">
                Step 1: Insert Excel Files
              </h2>
              <p className="text-gray-600 font-montserrat">
                Select the Excel files you want to analyze.
              </p>
              <input
                type="file"
                accept=".xlsx, xls"
                multiple
                className="mt-2 border rounded p-2 w-full font-montserrat"
                onChange={handleFileChange}
              />
            </div>
            <button
              className={`font-montserrat mt-1 bg-[#03989E] hover:bg-[#03989E]/90 text-white font-semibold py-2 px-4 rounded ${
                loading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              onClick={handleUpload}
              disabled={loading}
            >
              {loading ? (
                <svg
                  className="animate-spin h-5 w-5 mr-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018 8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                'Upload Excel'
              )}
            </button>
          </div>
        </div>
        {loading === false && processedData.length > 0 ? (
          <div className="bg-white border rounded-lg p-6 shadow-md mt-8 w-full mb-10">
            <div className="mb-4">
              <p className="text-gray-600 font-montserrat">Available files:</p>
              <select
                className="mb-4 border rounded p-2 w-full mt-2 font-montserrat"
                value={selectedFileIndex}
                onChange={(e) => {
                  setSelectedFileIndex(Number(e.target.value));
                }}
              >
                <option className="font-montserrat" value={-1}>
                  Select the Excel file
                </option>
                {processedData.map((fileData, index) => (
                  <option key={index} value={index}>
                    {fileData.filename}
                  </option>
                ))}
              </select>
              {showPopup && (
                <div className="fixed inset-0 flex items-center justify-center z-10 min-w-full">
                    <div className="bg-white border rounded-lg p-8 shadow-lg w-[500px] h-[400px] overflow-y-auto">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-2xl font-semibold font-montserrat text-black">
                        Resultado da Análise
                        </h2>
                        <button
                        className="text-gray-600 hover:text-gray-800 transition ease-in-out duration-150 font-montserrat"
                        onClick={() => setShowPopup(false)}
                        >
                        Fechar
                        </button>
                    </div>
                    <div className="mt-6">
                        {/* Exibe a mensagem da resposta aqui com o efeito de digitação */}
                        {analysisResult && analysisResult.message && (
                        <div className="text-black font-montserrat text-base">
                            <TypingEffect
                            text={analysisResult.message}
                            onTypingComplete={() => setIsTypingMessage(false)}
                            typingSpeed={50} // Velocidade de digitação em milissegundos por caractere
                            />
                        </div>
                        )}
                    </div>
                    </div>
                </div>
                )}



            </div>
            {selectedFileIndex !== -1 && (
              <div className="overflow-x-auto min-h-20 max-h-64">
                <table className="w-full table-auto">
                  <thead>
                    <tr>
                      {processedData[selectedFileIndex]?.data.columns.map(
                        (column: string, index: number) => (
                          <th
                            key={index}
                            className="px-6 py-3 bg-[#B3D8D9] text-left text-xs leading-4 font-medium text-black uppercase tracking-wider font-montserrat"
                          >
                            {column}
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {currentItems.map((row: any[], rowIndex: number) => (
                      <tr
                        key={rowIndex}
                        className={rowIndex % 2 === 0 ? 'bg-gray-50' : ''}
                      >
                        {row.map((cell: any, cellIndex: number) => (
                          <td
                            key={cellIndex}
                            className="px-6 py-2 whitespace-no-wrap text-sm leading-5 text-gray-500 font-montserrat"
                          >
                            {cell}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {selectedFileIndex !== -1 && (
              <div className="flex justify-center mt-6 space-x-4">
                {tools.map((tool, index) => (
                  <div key={index} className="text-center">
                    <button
                      className="flex flex-col items-center px-4 py-2 bg-[#03989E] text-white rounded-lg hover:bg-[#03989E]/90 transition duration-300"
                      onClick={tool.onClick}
                    >
                    {analyzing ? (
                            <p className="mt-1 text-white font-medium font-montserrat text-sm">
                            Estou pensando...
                            </p>
                    ) : (
                        <>
                            {tool.icon}
                            <span className="mt-1 text-white font-montserrat text-base">
                                {tool.name}
                            </span>
                            <span className="text-gray-200 font-montserrat text-xs">
                                {tool.creditsRequired} créditos
                            </span>
                        </>
                    )}
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SheetPage;
