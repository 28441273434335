import React, { useState, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import { Heading } from '../components/Global/Heading';
import { Sheet } from 'lucide-react';
import axios from 'axios';

import { REACT_APP_API_URL_MS_SPREADSHEET } from "../config/keys";


const ProcessExcelFilePage = () => {
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [loading, setLoading] = useState(false);
    const [processedData, setProcessedData] = useState<any[]>([]);
    const [selectedFileIndex, setSelectedFileIndex] = useState(-1);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Define a quantidade de itens por página

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            setSelectedFiles(Array.from(files));
        }
    };

    const handleUpload = async () => {
        const endpoint = `${REACT_APP_API_URL_MS_SPREADSHEET}/api/upload_file_excel`; 
        const token = 'f32x1v3mYTI7HzPwjqsq5FyINwNhxRugFHf6A8z61dE';

        const formData = new FormData();
        selectedFiles.forEach((file) => {
            formData.append('files', file);
        });

        try {
            setLoading(true);

            const response = await axios.post(endpoint, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            setProcessedData(response.data.data);
        } catch (error) {
            console.error('Erro na requisição:', error);
        } finally {
            setLoading(false);
        }
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = processedData[selectedFileIndex]?.data.data.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <div className="relative min-h-screen bg-[#03989E]">
            <Link to="/" className="absolute top-0 left-0 m-4 text-white">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                </svg>
            </Link>
            <div className="flex flex-col items-center justify-center p-8">
                <Heading
                    title="Abrir e Ler Planilhas Excel"
                    description="Acesse seus arquivos Excel sem complicações!"
                    icon={Sheet}
                    iconColor="text-[#000000]"
                    bgColor="bg-[#B3D8D9]/40"
                />
                <div className="bg-white border rounded-lg p-6 shadow-md mt-8 max-w-lg w-full">
                    <div className="mb-4">
                        <h2 className="text-lg font-medium mb-2 font-montserrat">Passo 1: Insira os Arquivos Excel</h2>
                        <p className="text-gray-600 font-montserrat">Selecione os arquivos Excel que deseja analisar.</p>
                        <input
                            type="file"
                            accept=".xlsx, xls"
                            multiple
                            className="mt-2 border rounded p-2 w-full font-montserrat"
                            onChange={handleFileChange}
                        />
                    </div>
                    <button
                        className={`font-montserrat mt-1 bg-[#03989E] hover:bg-[#03989E]/90 text-white font-semibold py-2 px-4 rounded ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        onClick={handleUpload}
                        disabled={loading}
                    >
                        {loading ? (
                            <svg className="animate-spin h-5 w-5 mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018 8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        ) : (
                            'Upload Excel'
                        )}
                    </button>
                </div>
                {loading === false && processedData.length > 0 ? (
                    <div className="bg-white border rounded-lg p-6 shadow-md mt-8 w-full">
                        <div className="mb-4">
                            <p className="text-gray-600 font-montserrat">Arquivos disponíveis:</p>
                            <select
                                className="mb-4 border rounded p-2 w-full mt-2 font-montserrat"
                                value={selectedFileIndex}
                                onChange={(e) => {
                                    setSelectedFileIndex(Number(e.target.value));
                                }}
                            >
                                <option className='font-montserrat' value={-1}>Selecione o arquivo Excel</option>
                                {processedData.map((fileData, index) => (
                                    <option key={index} value={index}>
                                        {fileData.filename}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {selectedFileIndex !== -1 && (
                            <div className="overflow-x-auto min-h-20 max-h-64">
                                <table className="w-full table-auto">
                                    <thead>
                                        <tr>
                                            {processedData[selectedFileIndex]?.data.columns.map((column: string, index: number) => (
                                                <th key={index} className="px-6 py-3 bg-[#B3D8D9] text-left text-xs leading-4 font-medium text-black uppercase tracking-wider font-montserrat">
                                                    {column}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white">
                                        {currentItems.map((row: any[], rowIndex: number) => (
                                            <tr key={rowIndex} className={rowIndex % 2 === 0 ? 'bg-gray-50' : ''}>
                                                {row.map((cell: any, cellIndex: number) => (
                                                    <td key={cellIndex} className="px-6 py-2 whitespace-no-wrap text-sm leading-5 text-gray-500 font-montserrat">
                                                        {cell}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                                                        <div className="mt-4 flex justify-center">
                                    <button
                                        className="px-3 py-1 mr-2 bg-gray-200 text-gray-700 rounded font-montserrat"
                                        onClick={() => setCurrentPage(currentPage - 1)}
                                        disabled={currentPage === 1}
                                    >
                                        Anterior
                                    </button>
                                    <span className="px-3 py-1 bg-gray-200 text-gray-400 rounded font-montserrat">
                                        Página {currentPage}
                                    </span>
                                    <button
                                        className="px-3 py-1 ml-2 bg-gray-200 text-gray-700 rounded font-montserrat"
                                        onClick={() => setCurrentPage(currentPage + 1)}
                                        disabled={indexOfLastItem >= processedData[selectedFileIndex]?.data.data.length}
                                    >
                                        Próxima
                                    </button>
                                </div>
                    </div>
                ) : null}
                </div>
        </div>
    );
}

export default ProcessExcelFilePage;
