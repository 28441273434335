import React, { useState, useEffect, PureComponent } from 'react';
import axios from 'axios';
import {
    BarChart,
    Bar,
    AreaChart,
    Area,
    Cell,
    Scatter,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LineChart,
    Line,
    PieChart,
    Pie,
    Radar,
    RadarChart,
    PolarGrid,
    PolarAngleAxis,
    PolarRadiusAxis,
    Treemap,
    RadialBarChart,
    RadialBar,
    ComposedChart,
} from 'recharts';

import { REACT_APP_SERVER_URL } from '../config/keys';

interface UserInfo {
    userId: string;
    name: string;
    availableCredits: number;
}

const KpisInvoices = () => {
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [kpisData, setKpisData] = useState<any>({});
    const [graphBarData, setGraphBarData] = useState<any>({});
    const [graphLineData, setGraphLineData] = useState<any>({});
    const [graphPieData, setGraphPieData] = useState<any>({});
    const [graphTreeMapData, setGraphTreeMapData] = useState<any>({});
    const [summaryByPrestadorData, setSummaryByPrestadorData] = useState<any>(
        {}
    );

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get<UserInfo>(
                    `${REACT_APP_SERVER_URL}/api/users/infos/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    setUserInfo(response.data);
                })
                .catch(error => {
                    console.error('Error fetching user information:', error);
                });
        }
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/invoice/more-kpis-invoice-data/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    setKpisData(response.data);
                })
                .catch(error => {
                    console.error('Error fetching KPIs data:', error);
                });
        }
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/invoice/graph-bar-invoice-data/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    setGraphBarData(response.data);
                })
                .catch(error => {
                    console.error('Error fetching KPIs data:', error);
                });
        }
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/invoice/graph-line-invoices/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    setGraphLineData(response.data);
                })
                .catch(error => {
                    console.error('Error fetching KPIs data:', error);
                });
        }
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/invoice/graph-pie-invoices/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    setGraphPieData(response.data);
                })
                .catch(error => {
                    console.error('Error fetching KPIs data:', error);
                });
        }
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/invoice/graph-tree-map-by-service/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    setGraphTreeMapData(response.data);
                })
                .catch(error => {
                    console.error('Error fetching KPIs data:', error);
                });
        }
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/invoice/count-by-municipio/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    setSummaryByPrestadorData(response.data);
                })
                .catch(error => {
                    console.error('Error fetching KPIs data:', error);
                });
        }
    }, []);

    return (
        <div className="min-h-screen bg-[#111827] py-10">
            <div>
                <div className="mx-0 md:max-w-8xl lg:max-w-8xl xl:max-w-12xl ml-30">
                    <div className="grid grid-cols-1 md:grid-cols-6 gap-4 ml-5">
                        <div className="col-span-1 bg-[#1f2937] border border-[#2c3848] text-white p-4 rounded-lg shadow-md">
                            <p className="text-lg font-montserrat">
                                Valor das Notas
                            </p>
                            <p className="text-xl font-semibold">
                                {kpisData.totalPaid
                                    ? `R$ ${kpisData.totalPaid},00`
                                    : '-'}
                            </p>
                        </div>
                        <div className="col-span-1 bg-[#1f2937] border border-[#2c3848] text-white p-4 rounded-lg shadow-md">
                            <p className="text-lg text-muted-foreground font-montserrat">
                                Média por Nota
                            </p>
                            <p className="text-xl font-semibold">
                                {kpisData.averageValuePerInvoice
                                    ? `R$ ${kpisData.averageValuePerInvoice},00`
                                    : '-'}
                            </p>
                        </div>
                        <div className="col-span-1 bg-[#1f2937] border border-[#2c3848] text-white p-4 rounded-lg shadow-md">
                            <p className="text-lg text-muted-foreground font-montserrat">
                                Total IRRF
                            </p>
                            <p className="text-xl font-semibold">
                                {kpisData.totalIRRF
                                    ? `R$ ${kpisData.totalIRRF},00`
                                    : '-'}
                            </p>
                        </div>
                        <div className="col-span-1 bg-[#1f2937] border border-[#2c3848] text-white p-4 rounded-lg shadow-md">
                            <p className="text-lg text-muted-foreground font-montserrat">
                                Total CSLL
                            </p>
                            <p className="text-xl font-semibold">
                                {kpisData.totalCSLL
                                    ? `R$ ${kpisData.totalCSLL},00`
                                    : '-'}
                            </p>
                        </div>
                        <div className="col-span-1 bg-[#1f2937] border border-[#2c3848] text-white p-4 rounded-lg shadow-md">
                            <p className="text-lg text-muted-foreground font-montserrat">
                                Alíquotas
                            </p>
                            <p className="text-xl font-semibold">
                                {kpisData.averageAliquotaPercentage
                                    ? `${kpisData.averageAliquotaPercentage}%`
                                    : '-'}
                            </p>
                        </div>
                        <div className="col-span-1 bg-[#1f2937] border border-[#2c3848] text-white p-4 rounded-lg shadow-md">
                            <p className="text-lg text-muted-foreground font-montserrat">
                                Total ISS
                            </p>
                            <p className="text-xl font-semibold">
                                {kpisData.totalISS
                                    ? `R$ ${kpisData.totalISS},00`
                                    : '-'}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div style={{ display: 'flex' }}>
                    <div className="-ml-10">
                        <div>
                            <p className="mt-6 -mb-3 ml-5 text-sm text-white font-montserrat">
                                Valor Total de Notas Emitidas por Data de
                                Emissão, incluindo a Média por Nota por Dia.
                            </p>

                            <div
                                className="bg-[#1f2937] border border-[#2c3848] rounded-sm text-white p-4 shadow-md ml-5 mt-5"
                                style={{ width: '650px', height: '300px' }}
                            >
                                <ResponsiveContainer width="100%" height="100%">
                                    <ComposedChart
                                        width={500}
                                        height={400}
                                        data={graphLineData.data}
                                        margin={{
                                            top: 20,
                                            right: 20,
                                            bottom: 20,
                                            left: 20,
                                        }}
                                    >
                                        <XAxis
                                            dataKey="datacreated"
                                            scale="band"
                                            domain={['auto', 'auto']}
                                            tickMargin={10}
                                            dx={85}
                                            dy={0}
                                            tick={{
                                                fill: '#ffffff',
                                                fontSize: 13,
                                            }}
                                            textAnchor="end" // Alinhe o texto à extremidade do rótulo
                                        />
                                        <YAxis
                                            tick={{
                                                fill: '#ffffff',
                                                fontSize: 13,
                                            }}
                                            domain={['auto', 'auto']}
                                            tickMargin={12}
                                        />
                                        <Tooltip />
                                        <Bar
                                            dataKey="totalPaid"
                                            barSize={20}
                                            fill="#82ca9d"
                                        />
                                        <Line
                                            type="monotone"
                                            dataKey="averagePerNote"
                                            stroke="#BA5796"
                                        />
                                    </ComposedChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div>
                            <p className="mt-6 -mb-3 ml-5 text-sm text-white font-montserrat">
                                Valor Total de Notas por Prestador de Serviço
                            </p>
                            <div
                                className="bg-[#1f2937] border border-[#2c3848] rounded-sm text-white p-4 shadow-md ml-5 mt-5"
                                style={{ width: '660px', height: '300px' }}
                            >
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart
                                        width={500}
                                        height={300}
                                        data={graphBarData.data}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <XAxis
                                            dataKey="razaoSocialPrestador"
                                            domain={['auto', 'auto']}
                                            tickMargin={10}
                                            dx={44}
                                            dy={0}
                                            tick={{
                                                fill: '#ffffff',
                                                fontSize: 10,
                                            }}
                                            textAnchor="end" // Alinhe o texto à extremidade do rótulo
                                        />
                                        <YAxis
                                            tick={{
                                                fill: '#ffffff',
                                                fontSize: 13,
                                            }}
                                            domain={['auto', 'auto']}
                                            tickMargin={12}
                                        />
                                        <Tooltip />
                                        <Line
                                            type="monotone"
                                            dataKey="totalPaid"
                                            stroke="#FFDE59"
                                            activeDot={{ r: 8 }}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="-ml-10">
                    <div style={{ display: 'flex' }}>
                        <div>
                            <p className="mt-6 -mb-3 ml-5 text-sm text-white font-montserrat">
                                Impostos Pagos por Prestador de Serviço
                            </p>
                            <div
                                className="bg-[#1f2937] border border-[#2c3848] rounded-sm text-white p-4 shadow-md ml-5 mt-5"
                                style={{ width: '600px', height: '240px' }}
                            >
                                <ResponsiveContainer width="100%" height="100%">
                                    <AreaChart
                                        width={500}
                                        height={400}
                                        data={graphPieData.data}
                                        margin={{
                                            top: 10,
                                            right: 30,
                                            left: 0,
                                            bottom: 0,
                                        }}
                                    >
                                        <XAxis
                                            dataKey="name"
                                            domain={['auto', 'auto']}
                                            tickMargin={10}
                                            dx={44}
                                            dy={0}
                                            tick={{
                                                fill: '#ffffff',
                                                fontSize: 10,
                                            }}
                                            textAnchor="end" // Alinhe o texto à extremidade do rótulo
                                        />
                                        <YAxis
                                            tick={{
                                                fill: '#ffffff',
                                                fontSize: 13,
                                            }}
                                            domain={['auto', 'auto']}
                                            tickMargin={12}
                                        />
                                        <Tooltip />
                                        <Area
                                            type="monotone"
                                            dataKey="irrf"
                                            stackId="1"
                                            stroke="#8884d8"
                                            fill="#8884d8"
                                        />
                                        <Area
                                            type="monotone"
                                            dataKey="csll"
                                            stackId="1"
                                            stroke="#82ca9d"
                                            fill="#82ca9d"
                                        />
                                        <Area
                                            type="monotone"
                                            dataKey="iss"
                                            stackId="1"
                                            stroke="#ffc658"
                                            fill="#ffc658"
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div>
                            <p className="mt-6 -mb-3 ml-5 text-sm text-white font-montserrat">
                                Serviços Prestados
                            </p>
                            <div
                                className="bg-[#1f2937] border border-[#2c3848] rounded-sm text-white p-4 shadow-md ml-5 mt-5"
                                style={{ width: '250px', height: '240px' }}
                            >
                                <ResponsiveContainer width="100%" height="100%">
                                    <PieChart width={400} height={400}>
                                        <Pie
                                            dataKey="count"
                                            isAnimationActive={true}
                                            data={graphTreeMapData.data}
                                            cx="50%"
                                            cy="50%"
                                            outerRadius={80}
                                            fill="#02A678"
                                            label
                                        />
                                        <Tooltip />
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        <div>
                            <p className="mt-6 -mb-3 ml-5 text-sm text-white font-montserrat">
                                Contagem de Notas por Município
                            </p>
                            <div
                                className="bg-[#1f2937] border border-[#2c3848] rounded-sm text-white p-4 shadow-md ml-5 mt-5"
                                style={{ width: '440px', height: '240px' }}
                            >
                                <ResponsiveContainer width="100%" height="100%">
                                    <Treemap
                                        width={400}
                                        height={200}
                                        data={summaryByPrestadorData.data}
                                        dataKey="count"
                                        aspectRatio={4 / 3}
                                        stroke="#ffffff"
                                        fill="#5777BA"
                                    />
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KpisInvoices;
