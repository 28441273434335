// PrivacyChoices.tsx
import React from 'react';

const PrivacyChoices: React.FC = () => {
    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white -mt-10">
            <h1 className="text-4xl font-bold mb-6 ml-10 text-[#39A2A7] font-montserrat">
                Política de Privacidade Neuron Lab
            </h1>

            <div className="max-w-3xl p-8 bg-gray-800 rounded-md shadow-md overflow-y-auto h-96 text-base font-montserrat">
                <p className="mb-4">
                    A Neuron Lab, por meio desta política, observa que
                    privacidade e segurança são prioridades e nos comprometemos
                    com a transparência do tratamento de dados pessoais dos
                    nossos usuários/clientes. Dessa forma, a presente Política
                    de Privacidade estabelece como é feita a coleta, uso e
                    transferência de informações de clientes ou outras pessoas
                    que acessam ou usam nossa plataforma.
                </p>

                <p className="mb-4">
                    Ao utilizar nossos serviços, entende-se que coletaremos e
                    usaremos suas informações pessoais nas formas descritas
                    nesta Política, sob as normas de Proteção de Dados (LGPD,
                    Lei Federal 13.709/2018), das disposições consumeristas da
                    Lei Federal 8078/1990 e as demais normas do ordenamento
                    jurídico brasileiro aplicáveis.
                </p>

                <p className="mb-4">
                    Assim, a VICTOR BARROS – CONSULTORIA EM DADOS E INTELIGÊNCIA
                    ARTIFICIAL LTDA., doravante denominada simplesmente como
                    Neuron Lab, inscrita no CNPJ/MF sob o nº 43.192.429/0001-30,
                    no papel de Controladora de Dados, obriga-se ao disposto na
                    presente Política de Privacidade.
                </p>

                <h2 className="text-2xl font-bold mt-6 mb-4">
                    1. Quais dados coletamos e para qual finalidade?
                </h2>

                <p className="mb-4">
                    Nossa plataforma coleta e utiliza alguns dados pessoais, de
                    forma a viabilizar a prestação de serviços e aprimorar a
                    experiência de uso.
                </p>

                <p className="mb-4">
                    <strong>1.1. Dados pessoais fornecidos pelo titular</strong>
                </p>

                <ul className="list-disc pl-6 mb-4">
                    <li>Nome Completo para identificação pessoal</li>
                    <li>
                        E-mail por ser necessário um dado para acesso a
                        plataforma e envio de comunicações
                    </li>
                    <li>Telefone por ser uma forma de contato</li>
                </ul>

                <h2 className="text-2xl font-bold mt-6 mb-4">
                    2. Como coletamos os seus dados?
                </h2>

                <p className="mb-4">
                    Nesse sentido, a coleta dos seus dados pessoais ocorre da
                    seguinte forma:
                </p>

                <ul className="list-disc pl-6 mb-4">
                    <li>
                        Cadastro na plataforma Neuron Lab para uso do sistema
                    </li>
                    <li>
                        Preenchimento do formulário de contato no site da Neuron
                        Lab
                    </li>
                </ul>

                <p className="mb-4">
                    <strong>2.1. Consentimento</strong>
                </p>

                <p className="mb-4">
                    É a partir do consentimento que tratamos os dados pessoais.
                    O consentimento é a manifestação livre, informada e
                    inequívoca pela qual você autoriza a Neuron Lab a tratar
                    seus dados. Assim, em consonância com a Lei Geral de
                    Proteção de Dados, seus dados só serão coletados, tratados e
                    armazenados mediante prévio e expresso consentimento.
                </p>
                <p className="mb-4">
                    O seu consentimento será obtido de forma específica para
                    cada finalidade acima descrita, evidenciando o compromisso
                    de transparência e boa-fé da Neuron Lab para com seus
                    usuários e clientes, seguindo as regulações legislativas
                    pertinentes. Ao utilizar os serviços da Neuron Lab e
                    fornecer seus dados pessoais, você está ciente e consentindo
                    com as disposições desta Política de Privacidade, além de
                    conhecer seus direitos e como exercê-los.
                </p>
                <p className="mb-4">
                    A qualquer tempo e sem nenhum custo, você poderá revogar seu
                    consentimento. É importante destacar que a revogação do
                    consentimento para o tratamento dos dados pode implicar a
                    impossibilidade da performance adequada de alguma
                    funcionalidade da plataforma que dependa da operação. Tais
                    consequências serão informadas previamente na ocasião da
                    solicitação.
                </p>
                <div className="mb-8">
                    <h2 className="text-2xl font-bold mb-4">
                        3. Quais são os direitos do usuário?
                    </h2>

                    <p className="mb-4">
                        A Neuron Lab assegura a seus usuários e clientes seus
                        direitos de titular previstos no artigo 18 da Lei Geral
                        de Proteção de Dados. Dessa forma, você pode, de maneira
                        gratuita e a qualquer tempo:
                    </p>

                    <ul className="list-disc pl-6 mb-4">
                        <li>
                            Confirmar a existência de tratamento de dados, de
                            maneira simplificada ou em formato claro e completo.
                        </li>
                        <li>
                            Acessar seus dados, podendo solicitá-los em uma
                            cópia legível sob forma impressa ou por meio
                            eletrônico, seguro e idôneo.
                        </li>
                        <li>
                            Corrigir seus dados, ao solicitar a edição, correção
                            ou atualização destes.
                        </li>
                        <li>
                            Limitar seus dados quando desnecessários, excessivos
                            ou tratados em desconformidade com a legislação
                            através da anonimização, bloqueio ou eliminação.
                        </li>
                        <li>
                            Solicitar a portabilidade de seus dados, através de
                            um relatório de dados cadastrais que a Neuron Lab
                            trata a seu respeito.
                        </li>
                        <li>
                            Eliminar seus dados tratados a partir de seu
                            consentimento, exceto nos casos previstos em lei.
                        </li>
                        <li>
                            Revogar seu consentimento, desautorizando o
                            tratamento de seus dados.
                        </li>
                        <li>
                            Informar-se sobre a possibilidade de não fornecer
                            seu consentimento e sobre as consequências da
                            negativa.
                        </li>
                    </ul>
                </div>

                <div className="mb-8">
                    <h2 className="text-2xl font-bold mb-4">
                        4. Como exercer seus direitos de titular?
                    </h2>

                    <p className="mb-4">
                        Para exercer seus direitos de titular, você deve entrar
                        em contato com a Neuron Lab através dos seguintes meios
                        disponíveis:
                    </p>

                    <ul className="list-disc pl-6 mb-4">
                        <li>Whatsapp: (11) 99232-4421</li>
                        <li>Email: dpo@neuronlab.io</li>
                    </ul>

                    <p>
                        De forma a garantir a sua correta identificação como
                        titular dos dados pessoais objeto da solicitação, é
                        possível que solicitemos documentos ou demais
                        comprovações que possam comprovar sua identidade. Nessa
                        hipótese, o usuário será informado previamente.
                    </p>
                </div>
                <div className="mb-8">
                    <h2 className="text-2xl font-bold mb-4">
                        5. Como e por quanto tempo os dados serão armazenados?
                    </h2>

                    <p className="mb-4">
                        Seus dados pessoais coletados pela Neuron Lab serão
                        utilizados e armazenados durante o tempo necessário para
                        a prestação do serviço ou para que as finalidades
                        elencadas na presente Política de Privacidade sejam
                        atingidas, considerando os direitos dos titulares dos
                        dados e dos controladores.
                    </p>

                    <p className="mb-4">
                        De modo geral, seus dados serão mantidos enquanto a
                        relação contratual junto à Neuron Lab perdurar. Findado
                        o período de armazenamento dos dados pessoais, estes
                        serão excluídos de nossas bases de dados ou
                        anonimizados, ressalvadas as hipóteses legalmente
                        previstas no artigo 16 lei geral de proteção de dados, a
                        saber:
                    </p>

                    <ol className="list-decimal pl-6 mb-4">
                        <li>
                            Cumprimento de obrigação legal ou regulatória pelo
                            controlador;
                        </li>
                        <li>
                            Estudo por órgão de pesquisa, garantida, sempre que
                            possível, a anonimização dos dados pessoais;
                        </li>
                        <li>
                            Transferência a terceiro, desde que respeitados os
                            requisitos de tratamento de dados dispostos nesta
                            Lei;
                        </li>
                        <li>
                            Uso exclusivo do controlador, vedado seu acesso por
                            terceiro, e desde que anonimizados os dados.
                        </li>
                    </ol>

                    <p className="mb-4">
                        Isto é, informações pessoais sobre você que sejam
                        imprescindíveis para o cumprimento de determinações
                        legais, judiciais e administrativas e/ou para o
                        exercício do direito de defesa em processos judiciais e
                        administrativos serão mantidas, a despeito da exclusão
                        dos demais dados.
                    </p>

                    <p className="mb-4">
                        O armazenamento de dados coletados pela Neuron Lab
                        reflete o nosso compromisso com a segurança e
                        privacidade dos seus dados. São utilizados bancos de
                        dados em território nacional, de forma que aplica-se a
                        lei vigente no Brasil. Empregamos também medidas e
                        soluções técnicas de proteção aptas a garantir a
                        confidencialidade, integridade e inviolabilidade dos
                        seus dados. Além disso, também contamos com medidas de
                        segurança apropriadas aos riscos e com controle de
                        acesso às informações armazenadas.
                    </p>
                </div>
                <div className="mb-8">
                    <h2 className="text-2xl font-bold mb-4">
                        6. O que fazemos para manter os dados seguros?
                    </h2>

                    <p className="mb-4">
                        Para mantermos as informações pessoais seguras, usamos
                        ferramentas físicas, eletrônicas e gerenciais orientadas
                        para a proteção da sua privacidade.
                    </p>

                    <p className="mb-4">
                        Aplicamos essas ferramentas levando em consideração a
                        natureza dos dados pessoais coletados, o contexto e a
                        finalidade do tratamento e os riscos que eventuais
                        violações gerariam para os direitos e liberdades do
                        titular dos dados coletados e tratados.
                    </p>

                    <p className="mb-4">
                        Entre as medidas que adotamos, destacamos as seguintes:
                    </p>

                    <ul className="list-disc pl-6 mb-4">
                        <li>
                            Apenas pessoas autorizadas têm acesso aos dados
                            pessoais.
                        </li>
                        <li>
                            O acesso aos dados pessoais é feito somente após o
                            compromisso de confidencialidade.
                        </li>
                        <li>
                            Os dados pessoais são armazenados em ambiente seguro
                            e idôneo.
                        </li>
                    </ul>

                    <p className="mb-4">
                        A Neuron Lab se compromete a adotar as melhores posturas
                        para evitar incidentes de segurança. Contudo, é
                        necessário destacar que nenhuma plataforma ou sistema
                        informatizado é inteiramente seguro e livre de riscos. É
                        possível que, apesar de todos os nossos protocolos de
                        segurança, problemas de culpa exclusivamente de
                        terceiros ocorram, como ataques cibernéticos de hackers,
                        ou também em decorrência da negligência ou imprudência
                        do próprio usuário/cliente.
                    </p>

                    <p>
                        Em caso de incidentes de segurança que possa gerar risco
                        ou dano relevante para você ou qualquer um de nossos
                        usuários e clientes, comunicaremos aos afetados e a
                        Autoridade Nacional de Proteção de Dados sobre o
                        ocorrido, em consonância com as disposições da Lei Geral
                        de Proteção de Dados.
                    </p>
                </div>
                <div className="mb-8">
                    <h2 className="text-2xl font-bold mb-4">
                        7. Com quem os dados podem ser compartilhados?
                    </h2>

                    <p className="mb-4">
                        Tendo em vista a preservação de sua privacidade, a
                        Neuron Lab não compartilhará seus dados pessoais com
                        nenhum terceiro não autorizado.
                    </p>

                    <p className="mb-4">
                        Existem outras hipóteses em que seus dados poderão ser
                        compartilhados, que são:
                    </p>

                    <ul className="list-decimal pl-6 mb-4">
                        <li>
                            Determinação legal, requerimento, requisição ou
                            ordem judicial, com autoridades judiciais,
                            administrativas ou governamentais competentes.
                        </li>
                        <li>
                            Caso de movimentações societárias, como fusão,
                            aquisição e incorporação, de forma automática.
                        </li>
                        <li>
                            Proteção dos direitos da Neuron Lab em qualquer tipo
                            de conflito, inclusive os de teor judicial.
                        </li>
                    </ul>

                    <p className="mb-4">
                        <strong>
                            7.1. Transferência internacional de dados
                        </strong>
                    </p>

                    <p className="mb-4">
                        Alguns dos terceiros com quem compartilhamos seus dados
                        podem ser localizados ou possuir instalações localizadas
                        em países estrangeiros. Nessas condições, de toda forma,
                        seus dados pessoais estarão sujeitos à Lei Geral de
                        Proteção de Dados e às demais legislações brasileiras de
                        proteção de dados.
                    </p>

                    <p className="mb-4">
                        Neste contexto, a Neuron Lab se compromete a adotar, em
                        todas as condições, eficientes padrões de segurança
                        cibernética e de proteção de dados, nos mais amplos
                        esforços para garantir e cumprir as exigências
                        legislativas.
                    </p>

                    <p>
                        Ao concordar com essa Política de Privacidade, o usuário
                        concorda com esse compartilhamento, que se dará conforme
                        as finalidades descritas no presente documento.
                    </p>
                </div>

                <div className="mb-8">
                    <h2 className="text-2xl font-bold mb-4">
                        8. Cookies ou dados de navegação
                    </h2>

                    <p className="mb-4">
                        A Neuron Lab faz uso de “cookies”, que são arquivos de
                        texto enviados pela plataforma ao seu computador e que
                        nele se armazenam, que contém informações relacionadas à
                        navegação no site e na plataforma. De forma geral, estes
                        “cookies” são utilizados para aprimorar o uso e a
                        experiência do usuário nos sistemas da Neuron Lab.
                    </p>

                    <p className="mb-4">
                        Ao acessar nosso site e consentir com o uso de
                        “cookies”, confirma-se o conhecimento e aceitação a
                        utilização de um sistema de coleta de dados de navegação
                        em seu dispositivo.
                    </p>

                    <p className="mb-4">
                        A Neuron Lab utiliza os seguintes “cookies”:
                        identificação do usuário logado e armazenamento de
                        sessão de acesso.
                    </p>

                    <p className="mb-4">
                        Pode-se a qualquer tempo e sem nenhum custo, alterar as
                        permissões, bloquear ou recusar os “cookies”. Todavia, a
                        revogação do consentimento de determinados “cookies”
                        pode inviabilizar o funcionamento correto de alguns
                        recursos da plataforma.
                    </p>

                    <p className="mb-4">
                        Para gerenciar os “cookies”, basta fazê-lo nas
                        configurações do navegador utilizado, na área de gestão
                        de “cookies”. Tutoriais sobre o tema podem ser obtidos
                        pelos links abaixo:
                    </p>

                    <ul className="list-disc pl-6 mb-4">
                        <li>
                            <a
                                href="https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Se você usa o Internet Explorer.
                            </a>
                        </li>
                        <li>
                            <a href="#">Se você usa o Firefox.</a>
                        </li>
                        <li>
                            <a
                                href="https://support.mozilla.org/pt-BR/kb/gerencie-configuracoes-de-armazenamento-local-de-s"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Se você usa o Firefox.
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&oco=1&hl=pt-BR"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Se você usa o Google Chrome.
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://support.microsoft.com/pt-br/help/4027947/microsoft-edge-delete-cookies"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Se você usa o Microsoft Edge.
                            </a>
                        </li>

                        <li>
                            <a
                                href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Se você usa Safari.
                            </a>
                        </li>
                    </ul>

                    <p>
                        Para maiores informações sobre os “cookies” que
                        utilizamos e como eles funcionam, publicamos em nossa
                        política, disponível neste&nbsp;
                        <a
                            href="/privacy-cookies"
                            className="text-blue-500 underline"
                        >
                            clique aqui
                        </a>
                        .
                    </p>
                </div>
                <div className="mb-8">
                    <h2 className="text-2xl font-bold mb-4">
                        9. Alteração desta Política de Privacidade
                    </h2>

                    <p className="mb-4">
                        A atual versão da Política de Privacidade foi formulada
                        e atualizada pela última vez em: 08/01/2024.
                    </p>

                    <p className="mb-4">
                        Reservamos o direito de modificar essa Política de
                        Privacidade a qualquer tempo, principalmente em função
                        da adequação a eventuais alterações feitas em nosso site
                        ou em âmbito legislativo. Recomendamos que a revise com
                        frequência.
                    </p>

                    <p className="mb-4">
                        Eventuais alterações entrarão em vigor a partir de sua
                        publicação no site da Neuron Lab.
                    </p>

                    <p>
                        Ao utilizar nossos serviços e fornecer dados pessoais
                        após tais modificações, o usuário as consente.
                    </p>
                </div>

                <div className="mb-8">
                    <h2 className="text-2xl font-bold mb-4">
                        10. Responsabilidade
                    </h2>

                    <p className="mb-4">
                        A Neuron Lab prevê a responsabilidade dos agentes que
                        atuam nos processos de tratamento de dados, em
                        conformidade com os artigos 42 ao 45 da Lei Geral de
                        Proteção de Dados.
                    </p>

                    <p className="mb-4">
                        Nos comprometemos em manter esta Política de Privacidade
                        atualizada, observando suas disposições e zelando por
                        seu cumprimento.
                    </p>

                    <p className="mb-4">
                        Além disso, assumimos ainda o compromisso de buscar
                        constantemente as condições técnicas e organizativas
                        seguramente adequadas a proteger todo o processo de
                        tratamento de dados.
                    </p>

                    <p>
                        Caso a Autoridade Nacional de Proteção de Dados exija a
                        adoção de providências em relação ao tratamento de dados
                        realizado pela Neuron Lab, comprometemo-nos a seguir
                        inteiramente.
                    </p>

                    <h3 className="text-xl font-bold mb-2">
                        10.1 Isenção de responsabilidade
                    </h3>

                    <p className="mb-4">
                        Conforme mencionado no Tópico 6, embora adotemos os
                        padrões de segurança a fim de evitar incidentes, não há
                        nenhum sistema informatizado inteiramente livre de
                        riscos. Nesse sentido, a Neuron Lab não se
                        responsabiliza por:
                    </p>

                    <p className="mb-4">
                        I – Quaisquer consequências decorrentes da negligência,
                        imprudência ou imperícia dos usuários em relação a seus
                        dados individuais. Garantimos e nos responsabilizamos
                        apenas pela segurança dos processos de tratamento de
                        dados e do cumprimento das finalidades descritas no
                        presente instrumento.
                    </p>

                    <p className="mb-4">
                        Destacamos que a responsabilidade em relação à
                        confidencialidade dos dados de acesso é do usuário.
                    </p>

                    <p className="mb-4">
                        II – Ações maliciosas de terceiros, como ataques de
                        hackers, exceto se comprovada conduta culposa ou
                        deliberada da Neuron Lab.
                    </p>

                    <p>
                        Destacamos que em caso de incidentes de segurança que
                        possam gerar risco ou dano relevante para qualquer um
                        dos nossos usuários ou clientes, comunicaremos aos
                        afetados e a Autoridade Nacional de Proteção de Dados
                        sobre o ocorrido e cumpriremos as providências
                        necessárias.
                    </p>
                </div>

                <div className="mb-8">
                    <h2 className="text-2xl font-bold mb-4">
                        11. Encarregado de Proteção de Dados (DPO)
                    </h2>

                    <p>
                        A Neuron Lab disponibiliza os seguintes meios para que
                        os usuários e clientes possam entrar em contato conosco
                        para exercer seus direitos de titular: o e-mail
                        dpo@neuronlab.io e whatsapp (11) 99232-4421.
                    </p>
                </div>

                {/* Adicione o restante do conteúdo da política de privacidade aqui */}

                <p className="mt-8 text-sm">Última atualização: 08/01/2024</p>
            </div>
        </div>
    );
};

export default PrivacyChoices;
