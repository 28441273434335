import React, { useState } from "react";

interface TagToken {
  tag: string;
  token: string;
}

const TableCell: React.FC<any> = (props) => {
  const [openInfo, setOpenInfo] = useState<boolean>(false);

  const [tt] = props.tagTokens.filter((f: TagToken) => f.token === props.cellData);

  const tokenized = !!tt;
  const str: string = "abc";

  const backgroundClass = props.headerCell
    ? `
        bg-gray-700
        text-gray-200 
    `
    : props.isSelected
    ? `
        bg-green-200
        hover:bg-green-300
    `
    : tokenized
    ? `
        bg-[#C8F0FF]
    `
    : `
        bg-gray-100
        text-gray-700 
        hover:bg-gray-200
    `;

  const classTd = `
        border-l border-r border-b border-slate-300 
        py-2 max-h-20 overflow-hidden 
        whitespace-no-wrap 
        text-xs relative
        cursor-default
        font-montserrat text-center 
        ${backgroundClass}        
    `;

  return (
    <td
      className={classTd}
      onClick={() => !props.headerCell && props.onClick(props.rowIndex, props.colIndex)}
    >
      {tokenized && openInfo && (
        <div className="fixed z-10 bottom-10 left-10 w-96 h-16 flex flex-col justify-center items-start p-2 rounded-sm bg-[#0b5C66]/90 font-montserrat">
          <p className="text-sm text-white font-montserrat">
            <strong>Tag:</strong> {tt.tag}
          </p>
          <p className="text-sm text-white font-montserrat">
            <strong>Token:</strong> {tt.token}
          </p>
        </div>
      )}

      {typeof props.cellData === "object" ? JSON.stringify(props.cellData) : props.cellData}
      {tokenized && (
        <p
          className="absolute top-0 overflow-ellipsis px-0.5 bg-[#0b5C66] text-white leading-3 right-1 mt-0 text-[0.5rem]"
          onMouseOver={() => setOpenInfo(true)}
          onMouseLeave={() => setOpenInfo(false)}
        >
          {tt.tag.length < 12 ? tt.tag : "..."}
        </p>
      )}
    </td>
  );
};

export default TableCell;
