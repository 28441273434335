import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { X } from 'lucide-react';
import { RiRobot2Line } from 'react-icons/ri';
import { Download } from 'lucide-react';
import axios from 'axios';

import { REACT_APP_SERVER_URL } from '../config/keys';
import { getUserFromLocalStorage } from '../services';
import ConfirmationPopup from './Global/ConfirmationPopup';

interface UserInfo {
    userId: string;
    name: string;
    availableCredits: number;
}

interface CarfInfo {
    _id: string;
    author_id: string;
    query: string;
    pdfLink: string;
    pdfNumber: string;
    numero_processo: string; // Updated field name
    data_sessao: string; // Updated field name
    data_publicacao: string; // Updated field name
    ementa: string; // Updated field name
    numero_decisao: string; // Updated field name
    decisao: string; // Updated field name
    nome_relator: string; // Updated field name
    __v: number;
}

interface DownloadData {
    taxId: string;
    name: string;
}

const WorkLaws: React.FC = () => {
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [inputValue, setInputValue] = useState('');

    const [companies, setCompanies] = useState<CarfInfo[]>([]);
    const [allCompanies, setAllCompanies] = useState<CarfInfo[]>([]);
    const [download, setDownload] = useState<DownloadData[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [kpisData, setKpisData] = useState<any>({});
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [copiedText, setCopiedText] = useState('');
    const [companyIdToDelete, setCompanyIdToDelete] = useState<string | null>(
        null
    );
    const companiesPerPage = 3;
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [filteredCompanies, setFilteredCompanies] = useState<CarfInfo[]>([]);

    const indexOfLastCompany = currentPage * companiesPerPage;
    const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
    const currentFilteredCompanies = filteredCompanies.slice(
        indexOfFirstCompany,
        indexOfLastCompany
    );

    const totalPages = Math.ceil(filteredCompanies.length / companiesPerPage);

    useEffect(() => {
        const filtered = companies.filter(company =>
            company.query.toLowerCase().includes(searchFilter.toLowerCase())
        );
        setFilteredCompanies(filtered);
    }, [companies, searchFilter]);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get<UserInfo>(
                    `${REACT_APP_SERVER_URL}/api/users/infos/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    setUserInfo(response.data);
                })
                .catch(error => {
                    console.error('Error fetching user information:', error);
                });
        }
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();
        const formattedToday = `${dd}/${mm}/${yyyy}`;

        axios
            .get<DownloadData[]>(
                `${REACT_APP_SERVER_URL}/api/datastore/download-company-data/${JSON.parse(
                    storedUserId!
                )}?startDate=${formattedToday}`
            )
            .then(response => {
                setDownload(response.data);
            })
            .catch(error => {
                console.error('Error fetching user information:', error);
            });
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        axios
            .get<CarfInfo[]>(
                `${REACT_APP_SERVER_URL}/api/carf/data-carf/${JSON.parse(
                    storedUserId!
                )}`
            )
            .then(response => {
                console.log('Company Data:', response.data);
                setAllCompanies(response.data);
                setCompanies(response.data);
            })
            .catch(error => {
                console.error('Error fetching user information:', error);
            });
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/datastore/kpis-company-data/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    console.log('KPIs Data:', response.data);
                    setKpisData(response.data);
                })
                .catch(error => {
                    console.error('Error fetching KPIs data:', error);
                });
        }
    }, []);

    const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const handlePrevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleDeleteCompany = (companyId: string) => {
        setCompanyIdToDelete(companyId);
        setShowConfirmationPopup(true);
    };

    const handleCancelDelete = () => {
        setCompanyIdToDelete(null);
        setShowConfirmationPopup(false);
    };

    const handleConfirmDelete = async () => {
        try {
            console.log('Deleting company with ID:', companyIdToDelete);
            await axios.delete(
                `${REACT_APP_SERVER_URL}/api/datastore/delete-company-data`,
                {
                    params: {
                        _id: companyIdToDelete,
                    },
                }
            );

            console.log('Company deleted successfully');
        } catch (error) {
            console.error('Error deleting company:', error);
        } finally {
            setCompanyIdToDelete(null);
            setShowConfirmationPopup(false);
            window.location.reload();
        }
    };

    const handleCopyClick = (text: string) => {
        navigator.clipboard.writeText(text);
        setCopiedText(`Texto copiado: ${text}`);

        // Limpar a mensagem após alguns segundos (opcional)
        setTimeout(() => {
            setCopiedText('');
        }, 3000);
    };

    const handleSearch = async () => {
        const user = getUserFromLocalStorage();
      
        if (user && user.userId && inputValue.trim() !== '') {
          const userId = user.userId.replace(/^"(.*)"$/, '$1');
          console.log(userId)

      
          try {
            setLoading(true);
      
            // Construa a URL com os parâmetros diretamente
            const url = `${REACT_APP_SERVER_URL}/api/work-laws/question/?author_id=${userId}&text=${inputValue}`;
            console.log(url)
      
            // Faça a requisição para a API OpenAI
            const response = await axios.get(url);
      
            console.log('Requisição enviada com sucesso:', response.data);
      
            // Mostre a resposta no console ou faça o que desejar com ela
            console.log(response.data.openaiResponse);
          } catch (error) {
            console.error('Erro ao enviar a requisição:', error);
            // Trate o erro, se necessário
          } finally {
            setLoading(false);
          }
        } else {
          console.error('Usuário não encontrado ou input vazio');
          // Adicione tratamento para o caso em que o usuário não é encontrado ou o input está vazio
        }
      };
      

    return (
        <div className="min-h-screen bg-[#111827] py-10">
            <div>
                <Link
                    to="/"
                    className="text-white hover:text-[#39A2A7]/80 font-semibold text-sm font-montserrat"
                >
                    &#8592; Voltar
                </Link>
            </div>
            <div className="flex mt-8 space-x-4 -ml-4">
                <div className="bg-[#1f2937] rounded-lg p-6 w-[400px] h-full">
                    <p className="text-2xl text-white font-semibold cursor-default font-montserrat rounded">
                        Assistente de IA para <br />
                        <span className="bg-[#02989E] px-2 py-1 animate-pulse font-montserrat rounded text-black text-2xl">
                            Legislação Trabalhista
                        </span>
                    </p>
                    <p className="mb-4 text-sm font-montserrat text-white mt-2">
                        Acesse as leis trabalhistas de forma automatizada.
                    </p>
                    <div className="flex flex-col items-center mb-2 h-12">
                        {' '}
                        {/* Ajustei a classe h-10 para h-12 */}
                        <input
                            type="text"
                            placeholder="Faça sua pergunta..."
                            className="border rounded-lg p-4 w-full font-montserrat bg-[#1f2937] focus:outline-none text-white mr-2" // Ajustei a classe p-10 para p-4
                            value={inputValue}
                            onChange={e => setInputValue(e.target.value)}
                        />
                    </div>

                    <div className="mt-4">
                        <button
                            id="search-button"
                            onClick={handleSearch}
                            className={`mt-3 bg-[#111827] text-white px-6 py-2 rounded font-medium hover:bg-[#111827]/50 focus:outline-none focus:ring focus:border-blue-300 font-montserrat`}
                            disabled={loading}
                        >
                            {loading ? (
                                'Aguarde...'
                            ) : (
                                <RiRobot2Line size={24} />
                            )}
                        </button>

                        <button
                            type="button"
                            id="download"
                            className="mt-2 ml-3 bg-[#111827] text-white px-6 py-2 rounded font-medium hover:bg-[#111827]/50 focus:outline-none focus:ring focus:border-blue-300 font-montserrat"
                        >
                            <Download />
                        </button>
                    </div>
                </div>

                <div>
                    <div
                        className="flex justify-center -mt-14 text-sm font-montserrat ml-5"
                        id="kpis"
                    >
                        <div>
                            <div className="mx-0 md:max-w-8xl lg:max-w-8xl xl:max-w-12xl">
                                <div>
                                    <div className="container mx-auto p-4 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-12">
                                        <div className="mt-4 mb-0">
                                            <input
                                                type="text"
                                                id="search"
                                                value={searchFilter}
                                                onChange={e =>
                                                    setSearchFilter(
                                                        e.target.value
                                                    )
                                                }
                                                placeholder="Pesquisar Assunto..."
                                                className="w-[900px] ml-0 h-8 px-4 pr-10 text-sm placeholder-gray-400 bg-[#1f2937] border rounded focus:outline-none focus:border-[#39A2A7] text-white"
                                            />
                                        </div>
                                        <div
                                            className="w-[900px]"
                                            id="companies"
                                        >
                                            {currentFilteredCompanies.length >
                                            0 ? (
                                                currentFilteredCompanies.map(
                                                    company => (
                                                        <div
                                                            key={company._id}
                                                            className="bg-[#1f2937]  text-white rounded-lg p-6 cursor-pointer mr-0 w-full h-auto -mt-8 mb-12"
                                                        >
                                                            <div className="flex items-center justify-between">
                                                                <div className="flex items-center -mt-2">
                                                                    <div className="bg-yellow-500 font-semibold text-black text-xs font-montserrat px-2 py-1 rounded-full">
                                                                        {
                                                                            company.query
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <X
                                                                    size={24}
                                                                    className="cursor-pointer text-red-500 hover:text-red-700 mb-0 -mt-2"
                                                                    onClick={() =>
                                                                        handleDeleteCompany(
                                                                            company._id
                                                                        )
                                                                    }
                                                                />
                                                            </div>

                                                            <hr className="my-2 border-t border-gray-300 mt-" />
                                                            <div className="relative">
                                                                <div className="flex items-center">
                                                                    <div className="w-full mt-2">
                                                                        <div className="flex">
                                                                            <div className="mt-0">
                                                                                <p className="text-sm mb-3 overflow-hidden overflow-ellipsis max-w-full whitespace-nowraptext-gray-400">
                                                                                    <span className="text-sm text-gray-400">
                                                                                        Data
                                                                                        da
                                                                                        Sessão:{' '}
                                                                                    </span>
                                                                                    <strong className="text-base">
                                                                                        {
                                                                                            company.data_sessao
                                                                                        }
                                                                                    </strong>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex items-center space-x-2 ">
                                                                            <span className=" font-montserrat font-bold text-white text-base">
                                                                                Ementa:{' '}
                                                                            </span>
                                                                            <div className="border border-solid border-blue-400 p-2 mb-2 mt-0 rounded">
                                                                                <p
                                                                                    className="text-gray-400 text-xs font-montserrat mb-2"
                                                                                    onClick={() =>
                                                                                        handleCopyClick(
                                                                                            `Atividade 1: ${
                                                                                                company.ementa ||
                                                                                                'N/A'
                                                                                            }`
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {company.ementa ||
                                                                                        'N/A'}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="relative">
                                                                            <div>
                                                                                {' '}
                                                                                <p className="text-sm mb-0 mt-2 overflow-hidden overflow-ellipsis max-w-full whitespace-nowrap text-gray-400">
                                                                                    <span className="text-sm text-gray-400">
                                                                                        Relator:{' '}
                                                                                    </span>
                                                                                    <strong className="text-sm">
                                                                                        {
                                                                                            company.nome_relator
                                                                                        }
                                                                                    </strong>
                                                                                </p>
                                                                            </div>
                                                                            <div className="absolute -top-2 right-0">
                                                                                <div className="flex">
                                                                                    <div>
                                                                                        <button
                                                                                            className={`mt-2 bg-[#111827] text-white px-10 py-2 rounded font-bold hover:bg-[#111827]/50 focus:outline-none focus:ring focus:border-blue-300 font-montserrat`}
                                                                                        >
                                                                                            Resumir
                                                                                        </button>
                                                                                    </div>
                                                                                    <div>
                                                                                        <button
                                                                                            className={`mt-2 ml-2 bg-[#111827] text-white px-10 py-2 rounded font-bold hover:bg-[#111827]/50 focus:outline-none focus:ring focus:border-blue-300 font-montserrat`}
                                                                                        >
                                                                                            Parecer
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                            ) : (
                                                <div className="text-white text-center mt-6">
                                                    Nenhuma Jurisprudência
                                                    adicionada
                                                </div>
                                            )}
                                            {copiedText && (
                                                <div className="fixed bottom-4 right-4 bg-[#03689E] text-white font-semibold px-4 py-2 rounded">
                                                    {copiedText}
                                                </div>
                                            )}
                                            {currentFilteredCompanies.length >
                                                0 && (
                                                <div className="flex justify-center mt-8  text-gray-100 text-sm font-montserrat">
                                                    <button
                                                        onClick={handlePrevPage}
                                                        disabled={
                                                            currentPage === 1
                                                        }
                                                    >
                                                        Anterior
                                                    </button>
                                                    <span className="mx-2 text-gray-100 text-xs font-montserrat">
                                                        {currentPage}
                                                    </span>
                                                    <button
                                                        onClick={handleNextPage}
                                                        disabled={
                                                            currentPage ===
                                                            totalPages
                                                        }
                                                    >
                                                        Próxima
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showConfirmationPopup && (
                <ConfirmationPopup
                    message="Tem certeza de que deseja excluir esta empresa?"
                    onCancel={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />
            )}
        </div>
    );
};

export default WorkLaws;
