import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { REACT_APP_SERVER_URL } from '../config/keys';
import ConfirmationPopup from './Global/ConfirmationPopup';
import { useParams } from 'react-router-dom';
import Avatar from '../components/Chat/avatar/Avatar';

interface UserInfo {
    userId: string;
    name: string;
    availableCredits: number;
}

interface JurisInfo {
    _id: string;
    author_id: string;
    query: string;
    tribunal: string;
    uiGContent: string;
    gpt3GeneratedContent: string;
    uiGContentsLength: string;
    createdAt: string;
    __v: number;
}

export type Message = {
    text: string;
    sentBy: string;
    sentAt: Date;
    isChatOwner?: boolean;
    responseContent?: string;
};

const LegalDocs: React.FC = () => {
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [companies, setCompanies] = useState<JurisInfo[]>([]);
    const [allCompanies, setAllCompanies] = useState<JurisInfo[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [kpisData, setKpisData] = useState<any>({});
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [copiedText, setCopiedText] = useState('');
    const [companyIdToDelete, setCompanyIdToDelete] = useState<string | null>(
        null
    );
    const companiesPerPage = 3;
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [filteredCompanies, setFilteredCompanies] = useState<JurisInfo[]>([]);
    const [newMessage, setNewMessage] = useState(''); // Definindo o estado para a nova mensagem
    const [messages, setMessages] = useState<Message[]>([]); // Estado para armazenar as mensagens da conversa

    const indexOfLastCompany = currentPage * companiesPerPage;
    const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
    const currentFilteredCompanies = filteredCompanies.slice(
        indexOfFirstCompany,
        indexOfLastCompany
    );
    const totalPages = Math.ceil(filteredCompanies.length / companiesPerPage);

    const { companyId } = useParams();
    console.log(companyId);

    // Efeito para obter as informações do usuário quando o componente for montado
    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            axios
                .get<UserInfo>(
                    `${REACT_APP_SERVER_URL}/api/users/infos/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    setUserInfo(response.data);
                    // Enviar a mensagem inicial apenas quando as informações do usuário estiverem disponíveis
                    const initialMessage: Message = {
                        text: `Olá ${
                            response.data.name ? response.data.name : 'Usuário'
                        }, como posso te ajudar?`,
                        sentBy: 'Dra. Legalista',
                        sentAt: new Date(),
                        isChatOwner: false,
                    };
                    setMessages([initialMessage]);
                })
                .catch(error => {
                    console.error('Error fetching user information:', error);
                });
        }
    }, []);

    useEffect(() => {
        axios
            .get<JurisInfo[]>(
                `${REACT_APP_SERVER_URL}/api/unique/docs-data/${companyId}`
            )
            .then(response => {
                console.log('Company Data:', response.data);
                setAllCompanies(response.data);
                setCompanies(response.data);
            })
            .catch(error => {
                console.error('Error fetching user information:', error);
            });
    }, [companyId]);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/datastore/kpis-company-data/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    console.log('KPIs Data:', response.data);
                    setKpisData(response.data);
                })
                .catch(error => {
                    console.error('Error fetching KPIs data:', error);
                });
        }
    }, []);

    useEffect(() => {
        const filtered = companies.filter(
            company =>
                company.uiGContent
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase()) ||
                company.uiGContent
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase())
        );
        setFilteredCompanies(filtered);
    }, [companies, searchFilter]);

    // Função para formatar o conteúdo da jurisprudência
    function formatContent(content: string) {
        if (!content) return 'N/A';

        const blueHeaders = [
            'Número',
            'Classe',
            'Relator',
            'Origem',
            'Órgão julgador',
            'Data',
            'Fonte da publicação',
            'Decisão',
            'Doutrina',
            'Indexação',
            'Referência',
        ];

        const lines = content.split('\n\n').map((line, index) => {
            const isBlueHeader = blueHeaders.some(header =>
                line.trim().startsWith(header)
            );

            return (
                <p
                    key={index}
                    className={`font-montserrat text-sm mb-1 ${
                        isBlueHeader
                            ? 'text-white text-lg font-bold'
                            : 'text-white text-base font-light'
                    }`}
                >
                    {line}
                </p>
            );
        });

        return lines;
    }

    const firstDocumentNumber = (currentPage - 1) * companiesPerPage + 1;

    const doSendMessage = async () => {
        const sentBy = userInfo ? userInfo.name : 'Usuario';

        // Cria uma nova mensagem com o texto digitado no input e o nome do remetente
        const newMessageObject: Message = {
            text: newMessage,
            sentBy: sentBy,
            sentAt: new Date(), // Define a hora atual como a hora de envio
            isChatOwner: true, // Define como true porque é uma mensagem enviada pelo usuário
        };

        // Adiciona a nova mensagem ao estado de mensagens
        setMessages(prevMessages => [...prevMessages, newMessageObject]);

        try {
            // Faz a requisição à sua API para obter a resposta da Dra. Legalista
            const response = await axios.get(
                `${REACT_APP_SERVER_URL}/api/unique/question-docs-data/${companyId}`,
                {
                    params: {
                        message: newMessage, // Envia a mensagem digitada pelo usuário como parâmetro
                    },
                }
            );

            // Extrai a resposta da Dra. Legalista do campo 'aiResponse'
            const aiResponse = response.data.aiResponse;

            // Cria um objeto de mensagem para a resposta da Dra. Legalista
            const responseMessage: Message = {
                text: aiResponse, // Usa a resposta da Dra. Legalista como texto da mensagem
                sentBy: 'Dra. Legalista', // Define a Dra. Legalista como o remetente da mensagem
                sentAt: new Date(), // Define a hora atual como a hora de envio
                isChatOwner: false, // Define como false porque é uma mensagem da Dra. Legalista
                responseContent: aiResponse, // Define a resposta como conteúdo de resposta para exibição adicional, se necessário
            };

            // Adiciona a mensagem de resposta ao estado de mensagens
            setMessages(prevMessages => [...prevMessages, responseMessage]);
        } catch (error) {
            console.error(
                'Error fetching response from Dra. Legalista:',
                error
            );
            // Em caso de erro, você pode tratar de acordo com suas necessidades, por exemplo, exibir uma mensagem de erro ao usuário
        }

        // Limpa o campo de entrada
        setNewMessage('');
    };

    const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const handlePrevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleCancelDelete = () => {
        setCompanyIdToDelete(null);
        setShowConfirmationPopup(false);
    };

    const handleConfirmDelete = async () => {
        try {
            console.log('Deleting company with ID:', companyIdToDelete);
            await axios.delete(
                `${REACT_APP_SERVER_URL}/api/unique/delete-juris-data`,
                {
                    params: {
                        _id: companyIdToDelete,
                    },
                }
            );

            console.log('Company deleted successfully');
        } catch (error) {
            console.error('Error deleting company:', error);
        } finally {
            setCompanyIdToDelete(null);
            setShowConfirmationPopup(false);
            window.location.reload();
        }
    };

    return (
        <div className="min-h-screen bg-[#111827] py-10">
            <div>
                <Link
                    to="/legal"
                    className="text-white hover:text-[#39A2A7]/80 font-semibold text-sm font-montserrat"
                >
                    &#8592; Voltar
                </Link>
            </div>
            <div className="flex mt-8 space-x-4 -ml-4">
                <div className="bg-[#111827] border border-[#1f2937] rounded-lg shadow relative w-[400px] h-full">
                    <div className="border-b-2 border-b-gray-200 py-3 px-6 flex flex-row justify-between items-center h-15">
                        <div className="flex flex-row items-center space-x-1.5 -ml-2">
                            <div className="flex flex-col">
                                <p className="text-2xl text-white font-semibold cursor-default font-montserrat rounded">
                                    Assistente de IA para <br />
                                    <span className="bg-[#02989E] px-2 py-1 animate-pulse font-montserrat rounded text-black text-2xl">
                                        Jurisprudência
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="max-h-[400px] h-[400px] px-6 py-1 overflow-auto font-montserrat mt-3">
                        {messages.map((message: Message, index: number) => (
                            <div
                                key={index}
                                className={`py-2 flex flex-row w-full te ${
                                    message.isChatOwner
                                        ? 'justify-end'
                                        : 'justify-start'
                                }`}
                            >
                                <div
                                    className={`${
                                        message.isChatOwner
                                            ? 'order-2'
                                            : 'order-1'
                                    }`}
                                >
                                    <Avatar />
                                </div>
                                <div
                                    className={`px-2 w-fit py-3 flex flex-col bg-[#1f2937] rounded-lg text-white ${
                                        message.isChatOwner
                                            ? 'order-1 mr-2'
                                            : 'order-2 ml-2'
                                    }`}
                                >
                                    <span className="text-xs text-muted-foreground">
                                        {message.sentBy}&nbsp;-&nbsp;
                                        {new Date(
                                            message.sentAt
                                        ).toLocaleTimeString('pt-BR', {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                        })}
                                    </span>
                                    <span className="text-md">
                                        {message.text}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="flex flex-row items-center space-x-2">
                        {' '}
                        {/* Reduzi o espaço entre os elementos para 2 */}
                        <input
                            type="text"
                            value={newMessage}
                            onChange={e => setNewMessage(e.target.value)}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    e.preventDefault(); // Evita quebra de linha no input
                                    doSendMessage(); // Chama a função doSendMessage quando Enter for pressionado
                                }
                            }}
                            placeholder="Digite sua pergunta..."
                            className="w-[350px] ml-3 mb-2 px-3 py-2 text-sm text-gray-200 placeholder-gray-400 bg-[#4B5563] border border-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-[#4B5563] focus:border-transparent font-montserrat"
                        />
                        <button
                            type="button"
                            disabled={!newMessage || newMessage.length === 0}
                            className="px-4 py-1.5 mb-2 text-base font-medium text-center text-white rounded-lg  focus:ring-4 focus:outline-none  disabled:opacity-50 font-montserrat"
                            onClick={doSendMessage} // Chama a função doSendMessage quando o botão for clicado
                        >
                            <p className="mr-2 font-montserrat font-bold">
                                Enviar
                            </p>
                        </button>
                    </div>
                </div>
                <div>
                    <div
                        className="flex justify-center -mt-14 text-sm font-montserrat ml-0"
                        id="kpis"
                    >
                        <div>
                            <div className="mx-0 md:max-w-8xl lg:max-w-8xl xl:max-w-12xl">
                                <div>
                                    <div className="container mx-auto p-4 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-12">
                                        <div>
                                            <div className="mt-4 mb-0">
                                                <input
                                                    type="text"
                                                    id="search"
                                                    value={searchFilter}
                                                    onChange={e =>
                                                        setSearchFilter(
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder="Pesquisar Ementa..."
                                                    className="w-[900px] ml-0 h-8 px-4 pr-10 text-sm placeholder-gray-400 bg-[#1f2937] border rounded focus:outline-none focus:border-[#39A2A7] text-white"
                                                />
                                            </div>
                                            <div
                                                className="w-[900px]"
                                                id="companies"
                                            >
                                                {currentFilteredCompanies.length >
                                                0 ? (
                                                    <div className="bg-[#1f2937] text-white rounded-lg p-6 cursor-pointer mr-0 w-full h-auto mb-2 mt-4">
                                                        <div className="flex items-center justify-between">
                                                            <div className="flex items-center -mt-2">
                                                                <div className="bg-yellow-500 font-semibold text-black text-base font-montserrat px-2 py-1 rounded-full">
                                                                    {
                                                                        currentFilteredCompanies[0]
                                                                            .tribunal
                                                                    }
                                                                </div>
                                                                <div className="cursor-pointer flex items-center font mr-32 ml-5">
                                                                    <p className="text-sm mb-0 overflow-hidden overflow-ellipsis max-w-full whitespace-nowrap">
                                                                        <span className="text-base">
                                                                            Assunto:{' '}
                                                                        </span>
                                                                        <strong className="text-lg">
                                                                            {
                                                                                currentFilteredCompanies[0]
                                                                                    .query
                                                                            }
                                                                        </strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr className="my-2 border-t border-gray-300 mt-" />
                                                        <div className="relative">
                                                            <div className="flex items-center">
                                                                <div className="w-full mt-2">
                                                                    <div className="flex items-center space-x-2 ">
                                                                        <span className=" font-montserrat font-bold text-white text-base">
                                                                            Parecer:{' '}
                                                                        </span>
                                                                        <div className="border border-solid border-blue-400 p-2 mb-2 mt-0 rounded">
                                                                            <p
                                                                                className="text-gray-400 text-xs font-montserrat mb-2"
                                                                                onClick={() =>
                                                                                    `Atividade 1: ${
                                                                                        currentFilteredCompanies[0]
                                                                                            .gpt3GeneratedContent ||
                                                                                        'N/A'
                                                                                    }`
                                                                                }
                                                                            >
                                                                                {currentFilteredCompanies[0]
                                                                                    .gpt3GeneratedContent ||
                                                                                    'N/A'}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="text-white text-center mt-6">
                                                        Nenhum Documento
                                                        encontrado
                                                    </div>
                                                )}
                                            </div>
                                            <div className="mt-6 ">
                                                <span className="font-montserrat text-white text-xl font-bold">
                                                    Documentos Analisados:
                                                </span>
                                            </div>
                                            <div
                                                className="w-[900px]"
                                                id="companies"
                                            >
                                                {currentFilteredCompanies.length >
                                                0 ? (
                                                    currentFilteredCompanies.map(
                                                        (company, index) => (
                                                            <div
                                                                key={
                                                                    company._id
                                                                }
                                                                className="bg-[#1f2937]  text-white rounded-lg p-6 cursor-pointer mr-0 w-full h-auto mb-2 mt-4"
                                                            >
                                                                <div className="flex items-center justify-between">
                                                                    <div className="flex items-center -mt-2">
                                                                        <div className="bg-gray-400 font-semibold text-black text-base font-montserrat px-2 py-1 rounded-full">
                                                                            {
                                                                                company.tribunal
                                                                            }
                                                                        </div>
                                                                        <div className="bg-yellow-500 font-semibold text-black text-base font-montserrat px-2 py-1 rounded-full ml-2">
                                                                            {
                                                                                company.query
                                                                            }
                                                                        </div>
                                                                        <div className=" font-semibold text-white text-base font-montserrat px-2 py-1 rounded-full ml-2">
                                                                            <p className="text-sm mb-0 overflow-hidden overflow-ellipsis max-w-full whitespace-nowrap">
                                                                                <strong className="text-base font-semibold">
                                                                                    Documento{' '}
                                                                                    {firstDocumentNumber +
                                                                                        index}
                                                                                </strong>
                                                                            </p>
                                                                        </div>
                                                                        <div className=" py-2 px-0 rounded-md text-white flex items-center justify-center font-montserrat -ml-10"></div>
                                                                    </div>
                                                                </div>
                                                                <hr className="my-2 border-t border-gray-300 mt-" />
                                                                <div className="cursor-pointer flex items-center">
                                                                    <strong className="text-lg font-montserrat text-white">
                                                                        {formatContent(
                                                                            company.uiGContent
                                                                        ) ||
                                                                            'N/A'}
                                                                    </strong>
                                                                </div>
                                                            </div>
                                                        )
                                                    )
                                                ) : (
                                                    <div className="text-white text-center mt-6">
                                                        Nenhuma Jurisprudência
                                                        adicionada
                                                    </div>
                                                )}
                                                {copiedText && (
                                                    <div className="fixed bottom-4 right-4 bg-[#03689E] text-white font-semibold px-4 py-2 rounded">
                                                        {copiedText}
                                                    </div>
                                                )}
                                                {currentFilteredCompanies.length >
                                                    0 && (
                                                    <div className="flex justify-center mt-8  text-gray-100 text-sm font-montserrat">
                                                        <button
                                                            onClick={
                                                                handlePrevPage
                                                            }
                                                            disabled={
                                                                currentPage ===
                                                                1
                                                            }
                                                        >
                                                            Anterior
                                                        </button>
                                                        <span className="mx-2 text-gray-100 text-xs font-montserrat">
                                                            {currentPage}
                                                        </span>
                                                        <button
                                                            onClick={
                                                                handleNextPage
                                                            }
                                                            disabled={
                                                                currentPage ===
                                                                totalPages
                                                            }
                                                        >
                                                            Próxima
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showConfirmationPopup && (
                <ConfirmationPopup
                    message="Tem certeza de que deseja excluir esta jurisprudência?"
                    onCancel={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />
            )}
        </div>
    );
};

export default LegalDocs;
