import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { X } from 'lucide-react';
import { FaPlus } from 'react-icons/fa';
import { RiRobot2Line } from 'react-icons/ri';
import axios from 'axios';

import ConfirmationPopup from './Global/ConfirmationPopup';

import { REACT_APP_SERVER_URL } from '../config/keys';
import { getUserFromLocalStorage } from '../services';

interface UserInfo {
    userId: string;
    name: string;
    availableCredits: number;
}

interface InvoiceResponse {
    _id: string;
    name: string;
    url: string;
    image?: string; // Pode ser opcional dependendo do seu modelo
    description: string;
    datePublished: string;
    filteredText: string;
    category: string;
    providerName: string;
    hasSummary: boolean;
    like: boolean;
}

interface Provider {
    name: string;
    isBlocked: boolean;
}

interface DateSelectorPopupProps {
    onClose: () => void; // Define uma função que não aceita argumentos e não retorna nada
    onSelectToday: () => void; // Similarmente para as demais ações
    onSelectYesterday: () => void;
}

const DateSelectorPopup: React.FC<DateSelectorPopupProps> = ({
    onClose,
    onSelectToday,
    onSelectYesterday,
}) => {
    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75">
            <div className="relative bg-white w-1/3 min-w-[320px] max-w-lg p-8 rounded-lg shadow-xl">
                <div className="absolute top-4 right-4">
                    <button
                        className="text-gray-500 hover:text-gray-700"
                        onClick={onClose}
                    >
                        <span className="text-2xl">&times;</span>
                    </button>
                </div>
                <h2 className="text-2xl mb-1 font-bold text-gray-700">
                    Baixar Caderno de Notícias
                </h2>
                <p className="text-sm text-gray-600 mb-4">
                    Selecione a data do caderno de notícias que deseja baixar:
                </p>
                <div className="flex justify-center space-x-2">
                    <button
                        className="px-6 py-3 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition duration-150 ease-in-out font-montserrat"
                        onClick={onSelectYesterday}
                    >
                        Caderno de Ontem
                    </button>
                    <button
                        className="px-6 py-3 bg-[#02989E] text-white rounded-lg hover:bg-[#02989E]/90 transition duration-150 ease-in-out font-montserrat"
                        onClick={onSelectToday}
                    >
                        Caderno de Hoje
                    </button>
                </div>
            </div>
        </div>
    );
};

const SubscriberNews: React.FC = () => {
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [companies, setCompanies] = useState<InvoiceResponse[]>([]);
    const [allCompanies, setAllCompanies] = useState<InvoiceResponse[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [kpisData, setKpisData] = useState<any>({});
    const [loadingSummarize, setLoadingSummarize] = useState(false);
    const companiesPerPage = 3;
    const [downloading, setDownloading] = useState(false);

    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [companyIdToDelete, setCompanyIdToDelete] = useState<string | null>(
        null
    );

    const [searchFilter, setSearchFilter] = useState<string>('');
    const [filteredCompanies, setFilteredCompanies] = useState<
        InvoiceResponse[]
    >([]);

    const [showDateSelectorPopup, setShowDateSelectorPopup] = useState(false);

    useEffect(() => {
        const filtered = companies.filter(
            company =>
                company.name &&
                company.name.toLowerCase() !== 'N/A' &&
                company.name.toLowerCase().includes(searchFilter.toLowerCase())
        );
        setFilteredCompanies(filtered);
    }, [companies, searchFilter]);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get<UserInfo>(
                    `${REACT_APP_SERVER_URL}/api/users/infos/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    setUserInfo(response.data);
                })
                .catch(error => {
                    console.error('Error fetching user information:', error);
                });
        }
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        axios
            .get<InvoiceResponse[]>(
                `${REACT_APP_SERVER_URL}/api/subscriber-news/news-data/${JSON.parse(
                    storedUserId!
                )}`
            )
            .then(response => {
                setAllCompanies(response.data);
                setCompanies(response.data);
            })
            .catch(error => {
                console.error('Error fetching user information:', error);
            });
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/subscriber-news/kpis-news-data/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    setKpisData(response.data);
                })
                .catch(error => {
                    console.error('Error fetching KPIs data:', error);
                });
        }
    }, []);

    const [uniqueProviders, setUniqueProviders] = useState<Provider[]>([]);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        const fetchData = async () => {
            try {
                if (storedUserId) {
                    const response = await axios.get(
                        `${REACT_APP_SERVER_URL}/api/subscriber-news/provider-list-data/${JSON.parse(
                            storedUserId
                        )}`
                    );
                    setUniqueProviders(response.data.uniqueProviders);
                }
            } catch (error) {
                console.error('Error fetching provider list:', error);
            }
        };

        fetchData();
    }, []); // Sem dependência para que o useEffect seja executado apenas uma vez

    const showProvidersList = uniqueProviders.length > 0;

    const indexOfLastCompany = currentPage * companiesPerPage;
    const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
    const currentFilteredCompanies = filteredCompanies.slice(
        indexOfFirstCompany,
        indexOfLastCompany
    );

    const totalPages = Math.ceil(filteredCompanies.length / companiesPerPage);

    const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const handlePrevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleDeleteCompany = (companyId: string) => {
        // Salva a página atual antes de iniciar o processo de exclusão
        const currentPageBeforeDelete = currentPage;

        setCompanyIdToDelete(companyId);
        setShowConfirmationPopup(true);

        // Salva a página atual após o início do processo de exclusão
        setPreviousPageBeforeDelete(currentPageBeforeDelete);
    };

    const handleCancelDelete = () => {
        setCompanyIdToDelete(null);
        setShowConfirmationPopup(false);
    };

    const handleConfirmDelete = async () => {
        try {
            console.log('Deleting company with ID:', companyIdToDelete);
            await axios.delete(
                `${REACT_APP_SERVER_URL}/api/subscriber-news/delete-news-data`,
                {
                    params: {
                        _id: companyIdToDelete,
                    },
                }
            );

            console.log('Company deleted successfully');
        } catch (error) {
            console.error('Error deleting company:', error);
        } finally {
            // Restaura a página anterior se a exclusão não afetar diretamente a página
            setCurrentPage(prevPage =>
                companyIdToDelete === null ? prevPage : previousPageBeforeDelete
            );
            setCompanyIdToDelete(null);
            setShowConfirmationPopup(false);
        }
    };

    const [previousPageBeforeDelete, setPreviousPageBeforeDelete] =
        useState<number>(1);

    const handleSummarize = async (companyId: string, filteredText: string) => {
        try {
            setLoadingSummarize(true);

            // Assuming you have a function to get user data from local storage
            const user = getUserFromLocalStorage();

            if (user && user.userId) {
                const userId = user.userId.replace(/^"(.*)"$/, '$1');

                const response = await axios.post(
                    `${REACT_APP_SERVER_URL}/api/subscriber-news/summarize/${companyId}?author_id=${userId}`,
                    { text: filteredText }
                );

                console.log('Resposta da sumarização:', response.data);

                // Atualize o estado local para refletir a mudança no botão
                // Por exemplo, você pode ter uma lista de empresas onde você atualiza o estado de uma empresa específica após a sumarização
                // Supondo que você tenha uma lista de empresas chamada 'companies', você pode atualizar o estado dessa lista

                setCompanies(prevCompanies => {
                    const updatedCompanies = prevCompanies.map(company => {
                        if (company._id === companyId) {
                            // Atualiza o estado do botão para 'download'
                            return { ...company, hasSummary: true };
                        }
                        return company;
                    });
                    return updatedCompanies;
                });
            } else {
                console.error(
                    'Usuário não encontrado ou sem ID no armazenamento local.'
                );
            }
        } catch (error) {
            console.error('Erro ao chamar a API de sumarização:', error);
        } finally {
            setLoadingSummarize(false);
        }
    };

    const handleDownload = (companyId: string) => {
        const user = getUserFromLocalStorage();
        setDownloading(true);

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');
            const authorId = encodeURIComponent(userId); // Certifique-se de codificar o authorId corretamente

            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/subscriber-news/download-sample/${companyId}?author_id=${authorId}`
                )
                .then(response => {
                    // Lógica para processar a resposta
                    console.log(
                        'Download realizado com sucesso:',
                        response.data
                    );

                    // Abre uma nova aba do navegador com os campos da resposta
                    const {
                        datePublished,
                        summary,
                        name,
                        fonte,
                        gpt3GeneratedContent,
                        url,
                    } = response.data;

                    const htmlContent = `
                        <html>
                            <head>
                                <title>Neuron Lab | Resumo Gerado</title>
                                <style>
                                    body {
                                        font-family: 'Montserrat', sans-serif;
                                        background-color: #111827;
                                        color: #ffffff;
                                        padding: 20px;
                                        line-height: 1.8;
                                    }
                                    h2 {
                                        color: #39A2A7;
                                        margin-bottom: 15px;
                                    }
                                    h4 {
                                        color: #D9D9D9;
                                        margin-bottom: 20px;
                                    }
                                    p {
                                        margin-bottom: 25px;
                                        font-size: 16px;
                                    }
                                    a {
                                        color: #39A2A7;
                                        text-decoration: underline;
                                    }
                                </style>
                            </head>
                            <body>
                                <h2>${name}</h2>
                                <h4>Data: ${datePublished}</h4>
                                <p>${gpt3GeneratedContent.replace(
                                    /\n/g,
                                    '<br>'
                                )}</p>
                                <p><a href="${url}" target="_blank">Link: ${url}</a></p>
                            </body>
                        </html>
                    `;

                    // Verifica se newTab não é null antes de acessar suas propriedades
                    const newTab = window.open();
                    if (newTab) {
                        newTab.document.write(htmlContent);
                    } else {
                        console.error(
                            'Não foi possível abrir a nova aba do navegador.'
                        );
                    }
                })
                .catch(error => {
                    console.error('Erro durante o download:', error);
                })
                .finally(() => {
                    // Atualiza o estado para indicar que o download foi concluído
                    setDownloading(false);
                });
        }
    };

    const handleNewsNotebook = () => {
        setShowDateSelectorPopup(true); // Mostra o popup
    };

    const handleSelectToday = () => {
        const user = getUserFromLocalStorage();
        setDownloading(true);

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');
            const authorId = encodeURIComponent(userId); // Certifique-se de codificar o authorId corretamente

            const today = new Date();
            const dd = String(today.getDate()).padStart(2, '0');
            const mm = String(today.getMonth() + 1).padStart(2, '0');
            const yyyy = today.getFullYear();
            const formattedToday = `${dd}/${mm}/${yyyy}`;

            console.log(formattedToday)

            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/subscriber-news/download-full-data/${authorId}?startDate=${formattedToday}`,
                    { responseType: 'blob' } // Define o tipo de resposta como Blob
                )
                .then(response => {
                    // Preparação do documento
                    const blob = new Blob([response.data], {
                        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    });
                    const url = URL.createObjectURL(blob);

                    // Criação do link de download
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `Caderno de Noticias_${formattedToday}.docx`; // Nome do arquivo com a data atual
                    // Simulação de clique no link de download
                    a.click();

                    // Simulação de clique no link de download
                    a.click();

                    // Limpeza do URL do Blob após o download
                    URL.revokeObjectURL(url);
                })
                .catch(error => {
                    console.error('Erro durante o download:', error);
                })
                .finally(() => {
                    // Atualiza o estado para indicar que o download foi concluído
                    setShowDateSelectorPopup(false); // Fecha o popup
                });
        }
    };

    const handleSelectYesterday = () => {
        const user = getUserFromLocalStorage();
        setDownloading(true);

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');
            const authorId = encodeURIComponent(userId); // Certifique-se de codificar o authorId corretamente

            const today = new Date();
            const yesterday = new Date(today); // Cria uma cópia da data de hoje
            yesterday.setDate(today.getDate() - 1); // Modifica essa cópia para representar o dia anterior

            const dd = String(yesterday.getDate()).padStart(2, '0');
            const mm = String(yesterday.getMonth() + 1).padStart(2, '0'); // getMonth() é baseado em zero
            const yyyy = yesterday.getFullYear();
            const formattedYesterday = `${dd}/${mm}/${yyyy}`; // Formata a data de ontem

            console.log(formattedYesterday)

            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/subscriber-news/download-full-data/${authorId}?startDate=${formattedYesterday}`,
                    { responseType: 'blob' } // Define o tipo de resposta como Blob
                )
                .then(response => {
                    // Preparação do documento
                    const blob = new Blob([response.data], {
                        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    });
                    const url = URL.createObjectURL(blob);

                    // Criação do link de download
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `Caderno de Noticias_${formattedYesterday}.docx`; // Nome do arquivo com a data de ontem
                    // Simulação de clique no link de download
                    a.click();

                    // Limpeza do URL do Blob após o download
                    URL.revokeObjectURL(url);
                })
                .catch(error => {
                    console.error('Erro durante o download:', error);
                })
                .finally(() => {
                    // Atualiza o estado para indicar que o download foi concluído
                    setShowDateSelectorPopup(false); // Fecha o popup
                });
        }
    };

    return (
        <div className="min-h-screen bg-[#111827] py-10">
            <div>
                <Link
                    to="/news"
                    className="text-white hover:text-[#39A2A7]/80 font-semibold text-sm font-montserrat"
                >
                    &#8592; Voltar
                </Link>
            </div>
            <div className="flex mt-8 space-x-4">
                <div>
                    <div className="bg-[#1f2937] rounded-lg p-6 w-auto h-auto">
                        <div className="mb-2">
                            <p className="text-2xl text-white font-semibold cursor-default font-montserrat rounded">
                                <span className="bg-[#02989E] px-2 py-1 mr-1 animate-pulse font-montserrat rounded text-black text-2xl">
                                    Notícias
                                </span>
                                <span className="font-semibold text-2xl">
                                    por assinatura
                                </span>
                            </p>
                        </div>

                        <p className="mb-4 text-sm font-montserrat text-white">
                            Acesse suas notícias por assinatura e resuma de
                            forma descomplicada.
                        </p>
                        <div className="mt-0">
                            <button
                                id="subscription-button"
                                className="mt-0 bg-[#111827] ml-0 text-white px-6 py-2 rounded font-medium font-montserrat disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                <span
                                    className="flex items-center"
                                    onClick={handleNewsNotebook}
                                >
                                    <RiRobot2Line
                                        size={24}
                                        className="mr-2 -ml-2"
                                    />
                                    <span className="font-montserrat text-base">
                                        Caderno de Notícias
                                    </span>
                                </span>
                            </button>
                        </div>
                    </div>
                    {showProvidersList && (
                        <div className="bg-[#1f2937] rounded-lg p-6 w-auto h-auto mt-4 max-h-[280px]">
                            <p className="mb-2 text-xl font-semibold text-white font-montserrat">
                                Fontes
                            </p>
                            <div className="text-sm font-montserrat text-white overflow-y-auto max-h-[150px] mt-2">
                                {uniqueProviders.map((provider, index) => (
                                    <div
                                        key={index}
                                        className="flex items-center justify-between py-2 border-b border-gray-500"
                                    >
                                        <div className="flex items-center">
                                            <span>{provider.name}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <div className="flex mt-8 space-x-4">
                    <div>
                        <div
                            className="flex justify-center -mt-14 text-sm font-montserrat"
                            id="kpis"
                        >
                            <div>
                                <div className="mx-0 md:max-w-8xl lg:max-w-8xl xl:max-w-12xl ml-30">
                                    <div className="grid grid-cols-1 md:grid-cols-6 gap-4 ml-5">
                                        <div className="col-span-1 bg-[#1f2937] border border-[#2c3848] text-white p-4 rounded-lg shadow-md">
                                            <p className="text-base text-muted-foreground font-montserrat">
                                                Total de Notícias
                                            </p>
                                            <p className="text-xl font-semibold">
                                                {kpisData.count_news || '-'}
                                            </p>
                                        </div>
                                        <div className="col-span-1 bg-[#1f2937] border border-[#2c3848] text-white p-4 rounded-lg shadow-md">
                                            <p className="text-base text-muted-foreground font-montserrat">
                                                Total de Categorias
                                            </p>
                                            <p className="text-xl font-semibold">
                                                {kpisData.countUniqueCategories ||
                                                    '-'}
                                            </p>
                                        </div>
                                        <div className="col-span-1 bg-[#1f2937] border border-[#2c3848] text-white p-4 rounded-lg shadow-md">
                                            <p className="text-base font-montserrat">
                                                Total de Fontes
                                            </p>
                                            <p className="text-xl font-semibold">
                                                {kpisData.countUniqueProviderNames ||
                                                    '-'}
                                            </p>
                                        </div>
                                        <div className="col-span-1 bg-[#1f2937] border border-[#2c3848] text-white p-4 rounded-lg shadow-md">
                                            <p className="text-base font-montserrat">
                                                Notícia Não Disponível
                                            </p>
                                            <p className="text-xl font-semibold">
                                                {kpisData.countEmptyFilteredText ||
                                                    '-'}
                                            </p>
                                        </div>
                                        <div className="col-span-1 bg-[#1f2937] border border-[#2c3848] text-white p-4 rounded-lg shadow-md">
                                            <p className="text-base font-montserrat">
                                                Notícia Disponível
                                            </p>
                                            <p className="text-xl font-semibold">
                                                {kpisData.countNonEmptyFilteredText ||
                                                    '-'}
                                            </p>
                                        </div>
                                        <button
                                            className="col-span-1 flex items-center justify-center border-dashed border-[#2c3848] border-2 text-[#ffffff] p-4 rounded-lg shadow-md hover:bg-[#1f2937] hover:text-white transition-all duration-300"
                                            onClick={() => {
                                                window.open(
                                                    '/kpis-invoices',
                                                    '_blank'
                                                );
                                            }}
                                            disabled
                                        >
                                            <p className="text-base font-montserrat">
                                                <FaPlus size={20} />
                                            </p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 mb-0">
                            <input
                                type="text"
                                id="search"
                                value={searchFilter}
                                onChange={e => setSearchFilter(e.target.value)}
                                placeholder="Pesquisar Notícia..."
                                className=" w-full ml-4 h-8 px-4 pr-10 text-sm placeholder-gray-400 bg-[#1f2937] border border-[#ffffff] rounded focus:outline-none focus:border-[#39A2A7] text-white"
                            />
                        </div>
                        <div
                            className="container mx-auto p-4 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-12 mt-6"
                            id="companies"
                        >
                            {currentFilteredCompanies.length === 0 ? (
                                <div className="flex items-center justify-center mt-10">
                                    <p className="text-gray-500 text-center text-lg font-montserrat">
                                        Nenhuma assinatura realizada.
                                    </p>
                                </div>
                            ) : (
                                currentFilteredCompanies.map(company => (
                                    <div
                                        key={company._id}
                                        className="bg-[#1f2937] text-white rounded-lg p-6 cursor-pointer mr-0 transition-transform hover:scale-105 w-96 md:w-72 h-auto -mt-0 "
                                    >
                                        <div className="ml-56 -mt-1 flex ">
                                            <div>
                                                <X
                                                    size={24}
                                                    className="cursor-pointer text-red-500 hover:text-red-700"
                                                    onClick={() =>
                                                        handleDeleteCompany(
                                                            company._id
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            <div>
                                                <img
                                                    src={
                                                        company.image ||
                                                        'https://placehold.it/700x400'
                                                    }
                                                    alt={company.name}
                                                    className="w-full h-auto -ml-8 -mt-16 mb-2 rounded-full  border-solid border border-[#2c3848]"
                                                    style={{
                                                        width: '90px',
                                                        height: '90px',
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="relative">
                                            <div className="flex items-center">
                                                <div className="w-full">
                                                    <p className="text-base font-medium font-montserrat mb-1 mt-1 overflow-hidden  max-w-full ">
                                                        {company.name || 'N/A'}
                                                    </p>
                                                    <p
                                                        className={`text-xs font-montserrat mt-2`}
                                                    >
                                                        Categoria:{' '}
                                                        {company.category ? (
                                                            <span className="bg-yellow-500 text-black font-montserrat font-semibold rounded-sm px-0.5 py-0.5 mr-2">
                                                                {
                                                                    company.category
                                                                }
                                                            </span>
                                                        ) : (
                                                            'N/A'
                                                        )}
                                                    </p>
                                                    <div className="flex items-center mt-2">
                                                        <p className="text-xs font-montserrat mr-2">
                                                            Fonte:{' '}
                                                            {company.providerName ? (
                                                                <span className="bg-white text-black font-montserrat font-semibold rounded-sm px-1 py-0.5">
                                                                    {
                                                                        company.providerName
                                                                    }
                                                                </span>
                                                            ) : (
                                                                'N/A'
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="border border-solid border-blue-400 p-2 mb-2 mt-4 rounded">
                                                        <p className="text-xs font-montserrat text-white font-medium">
                                                            {
                                                                company.description
                                                            }
                                                        </p>
                                                        <p className="text-gray-400 text-xs font-montserrat mt-2">
                                                            Data da Publicação:{' '}
                                                            {company.datePublished ||
                                                                'N/A'}
                                                        </p>
                                                        {company.url && (
                                                            <a
                                                                href={
                                                                    company.url
                                                                }
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="text-xs font-montserrat text-blue-300 inline-block hover:underline"
                                                            >
                                                                Leia Mais
                                                            </a>
                                                        )}
                                                    </div>
                                                    <div
                                                        key={company._id}
                                                        className="flex space-x-4 mt-4"
                                                    >
                                                        <button
                                                            className={`flex-1 block text-center bg-[#57B4B9] text-[#111827] font-montserrat font-semibold p-2 rounded-md hover:bg-[#57B4B9]/90 focus:outline-none focus:ring focus:border-[#57B4B9]/10`}
                                                            onClick={() =>
                                                                handleDownload(
                                                                    company._id
                                                                )
                                                            }
                                                            disabled={
                                                                downloading
                                                            }
                                                        >
                                                            {downloading
                                                                ? 'Aguarde...'
                                                                : 'Download'}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-center mt-4 ml-62 text-gray-100 text-sm font-montserrat">
                <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    Anterior
                </button>
                <span className="mx-2 text-gray-100 text-xs font-montserrat">
                    {currentPage}
                </span>
                <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    Próxima
                </button>
            </div>
            {showConfirmationPopup && (
                <ConfirmationPopup
                    message="Tem certeza de que deseja excluir esta notícia?"
                    onCancel={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />
            )}
            {showDateSelectorPopup && (
                <DateSelectorPopup
                    onClose={() => setShowDateSelectorPopup(false)}
                    onSelectToday={handleSelectToday}
                    onSelectYesterday={handleSelectYesterday}
                />
            )}
        </div>
    );
};

export default SubscriberNews;
