// PrivacyChoices.tsx
import React from 'react';

const PrivacyCookies: React.FC = () => {
    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white -mt-10">
            <h1 className="text-4xl font-bold mb-6 ml-10 text-[#39A2A7] font-montserrat">
                Política de Cookies Neuron Lab
            </h1>

            <div className="max-w-3xl p-8 bg-gray-800 rounded-md shadow-md overflow-y-auto h-96 text-base font-montserrat">
                <div className="mb-8">
                    <h3 className="text-xl font-bold">
                        1. Cookies ou dados de navegação
                    </h3>

                    <p>
                        Cookies são pequenos arquivos de texto que são
                        armazenados no seu dispositivo quando você visita um
                        site ou utiliza uma aplicação. Eles são amplamente
                        utilizados para garantir que as páginas da web funcionem
                        corretamente, bem como para fornecer informações aos
                        proprietários do site.
                    </p>

                    <h3 className="text-xl font-bold mt-4">
                        2. Tipos de Cookies Utilizados
                    </h3>

                    <p className="mb-4">
                        <strong>2.1 Cookies Necessários:</strong> Esses cookies
                        são essenciais para o funcionamento da Plataforma. Eles
                        permitem a navegação e utilização dos recursos, como o
                        acesso a áreas seguras da Plataforma.
                    </p>

                    <p className="mb-4">
                        <strong>2.2 Cookies Analíticos:</strong> Utilizamos
                        cookies analíticos para entender como os usuários
                        interagem com a Plataforma, reunindo informações de
                        forma anônima. Isso nos ajuda a melhorar continuamente a
                        experiência do usuário.
                    </p>

                    <p className="mb-4">
                        <strong>2.3 Cookies de Desempenho:</strong> Esses
                        cookies monitoram o desempenho da Plataforma, garantindo
                        uma resposta rápida e eficiente. Eles também ajudam a
                        identificar e resolver problemas operacionais.
                    </p>

                    <p className="mb-4">
                        <strong>2.4 Cookies de Funcionalidade:</strong> Cookies
                        de funcionalidade são usados para lembrar as escolhas
                        que você fez na Plataforma, como preferências de idioma,
                        personalização da interface, etc.
                    </p>

                    <h3 className="text-xl font-bold mt-4">
                        3. Gerenciamento de Cookies
                    </h3>

                    <p>
                        Você pode gerenciar as configurações de cookies
                        ajustando as preferências do seu navegador. No entanto,
                        desativar certos tipos de cookies pode afetar
                        negativamente a funcionalidade da Plataforma.
                    </p>

                    <h3 className="text-xl font-bold mt-4">
                        4. Cookies de Terceiros
                    </h3>

                    <p>
                        Podemos permitir que terceiros coloquem cookies na
                        Plataforma para fins de análise, publicidade e outras
                        finalidades. Esses cookies são regidos pelas políticas
                        de privacidade desses terceiros.
                    </p>

                    <h3 className="text-xl font-bold mt-4">
                        5. Alterações nesta Política de Cookies
                    </h3>

                    <p>
                        Reservamo-nos o direito de atualizar esta Política de
                        Cookies periodicamente. Recomendamos que você reveja
                        regularmente para ficar informado sobre como estamos
                        utilizando cookies.
                    </p>

                    <h3 className="text-xl font-bold mt-4">6. Contato</h3>

                    <p>
                        Se você tiver dúvidas ou preocupações sobre esta
                        Política de Cookies, entre em contato conosco através do{' '}
                        <a href="mailto:dpo@neuronlab.io">dpo@neuronlab.io</a>{' '}
                        ou pelo whatsapp (11) 99232-4421.
                    </p>

                    <p className="mt-4">
                        Ao continuar a usar a Plataforma, você concorda com o
                        uso de cookies de acordo com esta Política. Obrigado por
                        escolher a Neuron Lab.
                    </p>

                    <ul className="list-disc pl-6 mb-4 mt-4">
                        <li>
                            <a
                                href="https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Se você usa o Internet Explorer.
                            </a>
                        </li>
                        <li>
                            <a href="#">Se você usa o Firefox.</a>
                        </li>
                        <li>
                            <a
                                href="https://support.mozilla.org/pt-BR/kb/gerencie-configuracoes-de-armazenamento-local-de-s"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Se você usa o Firefox.
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&oco=1&hl=pt-BR"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Se você usa o Google Chrome.
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://support.microsoft.com/pt-br/help/4027947/microsoft-edge-delete-cookies"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Se você usa o Microsoft Edge.
                            </a>
                        </li>

                        <li>
                            <a
                                href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Se você usa Safari.
                            </a>
                        </li>
                    </ul>
                </div>

                <div className="mb-8">
                    <h2 className="text-2xl font-bold mb-4">
                        11. Encarregado de Proteção de Dados (DPO)
                    </h2>

                    <p>
                        A Neuron Lab disponibiliza os seguintes meios para que
                        os usuários e clientes possam entrar em contato conosco
                        para exercer seus direitos de titular: o e-mail
                        dpo@neuronlab.io e whatsapp (11) 99232-4421.
                    </p>
                </div>

                {/* Adicione o restante do conteúdo da política de privacidade aqui */}

                <p className="mt-8 text-sm">Última atualização: 08/01/2024</p>
            </div>
        </div>
    );
};

export default PrivacyCookies;
