import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import { X, Search, Download, RefreshCcw, GitMerge } from 'lucide-react';
import { FaPlus } from 'react-icons/fa';
import { RiRobot2Line } from 'react-icons/ri';
import axios from 'axios';
import * as XLSX from 'xlsx';
import Joyride from 'react-joyride';


import ConfirmationPopup from './Global/ConfirmationPopup';

import { REACT_APP_SERVER_URL } from '../config/keys';
import { getUserFromLocalStorage } from '../services';

interface UserInfo {
    userId: string;
    name: string;
    availableCredits: number;
}

interface DownloadData {
    _id: string;
    numeroDaNota: string;
    codigoVerificacaoNota: string;
}

interface InvoiceResponse {
    _id: string;
    createdAt: string;
    name: string;
    totalPages: number;
    content: string;
    hasSummary: boolean;
}

interface UserInfo {
    userId: string;
    name: string;
    availableCredits: number;
}

const Deed: React.FC = () => {
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [companies, setCompanies] = useState<InvoiceResponse[]>([]);
    const [allCompanies, setAllCompanies] = useState<InvoiceResponse[]>([]);
    const [download, setDownload] = useState<DownloadData[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const companiesPerPage = 3;

    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [companyIdToDelete, setCompanyIdToDelete] = useState<string | null>(
        null
    );

    const [tourFinished, setTourFinished] = useState(false);

    const [searchFilter, setSearchFilter] = useState<string>('');
    const [filteredCompanies, setFilteredCompanies] = useState<
        InvoiceResponse[]
    >([]);

    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
    const [loading, setLoading] = useState(false);
    const [copiedText, setCopiedText] = useState('');
    const [loadingSummarize, setLoadingSummarize] = useState(false);

    const handleCopyClick = (text: string) => {
        navigator.clipboard.writeText(text);
        setCopiedText(`Texto copiado: ${text}`);

        // Limpar a mensagem após alguns segundos (opcional)
        setTimeout(() => {
            setCopiedText('');
        }, 3000);
    };

    useEffect(() => {
        const filtered = companies.filter(
            company =>
                company.name &&
                company.name.toLowerCase() !== 'N/A' &&
                company.name.toLowerCase().includes(searchFilter.toLowerCase())
        );
        setFilteredCompanies(filtered);
    }, [companies, searchFilter]);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get<UserInfo>(
                    `${REACT_APP_SERVER_URL}/api/users/infos/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    setUserInfo(response.data);
                })
                .catch(error => {
                    console.error('Error fetching user information:', error);
                });
        }
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        axios
            .get<InvoiceResponse[]>(
                `${REACT_APP_SERVER_URL}/api/deed/deed-data/${JSON.parse(
                    storedUserId!
                )}`
            )
            .then(response => {
                setAllCompanies(response.data);
                setCompanies(response.data);
            })
            .catch(error => {
                console.error('Error fetching user information:', error);
            });
    }, []);

    const indexOfLastCompany = currentPage * companiesPerPage;
    const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
    const currentFilteredCompanies = filteredCompanies.slice(
        indexOfFirstCompany,
        indexOfLastCompany
    );

    const totalPages = Math.ceil(filteredCompanies.length / companiesPerPage);

    const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const handlePrevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleSearchClick = async () => {
        const user = getUserFromLocalStorage();

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');

            try {
                if (!selectedFiles || selectedFiles.length === 0) {
                    console.error('Nenhum arquivo selecionado.');
                    return;
                }

                setLoading(true);

                const formData = new FormData();

                for (let i = 0; i < selectedFiles.length; i++) {
                    formData.append('pdfFiles', selectedFiles[i]);
                }

                const endpoint = `${REACT_APP_SERVER_URL}/api/deed/upload?author_id=${userId}`;

                await axios.post(endpoint, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                setLoading(false);

                window.location.reload();
            } catch (error) {
                console.error('Erro durante o envio de arquivos:', error);

                setLoading(false);
            }
        }
    };

    const handleDeleteCompany = (companyId: string) => {
        setCompanyIdToDelete(companyId);
        setShowConfirmationPopup(true);
    };

    const handleCancelDelete = () => {
        setCompanyIdToDelete(null);
        setShowConfirmationPopup(false);
    };

    const handleConfirmDelete = async () => {
        try {
            console.log('Deleting Document with ID:', companyIdToDelete);
            await axios.delete(
                `${REACT_APP_SERVER_URL}/api/deed/delete-deed-data`,
                {
                    params: {
                        _id: companyIdToDelete,
                    },
                }
            );

            console.log('Document deleted successfully');
        } catch (error) {
            console.error('Error deleting company:', error);
        } finally {
            setCompanyIdToDelete(null);
            setShowConfirmationPopup(false);
            window.location.reload();
        }
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        setSelectedFiles(files);
    };

    const handleSummarize = async (companyId: string, content: string) => {
        try {
            setLoadingSummarize(true);

            // Assuming you have a function to get user data from local storage
            const user = getUserFromLocalStorage();

            if (user && user.userId) {
                setLoading(true);

                const userId = user.userId.replace(/^"(.*)"$/, '$1');

                const response = await axios.post(
                    `${REACT_APP_SERVER_URL}/api/summarization/summarize/${companyId}?author_id=${userId}`,
                    { text: content }
                );

                console.log('Resposta da sumarização:', response.data);

                window.location.reload();
            } else {
                console.error(
                    'Usuário não encontrado ou sem ID no armazenamento local.'
                );
            }
        } catch (error) {
            console.error('Erro ao chamar a API de sumarização:', error);
        } finally {
            setLoadingSummarize(false);
            setLoading(false);
        }
    };

    const handleDownloadClick = (companyId: string) => {
        axios
            .get(
                `${REACT_APP_SERVER_URL}/api/summarization/download-sample/${companyId}`
            )
            .then(response => {
                // Lógica para processar a resposta
                console.log('Download realizado com sucesso:', response.data);

                // Abre uma nova aba do navegador com os campos da resposta
                const { createdAt, summary, name } = response.data;

                const htmlContent = `
                <html>
                    <head>
                        <title>Neuron Lab | Resumo Gerado</title>
                        <style>
                            body {
                                font-family: 'Montserrat', sans-serif;
                                background-color: #111827;
                                color: #ffffff;
                                padding: 20px;
                                line-height: 1.8;
                            }
                            h2 {
                                color: #39A2A7;
                                margin-bottom: 15px;
                            }
                            h4 {
                                color: #D9D9D9;
                                margin-bottom: 20px;
                            }
                            p {
                                margin-bottom: 25px;
                                font-size: 16px;
                            }
                        </style>
                    </head>
                    <body>
                        <h2>${name}</h2>
                        <h4>Data: ${createdAt}</h4>
                        <p>${summary.replace(/\n/g, '<br>')}</p>
                    </body>
                </html>
            `;

                // Verifica se newTab não é null antes de acessar suas propriedades
                const newTab = window.open();
                if (newTab) {
                    newTab.document.write(htmlContent);
                } else {
                    console.error(
                        'Não foi possível abrir a nova aba do navegador.'
                    );
                }
            })
            .catch(error => {
                console.error('Erro durante o download:', error);
            });
    };

    function truncateString(
        str: string | undefined,
        maxLength: number
    ): string {
        return str && str.length > maxLength
            ? str.slice(0, maxLength)
            : str || '';
    }

    return (
        <div className="min-h-screen bg-[#111827] py-10">
            <div>
                <Link
                    to="/"
                    className="text-white hover:text-[#39A2A7]/80 font-semibold text-sm font-montserrat"
                >
                    &#8592; Voltar
                </Link>
            </div>
            <div className="flex mt-8 space-x-4">
                <div className="bg-[#1f2937] rounded-lg p-6  w-1/3 h-full">
                    <p className="text-2xl text-white font-semibold cursor-default font-montserrat rounded">
                        Assistente de IA para <br />
                        <span className="bg-[#02989E] px-2 py-1 animate-pulse font-montserrat rounded text-black text-2xl">
                            Escrituras de Imóveis
                        </span>
                    </p>
                    <p className="mb-4 text-sm font-montserrat text-white mt-2">
                        Analise de forma simplificada escrituras imobiliárias.
                    </p>
                    <div className="flex flex-col items-center mb-2">
                        <label
                            htmlFor="fileInput"
                            className="cursor-pointer w-full"
                        >
                            <div
                                className="bg-[#1f2937] border-dashed border border-white py-2 px-4 rounded-md text-white flex items-center justify-center font-montserrat"
                                id="inputDocument"
                            >
                                Selecionar Escrituras
                            </div>
                        </label>
                        <input
                            type="file"
                            accept=".pdf"
                            multiple
                            className="hidden"
                            id="fileInput"
                            onChange={handleFileChange}
                        />
                        {selectedFiles && selectedFiles.length > 0 && (
                            <p className="mt-2 text-base font-light font-montserrat text-yellow-300 italic">
                                {`${selectedFiles.length} ${
                                    selectedFiles.length === 1
                                        ? 'documento selecionado'
                                        : 'documentos selecionados'
                                }`}
                            </p>
                        )}
                    </div>
                    <button
                        onClick={() => handleSearchClick()}
                        id="search-button"
                        className={`mt-3 bg-[#111827] text-white px-6 py-2 rounded font-medium hover:bg-[#111827]/50 focus:outline-none focus:ring focus:border-blue-300 font-montserrat ${
                            loading ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                        disabled={loading}
                    >
                        {loading ? 'Aguarde...' : <RiRobot2Line size={24} />}
                    </button>
                    <button
                        type="button"
                        id="download"
                        className="mt-2 ml-3 bg-[#111827] text-white px-6 py-2 rounded font-medium hover:bg-[#111827]/50 focus:outline-none focus:ring focus:border-blue-300 font-montserrat
            "
                        disabled
                    >
                        <Download />
                    </button>
                </div>
                <div>
                    <div className="mt-0 mb-0">
                        <input
                            type="text"
                            id="search"
                            value={searchFilter}
                            onChange={e => setSearchFilter(e.target.value)}
                            placeholder="Pesquisar Escritura..."
                            className=" w-full ml-4 h-8 px-4 pr-10 text-sm placeholder-gray-400 bg-[#1f2937] border border-[#ffffff] rounded focus:outline-none focus:border-[#39A2A7] text-white"
                        />
                    </div>
                    <div
                        className="container mx-auto p-4 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-12"
                        id="companies"
                    >
                        {currentFilteredCompanies.length === 0 ? (
                            <div className="flex items-center justify-center mt-10">
                                <p className="text-gray-500 text-center text-lg font-montserrat">
                                    Nenhuma escritura adicionada.
                                </p>
                            </div>
                        ) : (
                            currentFilteredCompanies.map(company => (
                                <div
                                    key={company._id}
                                    className="bg-[#1f2937] text-white rounded-lg p-6 cursor-pointer transition-transform hover:scale-105 w-96 md:w-72 h-auto -mt-0 ml-4"
                                >
                                    <X
                                        size={24}
                                        className="cursor-pointer text-red-500 hover:text-red-700 mb-2 -mt-2"
                                        onClick={() =>
                                            handleDeleteCompany(company._id)
                                        }
                                    />
                                    <div className="relative">
                                        <div className="flex items-center">
                                            <div className="w-full">
                                                <p
                                                    className="text-xl font-semibold font-montserrat mb-2 overflow-hidden max-w-full whitespace-normal"
                                                    onClick={() =>
                                                        handleCopyClick(
                                                            company.name ??
                                                                'N/A'
                                                        )
                                                    }
                                                >
                                                    {company.name &&
                                                    company.name.length > 90
                                                        ? `${decodeURIComponent(
                                                              company.name
                                                          ).slice(0, 90)}...`
                                                        : decodeURIComponent(
                                                              company.name
                                                          ) ?? 'N/A'}
                                                </p>

                                                <p
                                                    className={`text-xs font-montserrat mt-0`}
                                                >
                                                    {company.totalPages !==
                                                        undefined &&
                                                    company.totalPages !==
                                                        null ? (
                                                        <span className="bg-yellow-500 text-black font-montserrat font-semibold rounded-sm px-0.5 py-0.5 mr-2">
                                                            {company.totalPages}
                                                            {company.totalPages ===
                                                            1
                                                                ? ' Página'
                                                                : ' Páginas'}
                                                        </span>
                                                    ) : (
                                                        'N/A'
                                                    )}
                                                </p>
                                                <p className="text-gray-400 text-xs font-montserrat mt-2">
                                                    Adicionado em:{' '}
                                                    {company.createdAt || 'N/A'}
                                                </p>

                                                <div className="border border-solid border-blue-400 p-2 mb-2 mt-2 rounded">
                                                    <p
                                                        className="text-sm font-medium font-montserrat mb-2 whitespace-pre-line"
                                                        onClick={() =>
                                                            handleCopyClick(
                                                                company.content ||
                                                                    'N/A'
                                                            )
                                                        }
                                                        style={{
                                                            wordWrap:
                                                                'break-word',
                                                        }}
                                                    >
                                                        {company.content &&
                                                        company.content.length >
                                                            200
                                                            ? `${company.content.substring(
                                                                  0,
                                                                  200
                                                              )}...`
                                                            : company.content ||
                                                              'N/A'}
                                                    </p>
                                                </div>

                                                <div className="flex space-x-4 mt-4">
                                                    <button
                                                        className={`flex-1 block text-center bg-[#111827] text-white p-2 font-montserrat rounded-md hover:bg-[#111827]/70 focus:outline-none focus:ring focus:border-[#111827] ${
                                                            company.hasSummary
                                                                ? 'cursor-not-allowed opacity-50'
                                                                : ''
                                                        }`}
                                                        onClick={() =>
                                                            !company.hasSummary &&
                                                            !loading &&
                                                            handleSummarize(
                                                                company._id,
                                                                company._id
                                                            )
                                                        }
                                                        disabled={
                                                            company.hasSummary ||
                                                            loading
                                                        }
                                                    >
                                                        {loading
                                                            ? 'Aguarde...'
                                                            : 'Resumir'}
                                                    </button>

                                                    <button
                                                        className="flex-1 block text-center bg-gray-700 text-white font-montserrat p-2 rounded-md hover:bg-gray-900 focus:outline-none focus:ring focus:border-gray-300"
                                                        onClick={() =>
                                                            handleDownloadClick(
                                                                company._id
                                                            )
                                                        }
                                                        disabled={
                                                            !company.hasSummary
                                                        }
                                                    >
                                                        Download
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                        {copiedText && (
                            <div
                                className="fixed bottom-2 right-4 bg-[#03689E] text-white font-semibold px-4 py-2 rounded"
                                style={{
                                    maxWidth: '900px',
                                    maxHeight: '150px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {copiedText}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex justify-center mt-4 ml-62 text-gray-100 text-sm font-montserrat">
                <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    Anterior
                </button>
                <span className="mx-2 text-gray-100 text-xs font-montserrat">
                    {currentPage}
                </span>
                <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    Próxima
                </button>
            </div>
            {!tourFinished && (
                <Joyride
                    steps={[
                        {
                            target: '#inputDocument',
                            content: (
                                <div>
                                    <p>
                                        Bem-vindo a IA para Resumo de
                                        Documentos!
                                    </p>
                                    <br />
                                    <p>
                                        Na primeira etapa, faça o upload de um
                                        ou vários documentos nesse campo.
                                    </p>
                                </div>
                            ),
                            placement: 'bottom',
                        },
                        {
                            target: '#search-button', // Substitua '.search-button' pela classe ou id do seu botão de pesquisa
                            content: (
                                <div>
                                    <p>
                                        Agora, clique nesse botão para iniciar a
                                        análise dos documentos.
                                    </p>
                                </div>
                            ),
                        },
                        {
                            target: '#companies',
                            content: (
                                <div>
                                    <p>Visualize os resultados na dashboard.</p>
                                </div>
                            ),
                            placement: 'bottom',
                        },

                        {
                            target: '#download',
                            content: (
                                <div>
                                    <p>
                                        Para obter os dados, clique no botão de
                                        download.
                                    </p>
                                    <br />
                                    <p>
                                        Isso permitirá que você baixe as
                                        informações em um arquivo Docx.
                                    </p>
                                    <br />
                                    <p>Não fornecido na versão trial.</p>
                                </div>
                            ),
                        },
                        {
                            target: '#search',
                            content: (
                                <div>
                                    <p>
                                        Realize pesquisas pelo nome do documento
                                        salvo na sua base de dados.
                                    </p>
                                </div>
                            ),
                        },
                        {
                            target: '#kpis',
                            content: (
                                <div>
                                    <p>
                                        Indicadores com informações importantes
                                        das notas salvas na sua base de dados.
                                    </p>
                                </div>
                            ),
                        },
                    ]}
                    run={!tourFinished}
                    continuous
                    showSkipButton
                    callback={data => {
                        if (
                            data.status === 'finished' ||
                            data.status === 'skipped'
                        ) {
                            setTourFinished(true);
                        }
                    }}
                    styles={{
                        options: {
                            overlayColor: 'rgba(17, 24, 39, 0.9)',
                            primaryColor: '#39A2A7',
                            textColor: '#000000',
                            width: 400,
                            zIndex: 1000,
                        },
                        tooltip: {
                            fontFamily: 'Montserrat',
                        },
                        buttonNext: {
                            backgroundColor: '#39A2A7',
                            color: '#FFFFFF',
                            border: 'none',
                            borderRadius: '4px',
                            marginLeft: '0',
                        },
                    }}
                    locale={{
                        next: 'Próximo',
                        last: 'Último',
                        skip: 'Pular',
                        back: 'Anterior',
                    }}
                />
            )}
            {showConfirmationPopup && (
                <ConfirmationPopup
                    message="Tem certeza de que deseja excluir esta escritura?"
                    onCancel={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />
            )}
        </div>
    );
};

export default Deed;
