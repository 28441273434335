import React from 'react';

interface ConfirmationPopupProps {
  message: string;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({ message, onCancel, onConfirm }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="bg-white p-6 rounded shadow-md">
        <p className="mb-4 font-montserrat">{message}</p>
        <div className="flex justify-end">
          <button className="mr-2 px-4 py-2 bg-gray-500 text-white rounded font-montserrat" onClick={onCancel}>
            Cancelar
          </button>
          <button className="px-4 py-2 bg-red-500 text-white rounded font-montserrat" onClick={onConfirm}>
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
