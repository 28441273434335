import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';

export default function InputDialogPopup(props: any) {
    const [text, setText] = React.useState("");
    const [option, setOption] = React.useState("");

    React.useEffect(() => {
        if (!props.open) {
            setText("");
            setOption("");
        }
    }, [props.open])

    return (

        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
        >
            <DialogTitle
                id="alert-dialog-title"
                sx={{
                    fontWeight: 'bold'
                }}
            >
                {props.title}
            </DialogTitle>
            {
                props.content ?
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {props.content}
                        </DialogContentText>
                    </DialogContent> : null
            }
            {
                props.inputLabel ?
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label={props.inputLabel}
                        type="text"
                        variant="standard"
                        value={text}
                        onChange={(e) => {                            
                            setText(e.target.value)
                        }}

                        onKeyDown={(k)=>{
                            if(k.key == 'Enter'){
                                props.inputLabel && props.onSubmit(text)
                                props.options && props.onSubmit(option)
                                !props.options && !props.inputLabel && props.onSubmit()
                                props.handleClose()
                            }
                        }}  
                        

                        sx={{
                            marginLeft: '1.5rem',
                            marginRight: '1.5rem',

                            '& label.Mui-focused': {
                                color: '#02989E',
                            },
                            '& .MuiInput-underline:after': {
                                borderBottomColor: '#02989E',
                            },
                        }}
                        
                    /> : null

            }

            {
                
                props.options ?
                    <FormControl
                    variant="standard"
                    sx={{
                        marginTop: '0.5rem',
                        marginLeft: '1.5rem',
                        marginRight: '1.5rem',

                        '& label.Mui-focused': {
                            color: '#02989E',
                        },
                        '& .MuiInput-underline:after': {
                            borderColor: '#02989E',
                        },
                    }}
                    >
                        <InputLabel id="demo-simple-select-label">{props.optionLabel}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.objectToString(option)}
                            label={props.optionLabel}
                            onChange={(e) => setOption(
                                props.options.find(
                                    (element : any) => 
                                        props.objectToString(element) === e.target.value
                                    )
                            )}
                        >
                            {props.options.map((opt : any, idx : number) =>                                 
                                <MenuItem key={idx} value={props.objectToString(opt)}>{props.objectToString(opt)}</MenuItem>        
                            )}                            
                        </Select>
                    </FormControl> : null

            }


            <DialogActions
                className='mt-8'
            >
                <Button
                    variant="contained"
                    onClick={props.handleClose}
                    color="error"
                >
                    Cancelar
                </Button>

                <Button onClick={() => {
                    props.inputLabel && props.onSubmit(text)
                    props.options && props.onSubmit(option)
                    !props.options && !props.inputLabel && props.onSubmit()
                    props.handleClose()
                }}
                    variant="contained"
                    style={{
                        color: "#FFFFFF",
                        backgroundColor: '#02989E'
                    }}
                    autoFocus
                >
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>

    );
}