import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Loader } from 'lucide-react';

export default function MessageDialogPopup(props: any) {
    const [text, setText] = React.useState("");
    const [option, setOption] = React.useState("");

    React.useEffect(() => {
        if (!props.open) {
            setText("");
            setOption("");
        }
    }, [props.open])

    return (

        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
        >
            <DialogTitle
                id="alert-dialog-title"
                sx={{
                    fontWeight: 'bold'
                }}
            >
                {props.title}
            </DialogTitle>
            {
                props.loading ?
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Loader className="absolute top-4 right-4 animate-spin" size={32} />{" A assistente está pensando..."}
                        </DialogContentText>
                    </DialogContent> : null
            }
            {
                props.content ?
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {props.content}
                        </DialogContentText>
                    </DialogContent> : null
            }


            <DialogActions
                className='mt-8'
            >               
                {!props.loading && <Button onClick={() => {                    
                    props.handleClose()
                }}
                    variant="contained"
                    style={{
                        color: "#FFFFFF",
                        backgroundColor: '#02989E'
                    }}
                    autoFocus
                >
                    Confirmar
                </Button>}
            </DialogActions>
        </Dialog>

    );
}