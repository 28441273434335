import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import { X } from 'lucide-react';
import { FaPaperclip, FaTimes } from 'react-icons/fa';
import { RiRobot2Line } from 'react-icons/ri';
import { MdEmail } from 'react-icons/md';
import { Download } from 'lucide-react';
import * as XLSX from 'xlsx';

import axios from 'axios';

import { SlDocs } from 'react-icons/sl';

import { REACT_APP_SERVER_URL } from '../config/keys';
import { getUserFromLocalStorage } from '../services';
import ConfirmationPopup from './Global/ConfirmationPopup';

interface UserInfo {
    userId: string;
    name: string;
    availableCredits: number;
}

interface InvoiceResponse {
    _id: string;
    subject: string;
    from: string;
    to: string;
    body: string;
    gpt3GeneratedContent: string;
    createdAt: string;
}

interface DownloadData {
    taxId: string;
    name: string;
}

const Email: React.FC = () => {
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [inputValue, setInputValue] = useState('');

    const [companies, setCompanies] = useState<InvoiceResponse[]>([]);
    const [allCompanies, setAllCompanies] = useState<InvoiceResponse[]>([]);
    const [download, setDownload] = useState<DownloadData[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [kpisData, setKpisData] = useState<any>({});
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [copiedText, setCopiedText] = useState('');
    const [companyIdToDelete, setCompanyIdToDelete] = useState<string | null>(
        null
    );
    const [downloading, setDownloading] = useState(false);
    const [loadingSummarize, setLoadingSummarize] = useState(false);
    const companiesPerPage = 3;
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [filteredCompanies, setFilteredCompanies] = useState<
        InvoiceResponse[]
    >([]);
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
    const [comparing, setComparing] = useState(false);

    const indexOfLastCompany = currentPage * companiesPerPage;
    const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
    const currentFilteredCompanies = filteredCompanies.slice(
        indexOfFirstCompany,
        indexOfLastCompany
    );

    const totalPages = Math.ceil(filteredCompanies.length / companiesPerPage);
    const [selectedFilesCount, setSelectedFilesCount] = useState(0);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        setSelectedFiles(files);
        if (files) {
            setSelectedFilesCount(files.length);
            // Your existing code for handling file change
        }
    };

    useEffect(() => {
        const filtered = companies.filter(
            company =>
                company.subject
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase()) ||
                (company.body &&
                    company.body
                        .toLowerCase()
                        .includes(searchFilter.toLowerCase()))
        );
        setFilteredCompanies(filtered);
    }, [companies, searchFilter]);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get<UserInfo>(
                    `${REACT_APP_SERVER_URL}/api/users/infos/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    setUserInfo(response.data);
                })
                .catch(error => {
                    console.error('Error fetching user information:', error);
                });
        }
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();
        const formattedToday = `${dd}/${mm}/${yyyy}`;

        axios
            .get<DownloadData[]>(
                `${REACT_APP_SERVER_URL}/api/datastore/download-company-data/${JSON.parse(
                    storedUserId!
                )}?startDate=${formattedToday}`
            )
            .then(response => {
                setDownload(response.data);
            })
            .catch(error => {
                console.error('Error fetching user information:', error);
            });
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        axios
            .get<InvoiceResponse[]>(
                `${REACT_APP_SERVER_URL}/api/email-analysis/emails-data/${JSON.parse(
                    storedUserId!
                )}`
            )
            .then(response => {
                setAllCompanies(response.data);
                setCompanies(response.data);
            })
            .catch(error => {
                console.error('Error fetching user information:', error);
            });
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/datastore/kpis-company-data/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    console.log('KPIs Data:', response.data);
                    setKpisData(response.data);
                })
                .catch(error => {
                    console.error('Error fetching KPIs data:', error);
                });
        }
    }, []);

    const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const handlePrevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleDeleteCompany = (companyId: string) => {
        setCompanyIdToDelete(companyId);
        setShowConfirmationPopup(true);
    };

    const handleCancelDelete = () => {
        setCompanyIdToDelete(null);
        setShowConfirmationPopup(false);
    };

    const handleConfirmDelete = async () => {
        try {
            console.log('Deleting Document with ID:', companyIdToDelete);
            await axios.delete(
                `${REACT_APP_SERVER_URL}/api/email-analysis/delete-email-data`,
                {
                    params: {
                        _id: companyIdToDelete,
                    },
                }
            );

            console.log('Document deleted successfully');
        } catch (error) {
            console.error('Error deleting company:', error);
        } finally {
            setCompanyIdToDelete(null);
            setShowConfirmationPopup(false);
            window.location.reload();
        }
    };

    const handleCopyClick = (text: string) => {
        navigator.clipboard.writeText(text);
        setCopiedText(`Texto copiado: ${text}`);

        // Limpar a mensagem após alguns segundos (opcional)
        setTimeout(() => {
            setCopiedText('');
        }, 3000);
    };

    const handleSearchClick = async () => {
        const user = getUserFromLocalStorage();

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');

            try {
                if (!selectedFiles || selectedFiles.length === 0) {
                    console.error('Nenhum email selecionado.');
                    return;
                }

                setLoading(true);

                const formData = new FormData();

                for (let i = 0; i < selectedFiles.length; i++) {
                    formData.append('pdfFiles', selectedFiles[i]);
                }

                const endpoint = `${REACT_APP_SERVER_URL}/api/email-analysis/upload?author_id=${userId}`;

                await axios.post(endpoint, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                setLoading(false);

                window.location.reload();
            } catch (error) {
                console.error('Erro durante o envio de arquivos:', error);

                setLoading(false);
            }
        }
    };

    const handleDownloadExcel = () => {
        const rows = download.map(company => {
            return {
                'RAZÃO SOCIAL': company.name || 'N/A',
            };
        });

        console.log(rows);

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(rows);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'CNPJ');

        XLSX.writeFile(workbook, 'CNPJ.xlsx', { compression: true });
    };

    return (
        <div className="min-h-screen bg-[#111827] py-10">
            <div>
                <Link
                    to="/"
                    className="text-white hover:text-[#39A2A7]/80 font-semibold text-sm font-montserrat"
                >
                    &#8592; Voltar
                </Link>
            </div>
            <div className="flex mt-8 space-x-4 -ml-4">
                <div className="bg-[#1f2937] rounded-lg p-6  w-1/3 h-full">
                    <p className="text-2xl text-white font-semibold cursor-default font-montserrat rounded">
                        Assistente de IA para <br />
                        <span className="bg-[#02989E] px-2 py-1 animate-pulse font-montserrat rounded text-black text-2xl">
                            E-mails
                        </span>
                    </p>
                    <p className="mb-4 text-sm font-montserrat text-white mt-2">
                        Descubra insights poderosos em e-mails
                    </p>
                    <div className="flex flex-col items-center mb-2">
                        <label
                            htmlFor="fileInput"
                            className="cursor-pointer w-full"
                        >
                            <div
                                className="bg-[#1f2937] border-dashed border border-white py-2 px-4 rounded-md text-white flex items-center justify-center font-montserrat"
                                id="inputDocument"
                            >
                                Selecionar e-mails
                            </div>
                        </label>
                        <input
                            type="file"
                            accept=".eml"
                            multiple
                            className="hidden"
                            id="fileInput"
                            onChange={handleFileChange}
                        />
                        {selectedFiles && selectedFiles.length > 0 && (
                            <p className="mt-2 text-base font-light font-montserrat text-yellow-300 italic">
                                {`${selectedFiles.length} ${
                                    selectedFiles.length === 1
                                        ? 'email selecionado'
                                        : 'emails selecionados'
                                }`}
                            </p>
                        )}
                    </div>
                    <button
                        onClick={() => handleSearchClick()}
                        id="search-button"
                        className={`mt-3 bg-[#111827] text-white px-6 py-2 rounded font-medium hover:bg-[#111827]/50 focus:outline-none focus:ring focus:border-blue-300 font-montserrat ${
                            loading ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                        disabled={loading}
                    >
                        {loading ? 'Aguarde...' : <RiRobot2Line size={24} />}
                    </button>
                </div>
                <div>
                    <div
                        className="flex justify-center -mt-14 text-sm font-montserrat ml-5"
                        id="kpis"
                    >
                        <div>
                            <div className="mx-0 md:max-w-8xl lg:max-w-8xl xl:max-w-12xl">
                                <div>
                                    <div className="container mx-auto p-4 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-12">
                                        <div className="mt-0 mb-0">
                                            <input
                                                type="text"
                                                id="search"
                                                value={searchFilter}
                                                onChange={e =>
                                                    setSearchFilter(
                                                        e.target.value
                                                    )
                                                }
                                                placeholder="Pesquisar E-mail..."
                                                className="w-[900px] ml-0 h-8 px-4 pr-10 text-sm placeholder-gray-400 bg-[#1f2937] border rounded focus:outline-none focus:border-[#39A2A7] text-white"
                                            />
                                        </div>
                                        <div
                                            className="w-[900px] -mt-14"
                                            id="companies"
                                        >
                                            {currentFilteredCompanies.length >
                                            0 ? (
                                                currentFilteredCompanies.map(
                                                    company => (
                                                        <div
                                                            key={company._id}
                                                            className="bg-[#1f2937]  text-white rounded-lg p-6 cursor-pointer mr-0 w-full h-auto mb-2 mt-6"
                                                        >
                                                            <div className="flex items-center justify-between">
                                                                <div className="cursor-pointer flex items-center font -mt-6 -mb-2 -ml-11">
                                                                    <MdEmail
                                                                        size={
                                                                            40
                                                                        }
                                                                        className="text-yellow-500 mb-0 ml-0"
                                                                    />
                                                                    <p className="text-sm mb-0 overflow-hidden overflow-ellipsis max-w-full whitespace-nowrap ml-1">
                                                                        <span className="text-sm"></span>
                                                                        <strong className=" font-bold text-white text-sm font-montserrat px-2 py-1 rounded-full">
                                                                            {
                                                                                company.subject
                                                                            }
                                                                        </strong>
                                                                    </p>
                                                                </div>

                                                                <X
                                                                    size={24}
                                                                    className="cursor-pointer text-red-500 hover:text-red-700 mb-0 -mt-2"
                                                                    onClick={() =>
                                                                        handleDeleteCompany(
                                                                            company._id
                                                                        )
                                                                    }
                                                                />
                                                            </div>

                                                            <hr className="my-2 border-t border-gray-300 mt-" />
                                                            <div className="relative">
                                                                <div className="flex items-center">
                                                                    <div className="w-full mt-2">
                                                                        <div className="mb-3">
                                                                            {' '}
                                                                            <p className="text-sm mb-0 overflow-hidden overflow-ellipsis max-w-full whitespace-nowraptext-gray-400">
                                                                                <span className="text-sm text-gray-400">
                                                                                    De:{' '}
                                                                                </span>
                                                                                <strong className="text-sm">
                                                                                    {
                                                                                        company.from
                                                                                    }
                                                                                </strong>
                                                                            </p>
                                                                            <p className="text-sm mb-0 overflow-hidden overflow-ellipsis max-w-full whitespace-nowraptext-gray-400">
                                                                                <span className="text-sm text-gray-400">
                                                                                    Para:{' '}
                                                                                </span>
                                                                                <strong className="text-sm">
                                                                                    {
                                                                                        company.to
                                                                                    }
                                                                                </strong>
                                                                            </p>
                                                                            <div className="flex items-center space-x-2 mt-3 -mb-2">
                                                                                <div
                                                                                    className="border border-solid border-gray-400 p-2 mb-2 mt-0 rounded bg-[#1f2937] opacity-0.3"
                                                                                    style={{
                                                                                        overflowY:
                                                                                            'auto',
                                                                                        maxHeight:
                                                                                            '200px',
                                                                                    }}
                                                                                >
                                                                                    <p
                                                                                        className="text-white text-xs font-montserrat mb-2"
                                                                                        onClick={() =>
                                                                                            handleCopyClick(
                                                                                                `Atividade 1: ${
                                                                                                    company.body ||
                                                                                                    'N/A'
                                                                                                }`
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {company.body ||
                                                                                            'N/A'}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex items-center space-x-2 ">
                                                                            <div className="flex items-center space-x-2">
                                                                                <span className="font-montserrat font-bold text-white text-sm">
                                                                                    Análise:{' '}
                                                                                </span>
                                                                                <div
                                                                                    className="border border-solid border-blue-400 p-2 mb-2 mt-0 rounded bg-[#1f2937] opacity-0.3"
                                                                                    style={{
                                                                                        overflowY:
                                                                                            'auto',
                                                                                        maxHeight:
                                                                                            '200px',
                                                                                    }}
                                                                                >
                                                                                    <p
                                                                                        className="text-white text-xs font-montserrat mb-2"
                                                                                        onClick={() =>
                                                                                            handleCopyClick(
                                                                                                `Atividade 1: ${
                                                                                                    company.gpt3GeneratedContent ||
                                                                                                    'N/A'
                                                                                                }`
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {company.gpt3GeneratedContent ||
                                                                                            'N/A'}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex mt-2">
                                                                            <div>
                                                                                {' '}
                                                                                <p className="text-sm mb-0 overflow-hidden overflow-ellipsis max-w-full whitespace-nowraptext-gray-400">
                                                                                    <span className="text-sm text-gray-400">
                                                                                        Data:{' '}
                                                                                    </span>
                                                                                    <strong className="text-sm">
                                                                                        {
                                                                                            company.createdAt
                                                                                        }
                                                                                    </strong>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                            ) : (
                                                <div className="text-white text-center mt-6">
                                                    Nenhum e-mail adicionado
                                                </div>
                                            )}
                                            {copiedText && (
                                                <div className="fixed bottom-4 right-4 bg-[#03689E] text-white font-semibold px-4 py-2 rounded">
                                                    {copiedText}
                                                </div>
                                            )}
                                            {currentFilteredCompanies.length >
                                                0 && (
                                                <div className="flex justify-center mt-8  text-gray-100 text-sm font-montserrat">
                                                    <button
                                                        onClick={handlePrevPage}
                                                        disabled={
                                                            currentPage === 1
                                                        }
                                                    >
                                                        Anterior
                                                    </button>
                                                    <span className="mx-2 text-gray-100 text-xs font-montserrat">
                                                        {currentPage}
                                                    </span>
                                                    <button
                                                        onClick={handleNextPage}
                                                        disabled={
                                                            currentPage ===
                                                            totalPages
                                                        }
                                                    >
                                                        Próxima
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showConfirmationPopup && (
                <ConfirmationPopup
                    message="Tem certeza de que deseja excluir este e-mail?"
                    onCancel={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />
            )}
        </div>
    );
};

export default Email;
