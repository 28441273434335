import React from "react";

import avatarImage from "../../../logo-neuron-icon.png"

const Avatar = () => {
  return (
    <div className="relative w-10 h-10 overflow-hidden bg-gray-100 rounded-full">
      <img
        src={avatarImage}
        alt="Avatar"
      />
    </div>
  );
};

export default Avatar;
