import React from 'react'
import { LandingNavbar } from "../components/Homepage/landing-navbar"
import Email from '../components/Email'

const EmailPage = () => {
    return (
        <main className="h-full bg-[#111827] overflow-auto">
            <div className="mx-auto max-w-screen-xl h-full w-full">
                <LandingNavbar />
                <Email />
            </div>
          </main>
    )
}

export default EmailPage