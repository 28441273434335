import { LandingNavbar } from "../components/Homepage/landing-navbar"
import LegalDocs from "../components/LegalDocs"

const LegalDocsPage = () => {
    return (
    <main className="h-full bg-[#111827] overflow-auto">
        <div className="mx-auto max-w-screen-xl h-full w-full">
            <LandingNavbar />
            <LegalDocs />
        </div>
      </main>
    )
}

export default LegalDocsPage