import { cn } from "../../lib/utils";
import { LucideIcon } from "lucide-react";
interface HeadingAssistantsProps {
    title?: string;
    icon: LucideIcon;
    iconColor?: string;
    bgColor?: string;
    justifyContent?: string;
}

export const HeadingAssistants = ({
    title,
    icon: Icon,
    iconColor,
    bgColor,
    justifyContent // Add the prop here
}: HeadingAssistantsProps) => {
    return (
            <div className={`px-4 lg:px-8 flex items-center gap-x-3 mb-2 mt-5 ${justifyContent || ''}`}>
                <div className={cn("p-2 w-fit rounded-md", bgColor)}>
                    <Icon className={cn("w-10 h-10", iconColor)} />
                </div>
                <div>
                    <h2 className="text-3xl font-bold text-[#FBFCF7] ">
                        {title}
                    </h2>
                </div>
            </div>
    );
};
