import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Copy, X, Search, Download, RefreshCcw, GitMerge } from 'lucide-react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import Joyride from 'react-joyride';

import { REACT_APP_SERVER_URL } from '../config/keys';
import { getUserFromLocalStorage } from '../services';

const API_URL = `${REACT_APP_SERVER_URL}/api/personal/data/`;

interface UserInfo {
    userId: string;
    name: string;
    availableCredits: number;
}

interface CPFInfo {
    _id: string;
    author_id: string;
    cpf: string;
    nome: string;
    nascimento: string;
    situacao: string;
    situacaoDigito: string;
    situacaoMotivo: string;
    pacoteUsado: number;
    saldo: number;
    consultaID: string;
    delay: number;
    __v: number;
}

interface DownloadData {
    creationDate: string;
    name: string;
    nascimento: string;
    year: string;
    age: number;
    statusAge: string;
    situation: string;
    situationReason: string;
}

interface CrossDataCombination {
    nome: string;
    cpf: string;
    empresas: string[];
    possuiEmpresa: string;
    cnpj1?: string | null;
    cnpj2?: string | null;
    cnpj3?: string | null;
    cnpj4?: string | null;
  }
  

const PersonalSearch: React.FC = () => {
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [projectName, setProjectName] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [companies, setCompanies] = useState<CPFInfo[]>([]);
    const [allCompanies, setAllCompanies] = useState<CPFInfo[]>([]);
    const [download, setDownload] = useState<DownloadData[]>([]);
    const [crossCombination, setCrossCombination] = useState<
        CrossDataCombination[]
    >([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [kpisData, setKpisData] = useState<any>({});
    const [tourFinished, setTourFinished] = useState(false);
    const [loading, setLoading] = useState(false);
    const [copiedText, setCopiedText] = useState('');
    const companiesPerPage = 3;

    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const [searchFilter, setSearchFilter] = useState<string>('');
    const [filteredCompanies, setFilteredCompanies] = useState<CPFInfo[]>([]);

    const [isTourOpen, setIsTourOpen] = useState(true);

    useEffect(() => {
        const filtered = companies.filter(company =>
            company.cpf.toLowerCase().includes(searchFilter.toLowerCase())
        );
        setFilteredCompanies(filtered);
    }, [companies, searchFilter]);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get<UserInfo>(
                    `${REACT_APP_SERVER_URL}/api/users/infos/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    setUserInfo(response.data);
                })
                .catch(error => {
                    console.error('Error fetching user information:', error);
                });
        }
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
      
        axios
          .get<DownloadData[]>(
            `${REACT_APP_SERVER_URL}/api/personal/download-personal-data/${JSON.parse(
              storedUserId!
            )}`
          )
          .then(response => {
            setDownload(response.data);
          })
          .catch(error => {
            console.error('Error fetching user information:', error);
          });
      }, []);
      

      useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();
        const formattedToday = `${dd}/${mm}/${yyyy}`;
        axios
            .get<CrossDataCombination[]>(
                `${REACT_APP_SERVER_URL}/api/personal/match/${JSON.parse(
                    storedUserId!
                )}?startDate=${formattedToday}`
            )
            .then(response => {
                setCrossCombination(response.data);
                console.log(response.data);
            })
            .catch(error => {
                console.error('Error fetching user information:', error);
            });
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        axios
            .get<CPFInfo[]>(
                `${REACT_APP_SERVER_URL}/api/personal/personal-data/${JSON.parse(
                    storedUserId!
                )}`
            )
            .then(response => {
                setAllCompanies(response.data);
                setCompanies(response.data);
            })
            .catch(error => {
                console.error('Error fetching user information:', error);
            });
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/personal/kpis-personal-data/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    setKpisData(response.data);
                })
                .catch(error => {
                    console.error('Error fetching KPIs data:', error);
                });
        }
    }, []);

    const indexOfLastCompany = currentPage * companiesPerPage;
    const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
    const currentFilteredCompanies = filteredCompanies.slice(
        indexOfFirstCompany,
        indexOfLastCompany
    );

    const totalPages = Math.ceil(filteredCompanies.length / companiesPerPage);

    const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const handlePrevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleSearchClick = async () => {
        const user = getUserFromLocalStorage();

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');

            const sanitizedCPFs = searchTerm
                .split(',')
                .map(cpf => cpf.replace(/\D/g, ''))
                .filter(Boolean);

            try {
                setLoading(true);
                for (const cpf of sanitizedCPFs) {
                    const formattedCPF = cpf.match(
                        /^(\d{3})\.?(\d{3})\.?(\d{3})-?(\d{2})$/
                    )
                        ? cpf.replace(
                              /^(\d{3})\.?(\d{3})\.?(\d{3})-?(\d{2})$/,
                              '$1$2$3$4'
                          )
                        : cpf;

                    const response = await axios.get(API_URL, {
                        params: {
                            cpfs: formattedCPF,
                            author_id: userId,
                        },
                    });

                    console.log(
                        `Response for CPF ${formattedCPF}:`,
                        response.data
                    );
                }
                setLoading(false);
                window.location.reload();
            } catch (error) {
                console.error('Request error:', error);
                setLoading(false);
            }
        }
    };

    const formatCPFs = (input: string) => {
        return input.replace(/[^\d]/g, '');
    };

    const handlePasteClick = () => {
        if (textareaRef.current) {
            navigator.clipboard.readText().then(pastedText => {
                const cpfArray = pastedText
                    .trim()
                    .split('\n')
                    .map(cpf => formatCPFs(cpf.trim()));
                const formattedText = cpfArray.join(',\n');
                setSearchTerm(formattedText);
            });
        }
    };

    const handleDownloadExcel = () => {
        const rows = download.map(company => {
            return {
                DATA: company.creationDate || 'N/A',
                NOME: company.name || 'N/A',
                'DATA DE NASC': company.nascimento || 'N/A',
                'ANO DE NASC': company.year || 'N/A',
                IDADE: company.age || 'N/A',
                STATUS: company.statusAge || 'N/A',
                'STATUS DE VIDA': company.situation || 'N/A',
                'OBS DO STATUS': company.situationReason || 'N/A',
            };
        });

        console.log(rows);

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(rows);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'CPF');

        XLSX.writeFile(workbook, 'CPF.xlsx', { compression: true });
    };

    const handleCrossCombination = () => {
        const rows = crossCombination.map(company => {
            return {
                'Nome Completo': company.nome || 'N/A',
                'CPF': company.cpf || 'N/A',
                'Possui Empresas?': company.possuiEmpresa || 'N/A',
                "CNPJ #01": company.cnpj1 || 'N/A',
                "CNPJ #02": company.cnpj2 || 'N/A',
                "CNPJ #03": company.cnpj3 || 'N/A',
                "CNPJ #04": company.cnpj4 || 'N/A',
            };
        });

        console.log(rows);

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(rows);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Combinação Cruzada');

        XLSX.writeFile(workbook, 'Combinação Cruzada.xlsx', {
            compression: true,
        });
    };

    const handleDeleteCompany = async (companyId: string) => {
        setCompanies(prevCompanies =>
            prevCompanies.filter(company => company._id !== companyId)
        );

        try {
            await axios.delete(
                `${REACT_APP_SERVER_URL}/api/personal/delete-personal-data`,
                {
                    params: {
                        _id: companyId,
                    },
                }
            );

            console.log('Personal Data deleted successfully');
            window.location.reload();
        } catch (error) {
            console.error('Error deleting company:', error);
        }
    };

    const getSituationColor = (situation: string) => {
        switch (situation) {
            case 'Regular':
                return 'bg-green-500 text-black';
            case 'Pendente':
                return 'bg-yellow-500 text-black';
            case 'Suspensa':
                return 'bg-orange-500 text-black';
            case 'Cancelada':
                return 'bg-red-500 text-white';
            case 'Nula':
                return 'bg-gray-500 text-white';
            default:
                return 'bg-gray-500 text-white';
        }
    };

    const handleCopyClick = (text: string) => {
        navigator.clipboard.writeText(text);
        setCopiedText(`Texto copiado: ${text}`);

        // Limpar a mensagem após alguns segundos (opcional)
        setTimeout(() => {
            setCopiedText('');
        }, 3000);
    };

    return (
        <div className="min-h-screen bg-[#111827] py-10">
            <div>
                <Link
                    to="/"
                    className="text-white hover:text-[#39A2A7]/80 font-semibold text-sm font-montserrat"
                >
                    &#8592; Voltar
                </Link>
            </div>
            <div className="flex mt-8 space-x-4">
                <div className="bg-[#1f2937] rounded-lg p-6  w-1/3 h-full">
                <div className="mb-2">
                        <p className="text-2xl text-white font-semibold cursor-default font-montserrat rounded">
                            Assistente de IA para <br />
                            <span className="bg-[#02989E] px-2 py-1 animate-pulse font-montserrat rounded text-black text-2xl">
                                CPF
                            </span>
                        </p>
                    </div>
                    <p className="mb-4 text-sm font-montserrat text-white">
                        Monitore dados de pessoas físicas.
                    </p>
                    <div className="flex items-center mb-2">
                        <textarea
                            ref={textareaRef}
                            id="searchTerm"
                            name="searchTerm"
                            value={searchTerm}
                            onChange={e =>
                                setSearchTerm(formatCPFs(e.target.value))
                            }
                            className="flex-1 w-72 h-96 p-2 border bg-[#1f2937] rounded focus:outline-none font-montserrat text-white"
                            placeholder="Cole os CPFs aqui"
                        ></textarea>
                        <button
                            onClick={handlePasteClick}
                            className="ml-2 bg-gray-200 text-black px-2 py-2 rounded hover:bg-gray-300 focus:outline-none focus:ring focus:border-blue-300 font-montserrat flex items-center"
                        >
                            <Copy size={10} className="font-montserrat" />
                        </button>
                    </div>
                    <button
                        onClick={() => handleSearchClick()}
                        id="search-button"
                        className={`mt-3 bg-[#111827] text-white px-6 py-2 rounded font-medium hover:bg-[#111827]/50 focus:outline-none focus:ring focus:border-blue-300 font-montserrat ${
                            loading ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                        disabled={loading}
                    >
                        {loading ? 'Aguarde...' : <Search size={24} />}
                    </button>
                    <button
                        type="button"
                        id="download"
                        className="mt-2 ml-3 bg-[#111827] text-white px-6 py-2 rounded font-medium hover:bg-[#111827]/50 focus:outline-none focus:ring focus:border-blue-300 font-montserrat
            "
                        onClick={handleDownloadExcel}
                    >
                        <Download />
                    </button>
                    <button
                        type="button"
                        id="cross"
                        className="mt-2 ml-3 bg-[#111827] text-white px-6 py-2 rounded font-medium hover:bg-[#111827]/50 focus:outline-none focus:ring focus:border-blue-300 font-montserrat"
                        onClick={handleCrossCombination}
                    >
                        <GitMerge />
                    </button>
                    <button
                        type="button"
                        id="updated"
                        className="mt-2 ml-3 bg-[#111827] text-white px-6 py-2 rounded font-medium hover:bg-[#111827]/50 focus:outline-none focus:ring focus:border-blue-300 font-montserrat
            "
                        disabled
                    >
                        <RefreshCcw />
                    </button>
                </div>
                <div></div>
                <div>
                    <div
                        className="flex justify-center -mt-14 text-sm font-montserrat ml-8"
                        id="kpis"
                    >
                        <div>
                            <div className="mx-0 md:max-w-8xl lg:max-w-8xl xl:max-w-12xl ml-38">
                                <div className="grid grid-cols-1 md:grid-cols-6 gap-4">
                                    <div className="col-span-1 bg-[#1f2937] text-white p-4 rounded-lg shadow-md border border-[#2c3848]">
                                        <p className="text-lg text-muted-foreground font-montserrat">
                                            CPFs
                                        </p>
                                        <p className="text-xl font-semibold">
                                            {kpisData.count_cpf || '-'}
                                        </p>
                                    </div>
                                    <div className="col-span-1 bg-[#1f2937] text-white p-4 rounded-lg shadow-md border border-[#2c3848]">
                                        <p className="text-lg font-montserrat">
                                            Regular
                                        </p>
                                        <p className="text-xl font-semibold">
                                            {kpisData.count_regular || '-'}
                                        </p>
                                    </div>
                                    <div className="col-span-1 bg-[#1f2937] text-white p-4 rounded-lg shadow-md border border-[#2c3848]">
                                        <p className="text-lg font-montserrat">
                                            Pendente
                                        </p>
                                        <p className="text-xl font-semibold">
                                            {kpisData.count_pendente || '-'}
                                        </p>
                                    </div>
                                    <div className="col-span-1 bg-[#1f2937] text-white p-4 rounded-lg shadow-md border border-[#2c3848]">
                                        <p className="text-lg font-montserrat">
                                            Suspenso
                                        </p>
                                        <p className="text-xl font-semibold">
                                            {kpisData.count_suspensa || '-'}
                                        </p>
                                    </div>
                                    <div className="col-span-1 bg-[#1f2937] text-white p-4 rounded-lg shadow-md border border-[#2c3848]">
                                        <p className="text-lg font-montserrat">
                                            Cancelado
                                        </p>
                                        <p className="text-xl font-semibold">
                                            {kpisData.count_cancelada || '-'}
                                        </p>
                                    </div>
                                    <div className="col-span-1 bg-[#1f2937] text-white p-4 rounded-lg shadow-md border border-[#2c3848]">
                                        <p className="text-lg font-montserrat">
                                            Nulo
                                        </p>
                                        <p className="text-xl font-semibold">
                                            {kpisData.count_nula || '-'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 mb-0">
                        <input
                            type="text"
                            id="search"
                            value={searchFilter}
                            onChange={e => setSearchFilter(e.target.value)}
                            placeholder="Pesquisar CPF..."
                            className="w-full ml-5 h-8 px-4 pr-10 text-sm placeholder-gray-400 bg-[#1f2937] border rounded focus:outline-none focus:border-[#39A2A7] text-white"
                        />
                    </div>
                    <div
                        className="container mx-auto p-4 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-12"
                        id="companies"
                    >
                        {currentFilteredCompanies.map(company => (
                            <div
                                key={company._id}
                                className="bg-[#1f2937] text-white rounded-lg p-6 cursor-pointer mr-0 transition-transform hover:scale-105 w-96 md:w-72 h-auto -mt-0"
                            >
                                <X
                                    size={24}
                                    className="cursor-pointer text-red-500 hover:text-red-700 mb-2 -mt-2"
                                    onClick={() =>
                                        handleDeleteCompany(company._id)
                                    }
                                />
                                <div className="relative">
                                    <div className="flex items-center">
                                        <div className="w-full">
                                            <p
                                                className="text-lg font-medium font-montserrat mb-2 overflow-hidden overflow-ellipsis max-w-full whitespace-nowrap"
                                                onClick={() =>
                                                    handleCopyClick(
                                                        company.nome || 'N/A'
                                                    )
                                                }
                                            >
                                                {company.nome || 'N/A'}
                                            </p>
                                            <p
                                                className="text-gray-400 text-base font-montserrat"
                                                onClick={() =>
                                                    handleCopyClick(
                                                        company.cpf || 'N/A'
                                                    )
                                                }
                                            >
                                                CPF: {company.cpf || 'N/A'}
                                            </p>
                                            <p
                                                className="text-gray-400 text-base font-montserrat mb-2"
                                                onClick={() =>
                                                    handleCopyClick(
                                                        company.nascimento ||
                                                            'N/A'
                                                    )
                                                }
                                            >
                                                Nascimento:{' '}
                                                {company.nascimento || 'N/A'}
                                            </p>
                                            <p
                                                className="text-gray-400 text-base font-montserrat mb-3"
                                                onClick={() =>
                                                    handleCopyClick(
                                                        company.situacao ||
                                                            'N/A'
                                                    )
                                                }
                                            >
                                                <span className="text-gray-200 text-base font-montserrat">
                                                    Situação:
                                                </span>
                                                <span
                                                    className={`inline-block px-1 py-0.5 rounded ml-1 ${getSituationColor(
                                                        company.situacao
                                                    )}`}
                                                >
                                                    {company.situacao || 'N/A'}
                                                </span>
                                            </p>
                                            <p
                                                className="text-black text-base font-montserrat bg-white p-1 rounded"
                                                onClick={() =>
                                                    handleCopyClick(
                                                        `Motivo: ${
                                                            company.situacaoMotivo ||
                                                            'N/A'
                                                        }`
                                                    )
                                                }
                                            >
                                                Motivo:{' '}
                                                <span className="font-bold text-base font-montserrat">
                                                    {company.situacaoMotivo ||
                                                        'N/A'}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {copiedText && (
                            <div className="fixed bottom-4 right-4 bg-[#03689E] text-white font-semibold px-4 py-2 rounded">
                                {copiedText}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex justify-center mt-4 ml-62 text-gray-100 text-sm font-montserrat">
                <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    Anterior
                </button>
                <span className="mx-2 text-gray-100 text-xs font-montserrat">
                    {currentPage}
                </span>
                <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    Próxima
                </button>
            </div>
            {!tourFinished && (
                <Joyride
                    steps={[
                        {
                            target: '#searchTerm',
                            content: (
                                <div>
                                    <p>Bem-vindo ao Monitor de CPF com IA!</p>
                                    <br />
                                    <p>
                                        Na primeira etapa, cole um ou vários
                                        números de CPF no campo de texto abaixo.
                                    </p>
                                </div>
                            ),
                            placement: 'bottom',
                        },
                        {
                            target: '#search-button', // Substitua '.search-button' pela classe ou id do seu botão de pesquisa
                            content: (
                                <div>
                                    <p>
                                        Agora, clique no botão de pesquisa para
                                        iniciar a busca.
                                    </p>
                                </div>
                            ),
                        },
                        {
                            target: '#companies',
                            content: (
                                <div>
                                    <p>Visualize os resultados na dashboard.</p>
                                </div>
                            ),
                            placement: 'bottom',
                        },

                        {
                            target: '#download',
                            content: (
                                <div>
                                    <p>
                                        Para obter os dados, clique no botão de
                                        download.
                                    </p>
                                    <br />
                                    <p>
                                        Isso permitirá que você baixe as
                                        informações em um arquivo Excel.
                                    </p>
                                </div>
                            ),
                        },
                        {
                            target: '#cross',
                            content: (
                                <div>
                                    <p>
                                        Explore conexões com empresas através da
                                        Informação Cruzada de CPF.
                                    </p>
                                    <br />
                                    <p>
                                        Ao clicar neste botão, você poderá
                                        verificar se o CPF está vinculado a
                                        empresas registradas na base de CNPJs.
                                    </p>
                                </div>
                            ),
                        },
                        {
                            target: '#updated',
                            content: (
                                <div>
                                    <p>
                                        Analise CPFs que passaram por
                                        atualizações cadastrais.
                                    </p>
                                    <br />
                                    <p>
                                        Ao clicar neste botão, você poderá
                                        verificar os CPFs que foram recentemente
                                        atualizados, apresentando mudanças em
                                        suas informações cadastrais.
                                    </p>
                                </div>
                            ),
                        },
                        {
                            target: '#search',
                            content: (
                                <div>
                                    <p>
                                        Realize pesquisas nos CPFs salvos na sua
                                        base de dados.
                                    </p>
                                </div>
                            ),
                        },
                        {
                            target: '#kpis',
                            content: (
                                <div>
                                    <p>
                                        Indicadores com informações importantes
                                        dos CPFs salvos na sua base de dados.
                                    </p>
                                </div>
                            ),
                        },
                    ]}
                    run={!tourFinished}
                    continuous
                    showSkipButton
                    callback={data => {
                        if (
                            data.status === 'finished' ||
                            data.status === 'skipped'
                        ) {
                            setTourFinished(true);
                        }
                    }}
                    styles={{
                        options: {
                            overlayColor: 'rgba(17, 24, 39, 0.9)',
                            primaryColor: '#39A2A7',
                            textColor: '#000000',
                            width: 400,
                            zIndex: 1000,
                        },
                        tooltip: {
                            fontFamily: 'Montserrat',
                        },
                        buttonNext: {
                            backgroundColor: '#39A2A7',
                            color: '#FFFFFF',
                            border: 'none',
                            borderRadius: '4px',
                            marginLeft: '0',
                        },
                    }}
                    locale={{
                        next: 'Próximo',
                        last: 'Último',
                        skip: 'Pular',
                        back: 'Anterior',
                    }}
                />
            )}
        </div>
    );
};

export default PersonalSearch;
