import type Register from '../../models/Register';
import Login from '../../models/Login';
import { REACT_APP_SERVER_URL } from '../../config/keys';
import axios from "axios";

export const loginUser = async ({ email, password }: Login) => {
    console.log(REACT_APP_SERVER_URL)
    return await axios.post(`${REACT_APP_SERVER_URL}/api/users/login`, { email, password });
};

export const createNewUser = async (signUpParams: Register) => {
    const { name, email, password, password2, cnpj, sector, position } = signUpParams;
    return await axios.post(`${REACT_APP_SERVER_URL}/api/users/register`, {
        name,
        email,
        password,
        password2, 
        cnpj,
        sector,
        position
    });
};
