import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { X } from 'lucide-react';
import { FaCheckCircle } from 'react-icons/fa';
import { RiRobot2Line } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import Switch from 'react-switch';
import axios from 'axios';
import coin from '../../src/coin.png';

import { REACT_APP_SERVER_URL } from '../config/keys';
import { getUserFromLocalStorage } from '../services';
import ConfirmationPopup from './Global/ConfirmationPopup';

interface UserInfo {
    userId: string;
    name: string;
    availableCredits: number;
}

interface JurisInfo {
    _id: string;
    author_id: string;
    query: string;
    tribunal: string;
    gpt3GeneratedContent: string;
    uiGContentsLength: string;
    createdAt: string;
    __v: number;
}

interface DownloadData {
    taxId: string;
    name: string;
}

const Legal: React.FC = () => {
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [inputValue, setInputValue] = useState('');

    const [companies, setCompanies] = useState<JurisInfo[]>([]);
    const [allCompanies, setAllCompanies] = useState<JurisInfo[]>([]);
    const [download, setDownload] = useState<DownloadData[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [kpisData, setKpisData] = useState<any>({});
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [copiedText, setCopiedText] = useState('');
    const [companyIdToDelete, setCompanyIdToDelete] = useState<string | null>(
        null
    );
    const companiesPerPage = 3;
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [filteredCompanies, setFilteredCompanies] = useState<JurisInfo[]>([]);

    const indexOfLastCompany = currentPage * companiesPerPage;
    const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
    const currentFilteredCompanies = filteredCompanies.slice(
        indexOfFirstCompany,
        indexOfLastCompany
    );

    const totalPages = Math.ceil(filteredCompanies.length / companiesPerPage);

    const [switch1, setSwitch1] = useState(false);
    const [switch2, setSwitch2] = useState(false);
    const [switch3, setSwitch3] = useState(false);
    const [switch4, setSwitch4] = useState(false);
    const [switch5, setSwitch5] = useState(false);
    const [switch6, setSwitch6] = useState(false);
    const [switch7, setSwitch7] = useState(false);
    const [switch8, setSwitch8] = useState(false);

    const [analysisCompleted, setAnalysisCompleted] = useState(false);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [totalNumberOfPages, setTotalNumberOfPages] = useState(0);
    const [updatedUserCredits, setUpdatedUserCredits] = useState(0);
    const [query, setQuery] = useState(0);
    const [executionTime, setExecutionTime] = useState(0);

    useEffect(() => {
        const filtered = companies.filter(
            company =>
                company.query
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase()) ||
                company.gpt3GeneratedContent
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase())
        );
        setFilteredCompanies(filtered);
    }, [companies, searchFilter]);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get<UserInfo>(
                    `${REACT_APP_SERVER_URL}/api/users/infos/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    setUserInfo(response.data);
                })
                .catch(error => {
                    console.error('Error fetching user information:', error);
                });
        }
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        axios
            .get<JurisInfo[]>(
                `${REACT_APP_SERVER_URL}/api/unique/stf-data/${JSON.parse(
                    storedUserId!
                )}`
            )
            .then(response => {
                console.log('Company Data:', response.data);
                setAllCompanies(response.data);
                setCompanies(response.data);
            })
            .catch(error => {
                console.error('Error fetching user information:', error);
            });
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/datastore/kpis-company-data/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    console.log('KPIs Data:', response.data);
                    setKpisData(response.data);
                })
                .catch(error => {
                    console.error('Error fetching KPIs data:', error);
                });
        }
    }, []);

    const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const handlePrevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleDeleteCompany = (companyId: string) => {
        setCompanyIdToDelete(companyId);
        setShowConfirmationPopup(true);
    };

    const handleCancelDelete = () => {
        setCompanyIdToDelete(null);
        setShowConfirmationPopup(false);
    };

    const handleConfirmDelete = async () => {
        try {
            console.log('Deleting company with ID:', companyIdToDelete);
            await axios.delete(
                `${REACT_APP_SERVER_URL}/api/unique/delete-juris-data`,
                {
                    params: {
                        _id: companyIdToDelete,
                    },
                }
            );

            console.log('Company deleted successfully');
        } catch (error) {
            console.error('Error deleting company:', error);
        } finally {
            setCompanyIdToDelete(null);
            setShowConfirmationPopup(false);
            window.location.reload();
        }
    };

    const handleCopyClick = (text: string) => {
        navigator.clipboard.writeText(text);
        setCopiedText(`Texto copiado: ${text}`);

        // Limpar a mensagem após alguns segundos (opcional)
        setTimeout(() => {
            setCopiedText('');
        }, 3000);
    };

    const handleSwitchChange = (switchNumber: number) => {
        setSwitch1(false);
        setSwitch2(false);
        setSwitch3(false);
        setSwitch4(false);
        setSwitch5(false);
        setSwitch6(false);
        setSwitch7(false);
        setSwitch8(false);

        switch (switchNumber) {
            case 1:
                setSwitch1(true);
                break;
            case 2:
                setSwitch2(true);
                break;
            case 3:
                setSwitch3(true);
                break;
            case 4:
                setSwitch4(true);
                break;
            case 5:
                setSwitch5(true);
                break;
            case 6:
                setSwitch6(true);
                break;
            case 7:
                setSwitch7(true);
                break;
            case 8:
                setSwitch8(true);
                break;
            default:
                break;
        }
    };

    const handleSearch = async () => {
        const start = new Date().getTime();

        const user = getUserFromLocalStorage();

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');

            try {
                setLoading(true);

                let apiUrl = '';

                if (switch1) {
                    apiUrl = `${REACT_APP_SERVER_URL}/api/carf/search/`;
                } else if (switch2) {
                    apiUrl = `${REACT_APP_SERVER_URL}/api/unique/search/`;
                } else if (switch3) {
                    apiUrl = `${REACT_APP_SERVER_URL}/api/unique/search-stj/`;
                } else if (switch4) {
                    apiUrl = `${REACT_APP_SERVER_URL}/api/unique/search-trf1/`;
                } else if (switch5) {
                    apiUrl = `${REACT_APP_SERVER_URL}/api/unique/search-trf2/`;
                } else if (switch6) {
                    apiUrl = `${REACT_APP_SERVER_URL}/api/unique/search-trf3/`;
                } else if (switch7) {
                    apiUrl = `${REACT_APP_SERVER_URL}/api/unique/search-trf4/`;
                } else if (switch8) {
                    apiUrl = `${REACT_APP_SERVER_URL}/api/unique/search-trf5/`;
                }

                const response = await axios.get(apiUrl, {
                    params: {
                        query: inputValue,
                        author_id: userId,
                    },
                });

                const {
                    uiGContentsLength,
                    availableCredits,
                    updatedUserCredits,
                    query,
                } = response.data;

                setQuery(query);
                setNumberOfPages(uiGContentsLength);
                setTotalNumberOfPages(availableCredits);
                setUpdatedUserCredits(updatedUserCredits);

                console.log('Response:', response.data);
            } catch (error) {
                console.error('Request error:', error);
            } finally {
                setAnalysisCompleted(true);

                const end = new Date().getTime();
                const elapsedTime = end - start;
                setExecutionTime(elapsedTime);
            }
        }
    };

    const handlePopupOkClick = () => {
        setAnalysisCompleted(false);
        window.location.reload();
    };

    const formatTime = (milliseconds: number) => {
        const minutes = Math.floor(milliseconds / 60000);
        const seconds = ((milliseconds % 60000) / 1000).toFixed(0);

        if (minutes === 0) {
            return `${seconds} segundos`;
        } else {
            return `${minutes} minutos ${seconds} segundos`;
        }
    };

    const navigate = useNavigate();

    const handleDocumentClick = (companyId: string) => {
        navigate(`/legal-docs/${companyId}`);
    };

    return (
        <div className="min-h-screen bg-[#111827] py-10">
            <div>
                <Link
                    to="/"
                    className="text-white hover:text-[#39A2A7]/80 font-semibold text-sm font-montserrat"
                >
                    &#8592; Voltar
                </Link>
            </div>
            <div className="flex mt-8 space-x-4 -ml-4">
                <div className="bg-[#1f2937] rounded-lg p-6 w-[400px] h-full">
                    <p className="text-2xl text-white font-semibold cursor-default font-montserrat rounded">
                        Assistente de IA para <br />
                        <span className="bg-[#02989E] px-2 py-1 animate-pulse font-montserrat rounded text-black text-2xl">
                            Jurisprudência
                        </span>
                    </p>
                    <p className="mb-4 text-sm font-montserrat text-white mt-2">
                        Busque e monitore jurisprudência de forma descomplicada.
                    </p>
                    <div className="flex flex-col items-center mb-2">
                        <input
                            type="text"
                            placeholder="Digite o assunto"
                            className="border rounded-lg p-3 w-full font-montserrat bg-[#1f2937] focus:outline-none text-white mr-2"
                            value={inputValue}
                            onChange={e => setInputValue(e.target.value)}
                        />
                    </div>
                    <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        <div className="flex items-center space-x-2">
                            <span className="text-white font-montserrat ml-0 -mr-1">
                                CARF
                            </span>
                            <Switch
                                onChange={() => handleSwitchChange(1)}
                                checked={switch1}
                                onColor="#39A2A7"
                                className="ml-2"
                            />
                        </div>

                        <div className="flex items-center space-x-2 ml-2">
                            <span className="text-white font-montserrat -mr-1 -ml-2">
                                STF
                            </span>
                            <Switch
                                onChange={() => handleSwitchChange(2)}
                                checked={switch2}
                                onColor="#39A2A7"
                                className=""
                            />
                        </div>

                        <div className="flex items-center space-x-2">
                            <span className="text-white font-montserrat -mr-1 -ml-2">
                                STJ
                            </span>
                            <Switch
                                onChange={() => handleSwitchChange(3)}
                                checked={switch3}
                                onColor="#39A2A7"
                                className="ml-2"
                            />
                        </div>

                        <div className="flex items-center space-x-2">
                            <span className="text-white font-montserrat -mr-1 ml-1">
                                TRF1
                            </span>
                            <Switch
                                onChange={() => handleSwitchChange(4)}
                                checked={switch4}
                                onColor="#39A2A7"
                                className="ml-2"
                            />
                        </div>

                        <div className="flex items-center space-x-2 ml-2">
                            <span className="text-white font-montserrat  -ml-2 -mr-1">
                                TRF2
                            </span>
                            <Switch
                                onChange={() => handleSwitchChange(5)}
                                checked={switch5}
                                onColor="#39A2A7"
                                className=""
                            />
                        </div>

                        <div className="flex items-center space-x-2">
                            <span className="text-white font-montserrat -mr-1">
                                TRF3
                            </span>
                            <Switch
                                onChange={() => handleSwitchChange(6)}
                                checked={switch6}
                                onColor="#39A2A7"
                                className=""
                            />
                        </div>
                        <div className="flex items-center space-x-2">
                            <span className="text-white font-montserrat -mr-1">
                                TRF4
                            </span>
                            <Switch
                                onChange={() => handleSwitchChange(7)}
                                checked={switch7}
                                onColor="#39A2A7"
                                className=""
                            />
                        </div>
                        <div className="flex items-center space-x-2">
                            <span className="text-white font-montserrat  -mr-1">
                                TRF5
                            </span>
                            <Switch
                                onChange={() => handleSwitchChange(8)}
                                checked={switch8}
                                onColor="#39A2A7"
                                className=""
                            />
                        </div>
                    </div>

                    <div className="mt-4">
                        <button
                            id="search-button"
                            onClick={handleSearch}
                            className={`mt-3 bg-[#111827] text-white px-6 py-2 rounded font-medium hover:bg-[#111827]/50 focus:outline-none focus:ring focus:border-blue-300 font-montserrat`}
                            disabled={loading} // Desabilita o botão enquanto loading for true
                        >
                            {loading ? (
                                'Aguarde...'
                            ) : (
                                <RiRobot2Line size={24} />
                            )}
                        </button>
                    </div>
                </div>
                <div>
                    <div
                        className="flex justify-center -mt-14 text-sm font-montserrat ml-5"
                        id="kpis"
                    >
                        <div>
                            <div className="mx-0 md:max-w-8xl lg:max-w-8xl xl:max-w-12xl">
                                <div>
                                    <div className="container mx-auto p-4 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-12">
                                        <div>
                                            <div className="mt-4 mb-0">
                                                <input
                                                    type="text"
                                                    id="search"
                                                    value={searchFilter}
                                                    onChange={e =>
                                                        setSearchFilter(
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder="Pesquisar Assunto..."
                                                    className="w-[900px] ml-0 h-8 px-4 pr-10 text-sm placeholder-gray-400 bg-[#1f2937] border rounded focus:outline-none focus:border-[#39A2A7] text-white"
                                                />
                                            </div>

                                            <div
                                                className="w-[900px]"
                                                id="companies"
                                            >
                                                {currentFilteredCompanies.length >
                                                0 ? (
                                                    currentFilteredCompanies.map(
                                                        company => (
                                                            <div
                                                                key={
                                                                    company._id
                                                                }
                                                                className="bg-[#1f2937]  text-white rounded-lg p-6 cursor-pointer mr-0 w-full h-auto mb-2 mt-6"
                                                            >
                                                                <div className="flex items-center justify-between">
                                                                    <div className="flex items-center -mt-2">
                                                                        <div className="bg-yellow-500 font-semibold text-black text-base font-montserrat px-2 py-1 rounded-full">
                                                                            {
                                                                                company.tribunal
                                                                            }
                                                                        </div>
                                                                        <div className="cursor-pointer flex items-center font mr-32 ml-5">
                                                                            <p className="text-sm mb-0 overflow-hidden overflow-ellipsis max-w-full whitespace-nowrap">
                                                                                <span className="text-base">
                                                                                    Assunto:{' '}
                                                                                </span>
                                                                                <strong className="text-lg">
                                                                                    {
                                                                                        company.query
                                                                                    }
                                                                                </strong>
                                                                            </p>
                                                                        </div>
                                                                        <div className=" py-2 px-0 rounded-md text-white flex items-center justify-center font-montserrat -ml-10"></div>
                                                                    </div>

                                                                    <X
                                                                        size={
                                                                            24
                                                                        }
                                                                        className="cursor-pointer text-red-500 hover:text-red-700 mb-0 -mt-2"
                                                                        onClick={() =>
                                                                            handleDeleteCompany(
                                                                                company._id
                                                                            )
                                                                        }
                                                                    />
                                                                </div>

                                                                <hr className="my-2 border-t border-gray-300 mt-" />
                                                                <div className="relative">
                                                                    <div className="flex items-center">
                                                                        <div className="w-full mt-2">
                                                                            <div className="flex items-center space-x-2 ">
                                                                                <span className=" font-montserrat font-bold text-white text-base">
                                                                                    Parecer:{' '}
                                                                                </span>
                                                                                <div className="border border-solid border-blue-400 p-2 mb-2 mt-0 rounded">
                                                                                    <p
                                                                                        className="text-gray-400 text-xs font-montserrat mb-2"
                                                                                        onClick={() =>
                                                                                            handleCopyClick(
                                                                                                `Atividade 1: ${
                                                                                                    company.gpt3GeneratedContent ||
                                                                                                    'N/A'
                                                                                                }`
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {company.gpt3GeneratedContent ||
                                                                                            'N/A'}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex mt-2 -mb-2">
                                                                    <div className="mb-0">
                                                                        {' '}
                                                                        <p className="text-sm mb-0 overflow-hidden overflow-ellipsis max-w-full whitespace-nowraptext-gray-400 mr-28">
                                                                            <span
                                                                                className="text-sm text-gray-400 cursor-pointer"
                                                                                onClick={() =>
                                                                                    handleDocumentClick(
                                                                                        company._id
                                                                                    )
                                                                                }
                                                                            >
                                                                                Documentos
                                                                                Analisados:{' '}
                                                                            </span>
                                                                            <strong
                                                                                className="text-lg cursor-pointer"
                                                                                onClick={() =>
                                                                                    handleDocumentClick(
                                                                                        company._id
                                                                                    )
                                                                                }
                                                                            >
                                                                                {
                                                                                    company.uiGContentsLength
                                                                                }
                                                                            </strong>
                                                                        </p>
                                                                    </div>
                                                                    <div>
                                                                        {' '}
                                                                        <p className="text-sm mb-0 overflow-hidden overflow-ellipsis max-w-full whitespace-nowraptext-gray-400 ml-4">
                                                                            <span className="text-sm text-gray-400">
                                                                                Pesquisa:{' '}
                                                                            </span>
                                                                            <strong className="text-lg">
                                                                                {
                                                                                    company.createdAt
                                                                                }
                                                                            </strong>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    )
                                                ) : (
                                                    <div className="text-white text-center mt-6">
                                                        Nenhuma Jurisprudência
                                                        adicionada
                                                    </div>
                                                )}
                                                {copiedText && (
                                                    <div className="fixed bottom-4 right-4 bg-[#03689E] text-white font-semibold px-4 py-2 rounded">
                                                        {copiedText}
                                                    </div>
                                                )}
                                                {currentFilteredCompanies.length >
                                                    0 && (
                                                    <div className="flex justify-center mt-8  text-gray-100 text-sm font-montserrat">
                                                        <button
                                                            onClick={
                                                                handlePrevPage
                                                            }
                                                            disabled={
                                                                currentPage ===
                                                                1
                                                            }
                                                        >
                                                            Anterior
                                                        </button>
                                                        <span className="mx-2 text-gray-100 text-xs font-montserrat">
                                                            {currentPage}
                                                        </span>
                                                        <button
                                                            onClick={
                                                                handleNextPage
                                                            }
                                                            disabled={
                                                                currentPage ===
                                                                totalPages
                                                            }
                                                        >
                                                            Próxima
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <>
                {analysisCompleted && (
                    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-75">
                        <div className="bg-white rounded-lg shadow-md overflow-hidden w-96">
                            <div className="px-6 py-4 bg-[#02989E] text-white flex justify-between items-center">
                                <div className="flex items-center">
                                    <FaCheckCircle size={24} className="mr-2" />
                                    <h2 className="text-2xl font-semibold font-montserrat">
                                        Tarefa Concluída
                                    </h2>
                                </div>
                            </div>
                            <div className="px-6 py-4">
                                <p className="text-gray-400 text-2xl font-semibold mb-2 font-montserrat">
                                    Relatório
                                </p>
                                <div className="flex items-center mb-0">
                                    <p className="text-gray-700 mr-2 font-montserrat text-lg">
                                        Tema:
                                    </p>
                                    <p className="text-gray-800 font-semibold font-montserrat text-lg">
                                        {query}
                                    </p>
                                </div>
                                <div className="flex items-center mb-0">
                                    <p className="text-gray-700 mr-2 font-montserrat text-lg">
                                        Documentos Analisados:
                                    </p>
                                    <p className="text-gray-800 font-semibold font-montserrat text-lg">
                                        {numberOfPages}
                                    </p>
                                </div>
                                <div className="flex items-center mb-0">
                                    <p className="text-gray-700 mr-2 text-lg font-montserrat">
                                        Créditos Utilizados:
                                    </p>
                                    <p className="text-gray-800 font-semibold font-montserrat text-lg">
                                        {totalNumberOfPages}
                                    </p>
                                </div>
                                <div className="flex items-center mb-4">
                                    <p className="text-gray-700 mr-2 text-lg font-montserrat">
                                        Duração:
                                    </p>
                                    <p className="text-gray-800 font-semibold font-montserrat text-lg">
                                        {formatTime(executionTime)}
                                    </p>
                                </div>

                                <div className="flex items-center mb-6">
                                    <img
                                        src={coin}
                                        alt="Moeda"
                                        className="w-10 h-10 mr-2"
                                    />
                                    <p className="text-gray-700 mr-2 font-montserrat text-xl">
                                        Créditos Disponíveis:
                                    </p>
                                    <p className="text-gray-800 font-semibold font-montserrat text-lg">
                                        {updatedUserCredits}
                                    </p>
                                </div>
                                <div className="flex justify-end">
                                    <button
                                        className="text-white bg-[#02989E] px-6 py-2 rounded-md hover:bg-[#02807a] font-semibold focus:outline-none font-montserrat"
                                        onClick={handlePopupOkClick}
                                    >
                                        Ok
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
            {showConfirmationPopup && (
                <ConfirmationPopup
                    message="Tem certeza de que deseja excluir esta jurisprudência?"
                    onCancel={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />
            )}
        </div>
    );
};

export default Legal;
