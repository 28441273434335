import React, { useEffect, useState } from 'react';
import { REACT_APP_SERVER_URL } from '../../config/keys';
import {
    Dialog,
    AppBar,
    Slide,
    Toolbar,
    IconButton,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import TableCell from './TableCell';
import Settings from './Settings';
import { TableState } from './localTypes';
import InputDialogPopup from '../Global/InputDialogPopup';
import { useParams, Link } from 'react-router-dom';
import { getUserFromLocalStorage } from '../../services';

import * as XLSX from 'xlsx';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface TagToken {
    tag: string;
    token: string;
}

type CellAddress = {
    row: number;
    col: number;
};

type Props = {
    processedData: any;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    tagTokens: TagToken[];
    setTagTokens: React.Dispatch<React.SetStateAction<TagToken[]>>;
    update?: boolean;
};

const TagPopup: React.FC<Props> = ({
    open,
    setOpen,
    processedData,
    tagTokens,
    setTagTokens,
    update,
}) => {
    const { id } = useParams();
    const [selectedCells, setSelectedCells] = useState<CellAddress[]>([]);
    const [firstRowHeader, setFirstRowHeader] = useState<boolean>(true);
    const [tableState, setTableState] = useState<TableState>(
        TableState.PRE_SETTINGS
    );
    const [settingsOptions, setSettingsOptions] = useState<string>('');
    const [bulkOptions, setBulkOptions] = useState<string>('');

    const [selectedTextToken, setSelectedTextToken] = useState<string>('');
    const [selectedColumn, setSelectedColumn] = useState<string>('');

    const [savedTagTokens, setSavedTagTokens] = useState<TagToken[]>([]);

    const isInputPopupOpen =
        tableState === TableState.WAITING_TYPE_TAG ||
        (tableState === TableState.WAITING_BULK_TAG && selectedColumn !== '');

    const [bulkPopupOpen, setBulkPopupOpen] = useState<boolean>(false);
    const [isCreateProjectPopupOpen, setIsCreateProjectPopupOpen] =
        useState<boolean>(false);

    const clearSelection = () => {
        setSelectedTextToken('');
        setSelectedColumn('');
        setSelectedCells([]);
    };

    const openBulkPopup = () => {
        setBulkPopupOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddTagToken = (tag: string) => {
        if (settingsOptions === 'type') {
            const newTagToken: TagToken = {
                tag: tag,
                token: selectedTextToken,
            };
            setTagTokens((prev: TagToken[]) => [...prev, newTagToken]);
            setSelectedCells([]);
        }
        if (settingsOptions === 'bulk' && bulkOptions === 'coltype') {
            const colIndex =
                'col' +
                (Object.values(processedData[0]?.data['1']).findIndex(
                    e => e == selectedColumn
                ) +
                    1);
            let tagTokensAppend: TagToken[] = [];

            Object.keys(processedData[0]?.data).forEach((rowIndex, i) => {
                const token = processedData[0]?.data[rowIndex][colIndex];
                if (
                    token &&
                    (!firstRowHeader || i > 0) &&
                    tagTokens.filter(tt => tt.token === token).length == 0 &&
                    tagTokensAppend.filter(tt => tt.token === token).length == 0
                ) {
                    tagTokensAppend = [
                        ...tagTokensAppend,
                        {
                            tag: tag,
                            token: token,
                        },
                    ];
                }
            });
            setTagTokens(prev => [...prev, ...tagTokensAppend]);
            setSettingsOptions('');
            setBulkOptions('');
            setSelectedColumn('');
        }
    };

    const handleAddBulkTagToken = (tag: any) => {
        if (bulkOptions === 'colheader') {
            const colIndex =
                'col' +
                (Object.values(processedData[0]?.data['1']).findIndex(
                    e => e == tag
                ) +
                    1);
            let tagTokensAppend: TagToken[] = [];

            Object.keys(processedData[0]?.data).forEach((rowIndex, i) => {
                const token = processedData[0]?.data[rowIndex][colIndex];
                if (
                    token &&
                    i > 0 &&
                    tagTokens.filter(tt => tt.token === token).length == 0 &&
                    tagTokensAppend.filter(tt => tt.token === token).length == 0
                ) {
                    tagTokensAppend = [
                        ...tagTokensAppend,
                        {
                            tag: tag,
                            token: token,
                        },
                    ];
                }
            });
            setTagTokens(prev => [...prev, ...tagTokensAppend]);
            setSettingsOptions('');
            setBulkOptions('');
        }

        if (bulkOptions === 'coltype') {
            setSelectedColumn(tag);
        }

        if (bulkOptions === 'colpair') {
            if (selectedTextToken === '') {
                setSelectedTextToken(tag);
                setTimeout(() => setBulkPopupOpen(true), 500);
            } else {
                if (selectedTextToken === tag) {
                    alert('As colunas devem ser diferentes');
                    setSelectedTextToken('');
                    return;
                }
                const colTokens =
                    'col' +
                    (Object.values(processedData[0]?.data['1']).findIndex(
                        e => e == selectedTextToken
                    ) +
                        1);
                const colTags =
                    'col' +
                    (Object.values(processedData[0]?.data['1']).findIndex(
                        e => e == tag
                    ) +
                        1);
                let tagTokensAppend: TagToken[] = [];
                Object.keys(processedData[0]?.data).forEach((rowIndex, i) => {
                    const currToken =
                        processedData[0]?.data[rowIndex][colTokens];
                    const currTag = processedData[0]?.data[rowIndex][colTags];
                    if (
                        currToken &&
                        currTag &&
                        (!firstRowHeader || i > 0) &&
                        tagTokens.filter(tt => tt.token === currToken).length ==
                            0 &&
                        tagTokensAppend.filter(tt => tt.token === currToken)
                            .length == 0
                    ) {
                        tagTokensAppend = [
                            ...tagTokensAppend,
                            {
                                tag: currTag,
                                token: currToken,
                            },
                        ];
                    }
                });
                setTagTokens(prev => [...prev, ...tagTokensAppend]);
                setSettingsOptions('');
            }
        }
    };

    const handleSave = async (projectName: string) => {
        console.log(update ? 'update' : 'insert');
        if (!update) {
            if (tagTokens.length > 0) {
                // Lógica para mostrar o popup e obter o nome do projeto do usuário
                // const projectName = window.prompt("Digite o nome do projeto:");

                if (projectName) {
                    const formattedAnnotations = tagTokens.map(tt => [
                        tt.token,
                        tt.tag,
                    ]);

                    const user = getUserFromLocalStorage();
                    const userId = user?.userId?.replace(/^"(.*)"$/, '$1');

                    if (!userId) {
                        console.error('UserId não encontrado no localStorage.');
                        return;
                    }

                    try {
                        const response = await fetch(
                            `${REACT_APP_SERVER_URL}/api/spreadsheet/create-project`,
                            {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization:
                                        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0Zjc5MzBmY2RiZDVmYjZiYmD1MDcyMSIsIm5hbWUiOiJOb21lIGRvIFVzdcOhcmlvIiwiYXZhaWxhYmxlQ3JlZGl0cyI6NTAsImlhdCI6MTY5NDA2MTgwNiwiZXhwIjoxNzI1NjE4NzMyfQ._fssHXDNRo-b7YteXWawDU5ycXH-kmWFn2IlKpByR-k',
                                },
                                body: JSON.stringify({
                                    author_id: userId,
                                    instruction: projectName,
                                    data: formattedAnnotations,
                                }),
                            }
                        );

                        setIsCreateProjectPopupOpen(false);

                        if (response.ok) {
                            console.log('Assistente treinado com sucesso!');
                            // Você pode adicionar código adicional aqui, se necessário
                        } else {
                            console.error(
                                'Erro ao treinar assistente:',
                                await response.text()
                            );
                        }
                    } catch (error) {
                        console.error(
                            'Erro ao processar a solicitação:',
                            error
                        );
                    }
                }
            } else {
                console.error('Nenhuma anotação para treinar o assistente.');
                alert('Nenhuma anotação para treinar o assistente.');
            }
        } else {
            console.log(id);
            if (id) {
                console.log('tagtokens', tagTokens);
                console.log('savedtagtokens', savedTagTokens);
                if (tagTokens.length > savedTagTokens.length) {
                    const startIdx = savedTagTokens.length;

                    const selectedTagTokens = tagTokens.filter(
                        (tt, i) => i >= startIdx
                    );
                    const tokens = selectedTagTokens.map(tt => tt.token);
                    const tags = selectedTagTokens.map(tt => tt.tag);

                    console.log(tokens);
                    console.log(tags);

                    const updateResponse = await fetch(
                        `${REACT_APP_SERVER_URL}/api/spreadsheet/insert-data/${id}`,
                        {
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                tags: tags,
                                tokens: tokens,
                            }),
                        }
                    );
                    console.log(updateResponse);
                }
            }
        }
    };

    const handleDownload = () => {
        const storingData = JSON.parse(JSON.stringify(processedData[0].data)); //deep clone
        const maxCols = Object.keys(storingData).reduce(
            (ll: number, cl: any) =>
                Math.max(ll, Object.keys(storingData[cl]).length),
            0
        );
        const colTags = 'col' + (maxCols + 1);
        const colTokens = 'col' + (maxCols + 2);
        console.log(colTags, colTokens);
        storingData[1 + ''][colTags] = 'Tags';
        storingData[1 + ''][colTokens] = 'Tokens';
        tagTokens.forEach((tt, i) => {
            storingData[i + 2 + ''][colTags] = tt.tag;
            storingData[i + 2 + ''][colTokens] = tt.token;
        });

        const vectorStoringData = Object.values(storingData).map((row: any) =>
            Object.values(row)
        );
        console.log(vectorStoringData);
        const worksheet = XLSX.utils.aoa_to_sheet(vectorStoringData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Dados Processados');
        XLSX.writeFile(workbook, 'Dados Processados.xlsx');
        console.log(processedData[0].data);
    };

    useEffect(() => {
        //Effect para controlar estado da tabela
        if (selectedCells.length == 0 && settingsOptions.length === 0) {
            setTableState(TableState.PRE_SETTINGS);
            return;
        }

        if (settingsOptions !== 'bulk' && selectedCells.length === 0) {
            setTableState(TableState.WAITING_SELECT);
            return;
        }

        if (
            settingsOptions === 'bulk' &&
            tableState !== TableState.WAITING_BULK_TAG
        ) {
            setTableState(TableState.WAITING_BULK_TAG);
            return;
        }

        if (selectedCells.length == 1) {
            if (settingsOptions === 'other') {
                setTableState(TableState.WAITING_TAG_CELL);
                return;
            }

            if (settingsOptions === 'type') {
                setTableState(TableState.WAITING_TYPE_TAG);
                return;
            }
        }
    }, [selectedCells, settingsOptions]);

    const cellClicked = (rowIndex: number, colIndex: number) => {
        if (settingsOptions === '' || settingsOptions === 'bulk') return;

        const cell = {
            row: +rowIndex,
            col: +colIndex,
        };

        //Verifica se a célula clicada já está clicada, para então inverter a seleção
        if (
            selectedCells.filter(c => c.row == cell.row && c.col == cell.col)
                .length > 0
        ) {
            setSelectedCells(prev => {
                return prev.filter(c => c.col != cell.col || c.row != cell.row);
            });
            //Caso contrário..
        } else {
            if (selectedCells.length == 0) {
                setSelectedTextToken(
                    processedData[0]?.data[cell.row + ''][
                        'col' + (cell.col + 1)
                    ]
                );
                setSelectedCells(prev => {
                    return [...prev, cell];
                });
            } else {
                setSelectedTextToken('');
            }

            if (selectedCells.length == 1 && settingsOptions == 'other') {
                const newTagToken: TagToken = {
                    tag: processedData[0]?.data[rowIndex + ''][
                        'col' + (colIndex + 1)
                    ],
                    token: processedData[0]?.data[selectedCells[0].row + ''][
                        'col' + (selectedCells[0].col + 1)
                    ],
                };
                setTagTokens(prev => [...prev, newTagToken]);
                setSelectedCells([]);
            }
        }
    };

    useEffect(() => {
        //Effect para controlar as células selecionadas
        if (settingsOptions === 'bulk' && selectedCells.length > 0) {
            setSelectedCells([]);
        }
        setSelectedColumn('');
    }, [bulkOptions, settingsOptions]);

    useEffect(() => {
        if (update) {
            setSavedTagTokens([...tagTokens]);
        }
    }, []);

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar
                    sx={{
                        backgroundColor: '#02989E',
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography
                        sx={{ ml: 2, flex: 1 }}
                        variant="h6"
                        component="div"
                    >
                        Rotular Dados
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => console.log('nothing ')}
                        aria-label="close"
                    >
                        {/* <PlayCircleFilledWhiteOutlinedIcon /> */}
                    </IconButton>
                </Toolbar>
            </AppBar>
            {processedData[0] && processedData[0].data && (
                <div className="bg-green-500 w-full h-full flex flex-row">
                    <div className="grow-[3] h-full max-w-[75%] bg-white overflow-x-auto">
                        <table className="w-full overflow-x bg-white">
                            <tbody>
                                {Object.keys(processedData[0]?.data).map(
                                    rowIndex => {
                                        return (
                                            <tr key={rowIndex}>
                                                <td className="py-1 border-r border-gray-300 text-center text-black text-sm font-medium font-montserrat">
                                                    {rowIndex}
                                                </td>
                                                {Object.keys(
                                                    processedData[0]?.data[
                                                        rowIndex
                                                    ]
                                                ).map((key, cellIndex) => (
                                                    <TableCell
                                                        headerCell={
                                                            firstRowHeader &&
                                                            +rowIndex == 1
                                                        }
                                                        isSelected={
                                                            selectedCells.filter(
                                                                c =>
                                                                    c.col ==
                                                                        cellIndex &&
                                                                    c.row ==
                                                                        +rowIndex
                                                            ).length > 0
                                                        }
                                                        onClick={cellClicked}
                                                        rowIndex={rowIndex}
                                                        colIndex={cellIndex}
                                                        key={cellIndex}
                                                        cellData={
                                                            processedData[0]
                                                                ?.data[
                                                                rowIndex
                                                            ][key]
                                                        }
                                                        tagTokens={tagTokens}
                                                    />
                                                ))}
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="grow-[1] h-full bg-red-300">
                        <Settings
                            firstRowHeader={firstRowHeader}
                            setFirstRowHeader={setFirstRowHeader}
                            tableState={tableState}
                            settingsOptions={settingsOptions}
                            setSettingsOptions={setSettingsOptions}
                            bulkOptions={bulkOptions}
                            setBulkOptions={setBulkOptions}
                            openBulkPopup={openBulkPopup}
                            setIsCreateProjectPopupOpen={
                                setIsCreateProjectPopupOpen
                            }
                            handleSave={handleSave}
                            handleDownload={handleDownload}
                            update={update}
                        />
                    </div>
                </div>
            )}
            <InputDialogPopup
                open={isInputPopupOpen}
                title="Adicionar novo Token"
                inputLabel="Nome da Tag/Rótulo:"
                content={
                    bulkOptions === 'colheader'
                        ? `Informe a tag correspondente ao token: ${selectedTextToken}`
                        : `Digite a tag para rotular os dados:`
                }
                onSubmit={handleAddTagToken}
                handleClose={() => {
                    clearSelection();
                }}
            />
            <InputDialogPopup //Popup para salvar o projeto
                open={isCreateProjectPopupOpen}
                title="Salvar Projeto"
                inputLabel="Nome do Projeto:"
                onSubmit={handleSave}
                handleClose={() => {
                    setIsCreateProjectPopupOpen(false);
                }}
            />
            <InputDialogPopup
                open={bulkPopupOpen}
                title="Adicionar Tokens em Lote"
                options={Object.values(processedData[0]?.data['1'])}
                objectToString={(e: any) => e}
                optionLabel={
                    bulkOptions != 'colpair'
                        ? 'Selecione a Coluna (a partir do primeiro elemento)'
                        : selectedTextToken === ''
                        ? 'Selecione a Coluna que contém os dados a serem Rotulados (a partir do primeiro elemento)'
                        : 'Selecione a Coluna que contém os Rótulos (a partir do primeiro elemento)'
                }
                content={
                    bulkOptions == 'colpair' && selectedTextToken !== ''
                        ? `A coluna iniciando com o valor ${selectedTextToken} foi definida como a coluna dos tokens`
                        : ''
                }
                onSubmit={handleAddBulkTagToken}
                handleClose={() => {
                    setBulkPopupOpen(false);
                }}
            />
        </Dialog>
    );
};

export default TagPopup;
