import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logoNeuronLab from '../../logo-neuronlab-site.png';
import axios from 'axios';

import { REACT_APP_SERVER_URL } from '../../config/keys';
import { LogOut, Settings } from 'lucide-react'; // Importe o ícone de logout

interface UserInfo {
  name: string;
  availableCredits: number;
}

export const LandingNavbar = () => {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');

    if (storedUserId) {
      axios
        .get<UserInfo>(`${REACT_APP_SERVER_URL}/api/users/infos/${JSON.parse(storedUserId)}`)
        .then((response) => {
          const updatedUserInfo: UserInfo = {
            ...response.data,
            availableCredits: Math.max(0, response.data.availableCredits),
          };
          setUserInfo(updatedUserInfo);
        })
        .catch((error) => {
          console.error('Erro ao buscar informações do usuário:', error);
        });
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const creditsBackgroundClass = userInfo?.availableCredits === 0 ? 'bg-red-500' : '';
  const creditsTextColorClass = userInfo?.availableCredits === 0 ? 'text-white' : '';

  return (
    <nav className="p-4 bg-transparent flex items-center justify-between">
      <Link to="/" className="flex items-center">
        <div className="relative w-8 h-8 mr-1">
          <img
            src={logoNeuronLab}
            alt="Neuron Lab"
            className="fill-class-if-needed"
          />
        </div>
        <h1 className="text-2xl font-montserrat text-white">NEURON LAB</h1>
      </Link>
      
      <div className="flex items-center text-white font-montserrat ml-4 text-base">
        {userInfo && (
          <>
            <div className="mr-10 flex flex-col"> {/* Usando flex para exibir as linhas lado a lado */}
              <p className={`text-xl ${creditsTextColorClass}`}>Olá, {userInfo.name}! </p>
              <div className={creditsBackgroundClass}>
                <p className={`text-sm text-gray-400 ${creditsTextColorClass}`}>
                  Você tem {userInfo.availableCredits} créditos
                </p>
              </div>
            </div>
          </>
        )}
        <div className="ml-auto flex items-center"> 
        <div>
        <Link to="/settings">
          <button
            className="bg-[#192339] text-white text-base font-bold font-montserrat py-1 px-4 rounded-lg mr-2"
            style={{ height: '30px' }}
          >
            <Settings className="h-5 w-5" />
          </button>
        </Link>
        </div>
        <div>
          <button
            onClick={handleLogout}
            className="bg-[#192339] text-white text-base font-bold font-montserrat py-1 px-4 rounded-lg"
            style={{ height: '30px' }}
          >
            <LogOut className="h-5 w-5" />
          </button>
        </div>
        </div>
      </div>
    </nav>
  );
};
