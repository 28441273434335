import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TypewriterComponent from 'typewriter-effect';
import axios from 'axios';

import { REACT_APP_SERVER_URL } from '../../config/keys';
import { LogOut } from 'lucide-react'; // Importe o ícone de logout

interface UserInfo {
    name: string;
    availableCredits: number;
}

export const LandingHero = () => {
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get<UserInfo>(
                    `${REACT_APP_SERVER_URL}/api/users/infos/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    const updatedUserInfo: UserInfo = {
                        ...response.data,
                        availableCredits: Math.max(
                            0,
                            response.data.availableCredits
                        ),
                    };
                    setUserInfo(updatedUserInfo);
                })
                .catch(error => {
                    console.error(
                        'Erro ao buscar informações do usuário:',
                        error
                    );
                });
        }
    }, []);

    return (
        <div className="text-white font-bold py-36 text-center space-y-5">
            <div className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl space-y-5 font-extrabold -mt-6">
                <h1>Assistentes de IA para</h1>
                <div className="text-[#111827]">
                    <div
                        className="gradient-container"
                        style={{
                            background: 'linear-gradient(to right, #7ad9de, #03989E)',
                            display: 'inline-block',
                            padding: '5px', // Adjust as needed
                            borderRadius: '4px', // Adjust as needed
                        }}
                    >
                        <TypewriterComponent
                            options={{
                                strings: [
                                    'Notas Fiscais',
                                    'Faturas de Energia',
                                    'Notícias',
                                    'CNPJ',
                                    'CPF',
                                    'Documentos',
                                    'Legislação Trabalhista',
                                    'Escrituras de Imóveis',
                                    'Jurisprudência',
                                    'Comparação de Documentos',
                                ],
                                autoStart: true,
                                loop: true,
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="text-base md:text-xl font-light text-zinc-400 -mt-10">
                Potencialize seus dados com assistentes de Inteligência Artificial.
            </div>
            <div>
                {userInfo && (
                    <>
                        <div className="mx-auto flex flex-col items-center mt-10 -mb-10">
                            {userInfo.availableCredits <= 0 ? (
                                <button
                                    className="bg-gradient-to-r from-[#FF6B6B] to-[#FFA06B] text-white font-bold py-3 px-6 rounded-lg w-64 h-20" // Cores mais chamativas
                                >
                                    <div className="font-montserrat text-xl">
                                        Obter mais créditos
                                    </div>
                                </button>
                            ) : (
                                <p></p>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};