import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import { X, Search, Download, RefreshCcw, GitMerge } from 'lucide-react';
import { RiRobot2Line } from 'react-icons/ri';
import axios from 'axios';
import * as XLSX from 'xlsx';
import Joyride from 'react-joyride';

import { REACT_APP_SERVER_URL } from '../config/keys';
import { getUserFromLocalStorage } from '../services';

import ConfirmationPopup from './Global/ConfirmationPopup';

interface UserInfo {
    userId: string;
    name: string;
    availableCredits: number;
}

interface DownloadData {
    tensao: string;
    concessionaria: string;
    ieConcessionaria: string;
    cnpjConcessionaria: string;
    roteiroDeLeitura: string;
    cepConsumidora: string;
    ruaConsumidora: string;
    numeroConsumidora: string;
    cidadeConsumidora: string;
    ufConsumidora: string;
    cnpjConsumidora: string;
    ieConsumidora: string;
    numeroContratoAgencia: string;
    pn: string;
    unidadeConsumidora: string;
    contaMes: string;
    vencimentoConta: string;
    totalPagar: string;
    demandaPonta1: string;
    demandaPonta2: string;
    demandaPonta2Tarifa: string;
    demandaForaPonta1: string;
    demandaForaPonta1Tarifa: string;
    demandaForaPonta2: string;
    demandaForaPonta2Tarifa: string;
    tusdPonta: string;
    tusdPontaTarifa: string;
    tusdForaPonta: string;
    tusdForaPontaTarifa: string;
    tePonta: string;
    tePontaTarifa: string;
    teForaPonta: string;
    teForaPontaTarifa: string;
    valorTotalOperacao: string;
    baseCalculoIcms: string;
    icms: string;
    baseCalculoPisCofins: string;
    pis: string;
    cofins: string;
    pisPercentual: string;
    cofinsPercentual: string;
    demandaContratadaPonta: string;
    demandaContratadaForaPonta: string;
    kWhPontaTE: string;
    kWhFpontaTe: string;
    kWhPontaTusd: string;
    kWhFPontaTusd: string;
    kwPonta: string;
    kWFPonta: string;
    tensaoContratado: string;
    nivelTensaoMinimo: string;
    nivelTensaoMaximo: string;
}

interface EnergyBillResponse {
    _id: string;
    concessionaria: string;
    unidadeConsumidora: string;
    tensao: string;
    ruaConsumidora: string;
    cidadeConsumidora: string;
    ufConsumidora: string;
    contaMes: string;
    vencimentoConta: string;
    totalPagar: string;
}

interface UserInfo {
    userId: string;
    name: string;
    availableCredits: number;
}

const EnergyBill: React.FC = () => {
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [companies, setCompanies] = useState<EnergyBillResponse[]>([]);
    const [allCompanies, setAllCompanies] = useState<EnergyBillResponse[]>([]);
    const [download, setDownload] = useState<DownloadData[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [kpisData, setKpisData] = useState<any>({});
    const companiesPerPage = 3;

    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [companyIdToDelete, setCompanyIdToDelete] = useState<string | null>(
        null
    );

    const [tourFinished, setTourFinished] = useState(false);

    const [searchFilter, setSearchFilter] = useState<string>('');
    const [filteredCompanies, setFilteredCompanies] = useState<
        EnergyBillResponse[]
    >([]);

    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const filtered = companies.filter(company => {
            const unidadeConsumidora = company.unidadeConsumidora || ''; // Tratamento para "N/A"
            return unidadeConsumidora
                .toLowerCase()
                .includes(searchFilter.toLowerCase());
        });
        setFilteredCompanies(filtered);
    }, [companies, searchFilter]);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get<UserInfo>(
                    `${REACT_APP_SERVER_URL}/api/users/infos/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    setUserInfo(response.data);
                })
                .catch(error => {
                    console.error('Error fetching user information:', error);
                });
        }
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        axios
            .get<DownloadData[]>(
                `${REACT_APP_SERVER_URL}/api/energy-bill/download-energy-bill-data/${JSON.parse(
                    storedUserId!
                )}`
            )
            .then(response => {
                setDownload(response.data);
            })
            .catch(error => {
                console.error('Error fetching user information:', error);
            });
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        axios
            .get<EnergyBillResponse[]>(
                `${REACT_APP_SERVER_URL}/api/energy-bill/energy-bill-data/${JSON.parse(
                    storedUserId!
                )}`
            )
            .then(response => {
                setAllCompanies(response.data);
                setCompanies(response.data);
            })
            .catch(error => {
                console.error('Error fetching user information:', error);
            });
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/energy-bill/kpis-energy-bill-data/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    setKpisData(response.data);
                })
                .catch(error => {
                    console.error('Error fetching KPIs data:', error);
                });
        }
    }, []);

    const indexOfLastCompany = currentPage * companiesPerPage;
    const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
    const currentFilteredCompanies = filteredCompanies.slice(
        indexOfFirstCompany,
        indexOfLastCompany
    );

    const totalPages = Math.ceil(filteredCompanies.length / companiesPerPage);

    const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const handlePrevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleSearchClick = async () => {
        const user = getUserFromLocalStorage();

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');

            try {
                if (!selectedFiles || selectedFiles.length === 0) {
                    console.error('Nenhum arquivo selecionado.');
                    return;
                }

                setLoading(true);

                const formData = new FormData();

                for (let i = 0; i < selectedFiles.length; i++) {
                    formData.append('pdfFiles', selectedFiles[i]);
                }

                const endpoint = `${REACT_APP_SERVER_URL}/api/energy-bill/create?author_id=${userId}`;

                await axios.post(endpoint, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                setLoading(false);

                window.location.reload();
            } catch (error) {
                console.error('Erro durante o envio de arquivos:', error);

                setLoading(false);
            }
        }
    };

    const handleDownloadExcel = () => {
        const rows = download.map(company => {
            return {
                Concessionária: company.concessionaria || 'N/A',
                Tensão: company.tensao || 'N/A',
                'IE da Concessionária': company.ieConcessionaria || 'N/A',
                'CNPJ da Concessionária': company.cnpjConcessionaria || 'N/A',
                'Roteiro de Leitura': company.roteiroDeLeitura || 'N/A',
                'Rua Consumidora': company.ruaConsumidora
                    ? `${company.ruaConsumidora
                          .charAt(0)
                          .toUpperCase()}${company.ruaConsumidora
                          .slice(1)
                          .replace(/,\s*$/, '')}`
                    : 'N/A',
                'Número Consumidora': company.numeroConsumidora || 'N/A',
                'Cidade Consumidora': company.cidadeConsumidora
                    ? `${company.cidadeConsumidora
                          .charAt(0)
                          .toUpperCase()}${company.cidadeConsumidora
                          .slice(1)
                          .toLowerCase()}`
                    : 'N/A',
                'UF Consumidora': company.ufConsumidora
                    ? company.ufConsumidora.replace(/^\//, '')
                    : 'N/A',
                'CEP Consumidora': company.cepConsumidora || 'N/A',
                'CNPJ Consumidora': company.cnpjConsumidora || 'N/A',
                'IE Consumidora': company.ieConsumidora || 'N/A',
                'Número Contrato Agência':
                    company.numeroContratoAgencia || 'N/A',
                PN: company.pn || 'N/A',
                'Unidade Consumidora': company.unidadeConsumidora || 'N/A',
                'Conta do Mês': company.contaMes || 'N/A',
                'Vencimento Conta': company.vencimentoConta || 'N/A',
                'Total a Pagar': company.totalPagar || 'N/A',
                'Demanda Ponta 1 (kW)': company.demandaPonta1 || 'N/A',
                'Demanda Ponta 2 (kW)': company.demandaPonta2 || 'N/A',
                'Demanda Ponta 2 Tarifa': company.demandaPonta2Tarifa || 'N/A',
                'Demanda Fora Ponta 1 (kW)': company.demandaForaPonta1 || 'N/A',
                'Demanda Fora Ponta 1 Tarifa':
                    company.demandaForaPonta1Tarifa || 'N/A',
                'Demanda Fora Ponta 2 (kW)': company.demandaForaPonta2 || 'N/A',
                'Demanda Fora Ponta 2 Tarifa':
                    company.demandaForaPonta2Tarifa || 'N/A',
                'TUSD Ponta': company.tusdPonta || 'N/A',
                'TUSD Ponta Tarifa': company.tusdPontaTarifa || 'N/A',
                'TUSD Fora Ponta': company.tusdForaPonta || 'N/A',
                'TUSD Fora Ponta Tarifa': company.tusdForaPontaTarifa || 'N/A',
                'TE Ponta': company.tePonta || 'N/A',
                'TE Ponta Tarifa': company.tePontaTarifa || 'N/A',
                'TE Fora Ponta': company.teForaPonta || 'N/A',
                'TE Fora Ponta Tarifa': company.teForaPontaTarifa || 'N/A',
                'Valor Total Operação': company.valorTotalOperacao || 'N/A',
                'Base Cálculo ICMS': company.baseCalculoIcms || 'N/A',
                ICMS: company.icms || 'N/A',
                'Base Cálculo PIS Cofins':
                    company.baseCalculoPisCofins || 'N/A',
                PIS: company.pis || 'N/A',
                COFINS: company.cofins || 'N/A',
                'PIS Percentual': company.pisPercentual || 'N/A',
                'COFINS Percentual': company.cofinsPercentual || 'N/A',
                'Demanda Contratada Ponta':
                    company.demandaContratadaPonta || 'N/A',
                'Demanda Contratada Fora Ponta':
                    company.demandaContratadaForaPonta || 'N/A',
                'kWh Ponta TE': company.kWhPontaTE || 'N/A',
                'kWh Fora Ponta TE': company.kWhFpontaTe || 'N/A',
                'kWh Ponta TUSD': company.kWhPontaTusd || 'N/A',
                'kWh Fora Ponta TUSD': company.kWhFPontaTusd || 'N/A',
                'kW Ponta': company.kwPonta || 'N/A',
                'kW Fora Ponta': company.kWFPonta || 'N/A',
                'Tensão Contratada': company.tensaoContratado || 'N/A',
                'Nível Tensão Mínimo': company.nivelTensaoMinimo || 'N/A',
                'Nível Tensão Máximo': company.nivelTensaoMaximo || 'N/A',
            };
        });

        console.log(rows);

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(rows);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Faturas de Energia');

        XLSX.writeFile(workbook, 'FaturasDeEnergia.xlsx', {
            compression: true,
        });
    };

    const handleDeleteCompany = (companyId: string) => {
        setCompanyIdToDelete(companyId);
        setShowConfirmationPopup(true);
    };

    const handleCancelDelete = () => {
        setCompanyIdToDelete(null);
        setShowConfirmationPopup(false);
    };

    const handleConfirmDelete = async () => {
        try {
            console.log('Deleting company with ID:', companyIdToDelete);
            await axios.delete(
                `${REACT_APP_SERVER_URL}/api/energy-bill/delete-bill-energy-data`,
                {
                    params: {
                        _id: companyIdToDelete,
                    },
                }
            );

            console.log('Company deleted successfully');
        } catch (error) {
            console.error('Error deleting company:', error);
        } finally {
            setCompanyIdToDelete(null);
            setShowConfirmationPopup(false);
            window.location.reload();
        }
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        setSelectedFiles(files);
    };

    return (
        <div className="min-h-screen bg-[#111827] py-10">
            <div>
                <Link
                    to="/"
                    className="text-white hover:text-[#39A2A7]/80 font-semibold text-sm font-montserrat"
                >
                    &#8592; Voltar
                </Link>
            </div>
            <div className="flex mt-8 space-x-4">
                <div className="bg-[#1f2937] rounded-lg p-6  w-1/3 h-full">
                <div className="mb-2">
                        <p className="text-2xl text-white font-semibold cursor-default font-montserrat rounded">
                            Assistente de IA para <br />
                            <span className="bg-[#02989E] px-2 py-1 animate-pulse font-montserrat rounded text-black text-2xl">
                                Faturas de Energia
                            </span>
                        </p>
                    </div>
                    <p className="mb-4 text-sm font-montserrat text-white">
                        Extraia e organize dados de faturas de energia.
                    </p>
                    <div className="flex flex-col items-center mb-2">
                        <label
                            htmlFor="fileInput"
                            className="cursor-pointer w-full"
                        >
                            <div
                                className="bg-[#1f2937] border-dashed border border-white py-2 px-4 rounded-md text-white flex items-center justify-center font-montserrat"
                                id="inputDocument"
                            >
                                Selecionar Faturas
                            </div>
                        </label>
                        <input
                            type="file"
                            accept=".pdf"
                            multiple
                            className="hidden"
                            id="fileInput"
                            onChange={handleFileChange}
                        />
                        {selectedFiles && selectedFiles.length > 0 && (
                            <p className="mt-2 text-base font-light font-montserrat text-yellow-300 italic">
                                {`${selectedFiles.length} ${
                                    selectedFiles.length === 1
                                        ? 'fatura selecionada'
                                        : 'faturas selecionadas'
                                }`}
                            </p>
                        )}
                    </div>
                    <button
                        onClick={() => handleSearchClick()}
                        id="search-button"
                        className={`mt-3 bg-[#111827] text-white px-6 py-2 rounded font-medium hover:bg-[#111827]/50 focus:outline-none focus:ring focus:border-blue-300 font-montserrat ${
                            loading ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                        disabled={loading}
                    >
                        {loading ? 'Aguarde...' : <RiRobot2Line size={24} />}
                    </button>
                    <button
                        type="button"
                        id="download"
                        className="mt-2 ml-3 bg-[#111827] text-white px-6 py-2 rounded font-medium hover:bg-[#111827]/50 focus:outline-none focus:ring focus:border-blue-300 font-montserrat
            "
                        onClick={handleDownloadExcel}
                    >
                        <Download />
                    </button>
                </div>
                <div></div>
                <div>
                    <div
                        className="flex justify-center -mt-14 text-sm font-montserrat"
                        id="kpis"
                    >
                        <div>
                            <div className="mx-0 md:max-w-8xl lg:max-w-8xl xl:max-w-12xl ml-35">
                                <div className="grid grid-cols-1 md:grid-cols-6 gap-4 ml-10">
                                    <div className="col-span-1 bg-[#1f2937] text-white p-4 rounded-lg shadow-md">
                                        <p className="text-sm text-muted-foreground font-montserrat">
                                            Total de
                                            <br />
                                            <span className="text-sm">
                                                Faturas
                                            </span>
                                        </p>
                                        <p className="text-2xl font-semibold mt-2">
                                            {kpisData.count_bills || '-'}
                                        </p>
                                    </div>
                                    <div className="col-span-2 md:col-span-1 bg-[#1f2937] text-white p-4 rounded-lg shadow-md ml-4 md:ml-0">
                                        <p className="text-sm text-muted-foreground font-montserrat">
                                            <span className="text-sm">
                                                Concessioná
                                                <br />
                                                rias
                                            </span>
                                        </p>
                                        <p className="text-2xl font-semibold mt-2">
                                            {kpisData.count_unique_concessionarias ||
                                                '-'}
                                        </p>
                                    </div>

                                    <div className="col-span-1 bg-[#1f2937] text-white p-4 rounded-lg shadow-md">
                                        <p className="text-sm text-muted-foreground font-montserrat">
                                            Total de
                                            <br />
                                            <span className="text-sm">
                                                Cidades
                                            </span>
                                        </p>
                                        <p className="text-2xl font-semibold mt-2">
                                            {kpisData.count_unique_municipio ||
                                                '-'}
                                        </p>
                                    </div>
                                    <div className="col-span-1 bg-[#1f2937] text-white p-4 rounded-lg shadow-md">
                                        <p className="text-sm text-muted-foreground font-montserrat">
                                            Total de
                                            <br />
                                            <span className="text-sm">
                                                Estados
                                            </span>
                                        </p>
                                        <p className="text-2xl font-semibold mt-2">
                                            {kpisData.count_unique_ufs || '-'}
                                        </p>
                                    </div>
                                    <div className="col-span-1 bg-[#1f2937] text-white p-4 rounded-lg shadow-md">
                                        <p className="text-sm text-muted-foreground font-montserrat">
                                            Unidades
                                            <br />
                                            <span className="text-sm">
                                                Consumidoras
                                            </span>
                                        </p>
                                        <p className="text-2xl font-semibold mt-2">
                                            {kpisData.count_unique_cnpjs || '-'}
                                        </p>
                                    </div>
                                    <div className="col-span-1 bg-[#1f2937] text-white p-4 rounded-lg shadow-md">
                                        <p className="text-sm text-muted-foreground font-montserrat">
                                            Valor Total
                                            <br />
                                            <span className="text-sm">
                                                Faturas
                                            </span>
                                        </p>
                                        <p className="text-2xl font-semibold mt-2">
                                            {kpisData.total_pagar_sum || '-'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 mb-0">
                        <input
                            type="text"
                            id="search"
                            value={searchFilter}
                            onChange={e => setSearchFilter(e.target.value)}
                            placeholder="Pesquisar Unidade Consumidora..."
                            className="w-full ml-5 h-8 px-4 pr-10 text-sm placeholder-gray-400 bg-[#1f2937] border rounded focus:outline-none focus:border-[#39A2A7] text-white"
                        />
                    </div>
                    <div
                        className="container mx-auto p-4 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-12"
                        id="companies"
                    >
                        {currentFilteredCompanies.map(company => (
                            <div
                                key={company._id}
                                className="bg-[#1f2937] text-white rounded-lg p-6 cursor-pointer mr-0 transition-transform hover:scale-105 w-96 md:w-72 h-auto -mt-0"
                            >
                                <X
                                    size={24}
                                    className="cursor-pointer text-red-500 hover:text-red-700 mb-2 -mt-2"
                                    onClick={() =>
                                        handleDeleteCompany(company._id)
                                    }
                                />
                                <div className="relative">
                                    <div className="flex items-center">
                                        <div className="w-full">
                                            <p className="text-sm font-medium font-montserrat mb-2 overflow-hidden overflow-ellipsis max-w-full whitespace-nowrap">
                                                {company.concessionaria ||
                                                    'N/A'}
                                            </p>
                                            <p className="text-gray-400 text-xs font-montserrat mb-0">
                                                UC:{' '}
                                                {company.unidadeConsumidora ||
                                                    'N/A'}
                                            </p>
                                            <p className="text-gray-400 text-xs font-montserrat mb-0">
                                                Tensão:{' '}
                                                {company.tensao || 'N/A'}
                                            </p>
                                            <p className="text-gray-400 text-xs font-montserrat mb-0">
                                                Endereço:{' '}
                                                {company.ruaConsumidora
                                                    ? `${company.ruaConsumidora
                                                          .toLowerCase()
                                                          .replace(
                                                              /\b\w/g,
                                                              char =>
                                                                  char.toUpperCase()
                                                          )
                                                          .replace(
                                                              /,\s*$/,
                                                              ''
                                                          )}`
                                                    : 'N/A'}
                                            </p>
                                            <p className="text-gray-400 text-xs font-montserrat mb-0">
                                                Cidade:{' '}
                                                {`${
                                                    company.cidadeConsumidora
                                                        ? company.cidadeConsumidora
                                                              .toLowerCase()
                                                              .replace(
                                                                  /\b\w/g,
                                                                  char =>
                                                                      char.toUpperCase()
                                                              )
                                                        : 'N/A'
                                                } - ${
                                                    company.ufConsumidora
                                                        ? company.ufConsumidora.replace(
                                                              /^\//,
                                                              ''
                                                          )
                                                        : 'N/A'
                                                }`}
                                            </p>

                                            <p className="text-gray-400 text-xs font-montserrat mb-0">
                                                Conta Mês:{' '}
                                                {company.contaMes || 'N/A'}
                                            </p>
                                            <p className="text-gray-400 text-xs font-montserrat mb-0">
                                                Vencimento:{' '}
                                                {company.vencimentoConta ||
                                                    'N/A'}
                                            </p>
                                            <div className="border border-solid border-blue-400 p-2 mb-2 mt-2 rounded">
                                                <p className="text-xs font-montserrat text-white font-medium">
                                                    Valor da Fatura:{' '}
                                                    <strong>
                                                        {company.totalPagar}
                                                    </strong>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="flex justify-center mt-4 ml-62 text-gray-100 text-sm font-montserrat">
                <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    Anterior
                </button>
                <span className="mx-2 text-gray-100 text-xs font-montserrat">
                    {currentPage}
                </span>
                <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    Próxima
                </button>
            </div>
            {!tourFinished && (
                <Joyride
                    steps={[
                        {
                            target: '#inputDocument',
                            content: (
                                <div>
                                    <p>
                                        Bem-vindo a IA para Análise de Faturas
                                        de Energia!
                                    </p>
                                    <br />
                                    <p>
                                        Na primeira etapa, faça o upload de uma
                                        ou várias faturas de energia nesse
                                        campo.
                                    </p>
                                </div>
                            ),
                            placement: 'bottom',
                        },
                        {
                            target: '#search-button', // Substitua '.search-button' pela classe ou id do seu botão de pesquisa
                            content: (
                                <div>
                                    <p>
                                        Agora, clique nesse botão para iniciar a
                                        análise dos arquivos.
                                    </p>
                                </div>
                            ),
                        },
                        {
                            target: '#companies',
                            content: (
                                <div>
                                    <p>Visualize os resultados na dashboard.</p>
                                </div>
                            ),
                            placement: 'bottom',
                        },

                        {
                            target: '#download',
                            content: (
                                <div>
                                    <p>
                                        Para obter os dados, clique no botão de
                                        download.
                                    </p>
                                    <br />
                                    <p>
                                        Isso permitirá que você baixe as
                                        informações em um arquivo Excel.
                                    </p>
                                </div>
                            ),
                        },
                        {
                            target: '#search',
                            content: (
                                <div>
                                    <p>
                                        Realize pesquisas pela Unidade
                                        Consumidade salvas na sua base de dados.
                                    </p>
                                </div>
                            ),
                        },
                        {
                            target: '#kpis',
                            content: (
                                <div>
                                    <p>
                                        Indicadores com informações importantes
                                        das faturas salvas na sua base de dados.
                                    </p>
                                </div>
                            ),
                        },
                    ]}
                    run={!tourFinished}
                    continuous
                    showSkipButton
                    callback={data => {
                        if (
                            data.status === 'finished' ||
                            data.status === 'skipped'
                        ) {
                            setTourFinished(true);
                        }
                    }}
                    styles={{
                        options: {
                            overlayColor: 'rgba(17, 24, 39, 0.9)',
                            primaryColor: '#39A2A7',
                            textColor: '#000000',
                            width: 400,
                            zIndex: 1000,
                        },
                        tooltip: {
                            fontFamily: 'Montserrat',
                        },
                        buttonNext: {
                            backgroundColor: '#39A2A7',
                            color: '#FFFFFF',
                            border: 'none',
                            borderRadius: '4px',
                            marginLeft: '0',
                        },
                    }}
                    locale={{
                        next: 'Próximo',
                        last: 'Último',
                        skip: 'Pular',
                        back: 'Anterior',
                    }}
                />
            )}
            {showConfirmationPopup && (
                <ConfirmationPopup
                    message="Tem certeza de que deseja excluir esta fatura?"
                    onCancel={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />
            )}
        </div>
    );
};

export default EnergyBill;
