import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Importe o componente Link
import { Heading } from '../components/Global/Heading';
import { TextSelect, Plus } from 'lucide-react';
import * as XLSX from 'xlsx';
import { REACT_APP_API_URL_MS_DOCUMENT } from "../config/keys";

interface PDFResponse {
  files: Array<{
    file_name: string;
    pages: Array<{
      page: number;
      text: string;
    }>;
    most_common_words: string[];
  }>;
}

interface Annotation {
  label: string;
  text: string;
}

const ExtractTextPdfPage: React.FC = () => {
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<PDFResponse | null>(null);
  const [selectedFileIndex, setSelectedFileIndex] = useState(-1);
  const [currentPage, setCurrentPage] = useState(1);
  const [annotations, setAnnotations] = useState<Annotation[]>([]);
  const [selectedLabel, setSelectedLabel] = useState<string | null>(null);
  const [selectedText, setSelectedText] = useState<string>('');
  const [isAddingNewLabel, setIsAddingNewLabel] = useState(false);

  const [labels, setLabels] = useState<string[]>(["Importante", "Destaque", "Observação"]);
  const [newLabel, setNewLabel] = useState<string>("");

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFiles(event.target.files);
    }
  };

  const handleUpload = async () => {
    if (!selectedFiles) return;

    // Validate selected files to ensure they are PDFs
    const isValidFiles = Array.from(selectedFiles).every(file =>
      file.type === 'application/pdf'
    );

    if (!isValidFiles) {
      alert('Por favor, adicione apenas arquivos PDF.');
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('files', selectedFiles[i], selectedFiles[i].name);
    }

    const headers = new Headers();
    headers.append('Authorization', 'Bearer 7XT6jpcILSEMv-qN8r_sH0z76dTF1r_g7uEvXa__BMo');

    setLoading(true);

    try {
        const response = await fetch(`${REACT_APP_API_URL_MS_DOCUMENT}/api/extract-text-pdf`, {
        method: 'POST',
        headers: headers,
        body: formData,
        mode: 'cors',
      });

      if (response.ok) {
        const responseData = await response.json();
        setResponse(responseData);
        setSelectedFileIndex(-1);
        setCurrentPage(1);
      } else {
        // Handle upload error if needed
      }
    } catch (error) {
      // Handle upload error if needed
    } finally {
      setLoading(false);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (response && selectedFileIndex !== -1 && currentPage < response.files[selectedFileIndex].pages.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleTextSelection = () => {
    const selectedText = window.getSelection()?.toString();
    setSelectedText(selectedText || '');
  };

  const handleAddAnnotation = () => {
    if (selectedLabel && selectedText) {
      const newAnnotation = { label: selectedLabel, text: selectedText };
      setAnnotations([...annotations, newAnnotation]);
      setSelectedText('');
      setSelectedLabel(null);
    }
  };

  const handleNewLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewLabel(event.target.value);
  };

  const handleAddNewLabel = () => {
    if (newLabel.trim() !== "") {
      setLabels([...labels, newLabel]);
      setSelectedLabel(newLabel);
      setNewLabel("");
      setIsAddingNewLabel(false); // Fechar o popup
    }
  };

  const applyAnnotations = (text: string) => {
    const formattedText = annotations.reduce((acc, annotation) => {
      if (annotation.label === "Importante") {
        return acc.replace(new RegExp(annotation.text, 'g'), `<mark>${annotation.text}</mark>`);
      }
      return acc;
    }, text);

    return { __html: formattedText };
  };

  const handleDownloadExcel = () => {
    const rows = annotations.map((annotation) => ({
      Rotulo: annotation.label,
      Texto: annotation.text,
    }));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(rows);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Anotacoes');

    XLSX.writeFile(workbook, 'Anotacoes.xlsx', { compression: true });
  };

  return (
    <div className="relative min-h-screen bg-[#03989E]">
    <Link to="/" className="absolute top-0 left-0 m-4 text-white">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </svg>
      </Link>
      <div className="absolute bottom-0 right-0 mr-4 mb-5 text-gray-200 text-center text-base italic font-montserrat">
        Essa versão é utilizada como Free Trial <br /> dos serviços da plataforma Neuron Lab
      </div>
      <div className="flex flex-col items-center justify-center p-8">
        <Heading
          title="Extração de Texto de PDF"
          description="PDFs em texto: informação ao seu alcance."
          icon={TextSelect}
          iconColor="text-[#000000]"
          bgColor="bg-[#B3D8D9]/40"
        />
        <div className="bg-white border rounded-lg p-6 shadow-md mt-8 max-w-lg w-full">
          <div className="mb-4">
            <h2 className="text-lg font-medium mb-2 font-montserrat">Passo 1: Insira os Arquivos PDF</h2>
            <p className="text-gray-600 font-montserrat">Selecione os arquivos PDF que deseja analisar.</p>
            <input
              type="file"
              accept=".pdf"
              multiple
              className="mt-2 border rounded p-2 w-full font-montserrat"
              onChange={handleFileChange}
            />
          </div>
          <button
            className={`font-montserrat mt-1 bg-[#03989E] hover:bg-[#03989E]/90 text-white font-semibold py-2 px-4 rounded ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={handleUpload}
            disabled={loading}
          >
            {loading ? (
              <svg className="animate-spin h-5 w-5 ml-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018 8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              'Upload PDF'
            )}
          </button>
        </div>
        {response && (
          <div className="mt-8 bg-white border rounded-lg p-6 shadow-md max-w-lg w-full ">
            <p className="text-gray-600 font-montserrat">PDFs Analisados</p>
            <select
              className="mb-4 border rounded p-2 w-full mt-2 font-montserrat"
              value={selectedFileIndex}
              onChange={(e) => {
                setSelectedFileIndex(Number(e.target.value));
                setCurrentPage(1);
              }}
            >
              <option className='font-montserrat' value={-1}>Selecione o PDF</option>
              {response.files.map((file, index) => (
                <option key={index} value={index}>
                  {file.file_name}
                </option>
              ))}
            </select>
            {selectedFileIndex !== -1 && (
              <div className=' max-h-96 overflow-y-auto overflow-x-auto'>
                <div className="mb-4 mt-2">
                  <p className="text-gray-600 font-montserrat">Página {currentPage}</p>
                  <div>
                    <p onMouseUp={handleTextSelection} dangerouslySetInnerHTML={applyAnnotations(response.files[selectedFileIndex].pages[currentPage - 1].text)} />
                    <div className="mt-6 flex font-montserrat">
                      <select
                        className="border rounded p-2 w-full font-montserrat"
                        value={selectedLabel || ''}
                        onChange={(e) => setSelectedLabel(e.target.value)}
                      >
                        <option value="">Selecione um rótulo</option>
                        {labels.map((label, index) => (
                          <option key={index} value={label}>
                            {label}
                          </option>
                        ))}
                      </select>
                      <button
                        className="bg-[#ffffff] border text-black py-2 px-4 rounded ml-2 font-montserrat"
                        onClick={() => setIsAddingNewLabel(true)}
                      >
                        <Plus size={18} />
                      </button>
                      {isAddingNewLabel && (
                        <div className="fixed inset-0 flex items-center justify-center z-50">
                          <div className="absolute inset-0 bg-gray-800 opacity-50"></div>
                          <div className="bg-white rounded-lg p-8 z-10">
                            <h3 className="text-lg font-semibold mb-4 font-montserrat">Adicionar Novo Rótulo</h3>
                            <input
                              type="text"
                              className="border rounded p-2 w-full mb-4 font-montserrat"
                              placeholder="Novo Rótulo"
                              value={newLabel}
                              onChange={handleNewLabelChange}
                            />
                            <div className="flex justify-end">
                              <button
                                className="bg-gray-300 hover:bg-gray-400 text-black py-2 px-4 rounded mr-2 font-montserrat"
                                onClick={() => setIsAddingNewLabel(false)}
                              >
                                Cancelar
                              </button>
                              <button
                                className="bg-[#03989E] hover:bg-[#03989E]/90 text-white py-2 px-4 rounded font-montserrat"
                                onClick={handleAddNewLabel}
                              >
                                Adicionar
                              </button>
                            </div>
                          </div>
                        </div>
                      )}

                    </div>

                    {selectedLabel && selectedText && (
                      <button
                        className="bg-[#ffffff] border text-black py-1 px-2 rounded mt-3 font-montserrat"
                        onClick={handleAddAnnotation}
                      >
                        Adicionar
                      </button>
                    )}
                  </div>
                </div>
                <div className="flex justify-center mt-2">
                  <button
                    className={`bg-[#03989E] hover:bg-[#03989E]/90 text-white font-medium font-montserrat py-1 px-2 rounded ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{ marginRight: '10px' }}
                  >
                    Página Anterior
                  </button>
                  <button
                    className={`bg-[#03989E] hover:bg-[#03989E]/90 text-white font-medium font-montserrat py-1 px-2 rounded ${currentPage === response.files[selectedFileIndex].pages.length ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={handleNextPage}
                    disabled={currentPage === response.files[selectedFileIndex].pages.length}
                  >
                    Próxima Página
                  </button>
                </div>
                <div>
                  <h3 className="text-gray-600 mb-1 mt-6 font-montserrat">Anotações:</h3>
                  {annotations.map((annotation, index) => (
                    <div key={index} className="bg-gray-100 p-2 mt-2">
                      <p className="font-semibold font-montserrat">Rótulo: {annotation.label}</p>
                      <p className='font-montserrat'>Texto: {annotation.text}</p>
                    </div>
                  ))}
                  {annotations.length > 0 && (
                    <div className="flex justify-center">
                                         <button
                      className="bg-[#FBFCF7] hover:bg-[#ffffff]/90 text-[#03989E] border border-dotted font-bold font-montserrat py-1 px-4 rounded mt-4 flex justify-center"
                      onClick={handleDownloadExcel}
                    >
                      Download Excel
                    </button>
                    </div>

                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ExtractTextPdfPage;
