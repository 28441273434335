import { LandingNavbar } from "../components/Homepage/landing-navbar"
import PrivacyCookies from "../components/PrivacyCookies"

const PrivacyCookiesPage = () => {
    return (
    <main className="h-full bg-[#111827] overflow-auto">
        <div className="mx-auto max-w-screen-xl h-full w-full">
            <LandingNavbar />
            <PrivacyCookies />
        </div>
      </main>
    )
}

export default PrivacyCookiesPage