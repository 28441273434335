import { Routes, Route } from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes';
import Home from '../pages/Home';
import Login from '../components/AuthComponent/Login';
import Register from '../components/AuthComponent/Register';
import ExtractTextPdfPage from '../pages/ExtractTextPdfPage';
import ProcessExcelFilesPage from '../pages/ProcessExcelFilesPage';
import ExtractTextImagePage from '../pages/ExtractTextImagePage';
import DocumentsPage from '../pages/DocumentsPage';
import SheetPage from '../pages/SheetPage';
import PaymentPage from '../pages/PaymentPage';
import SettingsPage from '../pages/SettingsPage';

import { REACT_APP_STRIP_PUBLIC_KEY } from '../config/keys';
import AssistantsDocumentsPage from '../pages/AssistantsDocumentsPage';
import AssistantsSpreadsheetsPage from '../pages/AssistantsSpreadsheetsPage';
import SpreadsheetPage from '../pages/SpreadsheetPage';
import NewsPage from '../pages/NewsPage';
import CompanySearchPage from '../pages/CompanySearchPage';
import PersonalSearchPage from '../pages/PersonalSearchPage';
import InvoicePage from '../pages/InvoicePage';
import EnergyBillPage from '../pages/EnergyBillPage';
import KpisInvoicePage from '../pages/KpisInvoicesPage';
import LegalPage from '../pages/LegalPage';
import PrivacyChoicesPage from '../pages/PrivacyChoicesPage';
import PrivacyCookiesPage from '../pages/PrivacyCookies';
import SummarizationPage from '../pages/SummarizationPage';
import DeedPage from '../pages/DeedPage';
import WorkLawsPage from '../pages/WorkLawsPage';
import EmailPage from '../pages/EmailPage';
import LegalDocsPage from '../pages/LegalDocsPage';
import SubscriberNewsPage from '../pages/SubscriberNewsPage';
import SummarizationDocsPage from '../pages/SummarizationDocsPage';


const AppRoutes = () => (
    <Routes>
        <Route path="/" element={<PrivateRoutes />}>
            <Route path="/" element={<Home />} />
            <Route
                path="/process-files-excel"
                element={<ProcessExcelFilesPage />}
            />

            <Route
                path="/extract-text-image"
                element={<ExtractTextImagePage />}
            />
            <Route path="/extract-text-pdf" element={<ExtractTextPdfPage />} />
            <Route path="/sheet" element={<SheetPage />} />

            <Route path="/spreadsheet" element={<SpreadsheetPage />} />

            <Route
                path="/spreadsheet/:id"
                element={<AssistantsSpreadsheetsPage />}
            />
            <Route path="/document/:id" element={<AssistantsDocumentsPage />} />

            <Route path="/settings" element={<SettingsPage />} />

            <Route path="/company-search" element={<CompanySearchPage />} />

            <Route path="/personal-search" element={<PersonalSearchPage />} />
            <Route path="/invoice" element={<InvoicePage />} />
            <Route path="/kpis-invoices" element={<KpisInvoicePage />} />
            <Route path="/energy-bill" element={<EnergyBillPage />} />
            <Route path="/documents" element={<DocumentsPage />} />
            <Route path="/deed" element={<DeedPage />} />
            <Route path="/work-laws" element={<WorkLawsPage />} />
            <Route path="/email" element={<EmailPage />} />

            <Route path="/news" element={<NewsPage />} />
            <Route path="/subscriber-news" element={<SubscriberNewsPage />} />
            <Route path="/legal" element={<LegalPage />} />
            <Route path="/legal-docs/:companyId" element={<LegalDocsPage />} />
            <Route path="/summarization" element={<SummarizationPage />} />
            <Route path="/summarization-docs/:companyId" element={<SummarizationDocsPage />} />

            <Route path="/privacy-choices" element={<PrivacyChoicesPage />} />
            <Route path="/privacy-cookies" element={<PrivacyCookiesPage />} />


        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Register />} />
    </Routes>
);

export default AppRoutes;
