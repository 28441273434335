export const setUserInLocalStorageWithResponseData = (data: {
    token: string;
    _id: string;
    name: string;
}) => {
    const { token, _id } = data;
    setUserInLocalStorage(token, _id);
    return true;
};

export const getUserFromLocalStorage = () => {
    const token = localStorage.getItem('userLoginToken');
    const _id = localStorage.getItem('userId'); // Retrieve the _id as well

    if (token && _id) {
        // Both token and _id are available in local storage
        return { loginToken: token, userId: _id }; // Include _id in the returned object
    }

    return null;
};

export const setUserInLocalStorage = (token: string, _id: string) => {
    localStorage.setItem('userLoginToken', JSON.stringify(token));
    localStorage.setItem('userId', JSON.stringify(_id)); // Defina userId como _id
};

