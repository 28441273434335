import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Sheet, LayoutGrid, File, Newspaper } from 'lucide-react';
import { TbFileInvoice } from 'react-icons/tb';
import { SlEnergy } from 'react-icons/sl';
import { FaHouse } from 'react-icons/fa6';
import { GrUserWorker } from 'react-icons/gr';
import { IoIosBusiness } from 'react-icons/io';
import { cn } from '../../lib/utils';
import { FaRegIdCard, FaBalanceScale, FaTag } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { MdOutlineDocumentScanner } from 'react-icons/md';

import axios from 'axios';

import { REACT_APP_SERVER_URL } from '../../config/keys';

interface UserInfo {
    name: string;
    availableCredits: number;
}

const services = [
    {
        name: 'Notas Fiscais',
        description: 'Extraia e organize dados de notas fiscais.',
        icon: TbFileInvoice,
        color: 'text-[#EBA21C]',
        bgColor: 'bg-[#EBA21C]/10',
        route: '/invoice',
        category: 'Planilhas',
    },
    {
        name: 'Faturas de Energia',
        description: 'IA para análise de contas de energia.',
        icon: SlEnergy,
        color: 'text-red-500',
        bgColor: 'bg-red-500/10',
        route: '/energy-bill',
        category: 'Planilhas',
    },
    {
        name: 'Rotulagem',
        description: 'Treine assistente com dados qualificados.',
        icon: FaTag,
        color: 'text-[#F9F9F9]',
        bgColor: 'bg-[#F9F9F9]/10',
        route: '/documents',
        category: 'Planilhas',
    },
    {
        name: 'Monitor de CNPJ',
        description: 'Monitore dados de pessoas jurídicas.',
        icon: IoIosBusiness,
        color: 'text-blue-500',
        bgColor: 'bg-blue-500/10',
        route: '/company-search',
        category: 'Consultas RF',
    },
    {
        name: 'Monitor de CPF',
        description: 'Monitore dados de pessoas físicas.',
        icon: FaRegIdCard,
        color: 'text-pink-500',
        bgColor: 'bg-pink-500/10',
        route: '/personal-search',
        category: 'Consultas RF',
    },
    {
        name: 'Notícias',
        description: 'Busque e resuma notícias de forma descomplicada.',
        icon: Newspaper,
        color: 'text-amber-300',
        bgColor: 'bg-amber-300/10',
        route: '/news',
        category: 'Planilhas',
    },
    {
        name: 'Documentos',
        description:
            'Extraia resumos inteligentes de seus documentos com facilidade.',
        icon: MdOutlineDocumentScanner,
        color: 'text-cyan-500',
        bgColor: 'bg-cyan-500/10',
        route: '/summarization',
        category: 'Documentos',
    },
    {
        name: 'Escrituras de Imóveis',
        description: 'Analise de forma simplificada escrituras imobiliárias.',
        icon: FaHouse,
        color: 'text-emerald-500',
        bgColor: 'bg-emerald-500/10',
        route: '/deed',
        category: 'Documentos',
    },
    {
        name: 'Legislação Trabalhista',
        description: 'Acesse as leis trabalhistas de forma automatizada.',
        icon: GrUserWorker,
        color: 'text-orange-600',
        bgColor: 'bg-orange-600/10',
        route: '/work-laws',
        category: 'Documentos',
    },
    {
        name: 'Jurisprudência',
        description: 'Monitore jurisprudências de forma eficiente.',
        icon: FaBalanceScale,
        color: 'text-green-500',
        bgColor: 'bg-green-500/10',
        route: '/legal',
        category: 'Consultas RF',
    },
    {
        name: 'E-mails',
        description: 'Descubra insights poderosos em e-mails.',
        icon: MdEmail,
        color: 'text-amber-700',
        bgColor: 'bg-amber-700/10',
        route: '/email',
        category: 'Consultas RF',
    },
];

export const LandingContent = () => {
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get<UserInfo>(
                    `${REACT_APP_SERVER_URL}/api/users/infos/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    const updatedUserInfo: UserInfo = {
                        ...response.data,
                        availableCredits: Math.max(
                            0,
                            response.data.availableCredits
                        ),
                    };
                    setUserInfo(updatedUserInfo);
                })
                .catch(error => {
                    console.error(
                        'Erro ao buscar informações do usuário:',
                        error
                    );
                });
        }
    }, []);

    return (
        <div className="px-10 pb-20 -mt-32">
            <div className="mt-4 mb-6">
                <input
                    type="text"
                    id="search"
                    placeholder="Pesquisar Assistente..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    autoComplete="off"
                    className="w-full h-10 px-4 text-base placeholder-gray-400 bg-[#192339] border border-gray-600 rounded-md focus:outline-none focus:border-[#39A2A7] text-white"
                />
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {services
                    .filter(item =>
                        item.name
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                    )
                    .map(item => (
                        <Link
                            key={item.description}
                            to={item.route}
                            className={`hover:no-underline ${
                                userInfo && userInfo.availableCredits <= 0
                                    ? 'pointer-events-none opacity-50' // Adiciona essas classes se os créditos forem <= 0
                                    : ''
                            }`}
                        >
                            <div className="bg-[#192339] text-white rounded-lg p-6 cursor-pointer text-xl">
                                <div className="flex items-center">
                                    <div
                                        className={cn(
                                            'p-2 w-fit rounded-md',
                                            item.bgColor
                                        )}
                                    >
                                        {item.icon === Sheet && (
                                            <Sheet
                                                className={cn(
                                                    'w-8 h-8',
                                                    item.color
                                                )}
                                            />
                                        )}
                                        {item.icon === IoIosBusiness && (
                                            <IoIosBusiness
                                                className={cn(
                                                    'w-8 h-8',
                                                    item.color
                                                )}
                                            />
                                        )}
                                        {item.icon === FaRegIdCard && (
                                            <FaRegIdCard
                                                className={cn(
                                                    'w-8 h-8',
                                                    item.color
                                                )}
                                            />
                                        )}

                                        {item.icon === Newspaper && (
                                            <Newspaper
                                                className={cn(
                                                    'w-8 h-8',
                                                    item.color
                                                )}
                                            />
                                        )}
                                        {item.icon === File && (
                                            <File
                                                className={cn(
                                                    'w-8 h-8',
                                                    item.color
                                                )}
                                            />
                                        )}
                                        {item.icon === TbFileInvoice && (
                                            <TbFileInvoice
                                                className={cn(
                                                    'w-8 h-8',
                                                    item.color
                                                )}
                                            />
                                        )}
                                        {item.icon === SlEnergy && (
                                            <SlEnergy
                                                className={cn(
                                                    'w-8 h-8',
                                                    item.color
                                                )}
                                            />
                                        )}
                                        {item.icon === FaTag && (
                                            <FaTag
                                                className={cn(
                                                    'w-8 h-8',
                                                    item.color
                                                )}
                                            />
                                        )}
                                        {item.icon ===
                                            MdOutlineDocumentScanner && (
                                            <MdOutlineDocumentScanner
                                                className={cn(
                                                    'w-8 h-8',
                                                    item.color
                                                )}
                                            />
                                        )}
                                        {item.icon === FaHouse && (
                                            <FaHouse
                                                className={cn(
                                                    'w-8 h-8',
                                                    item.color
                                                )}
                                            />
                                        )}
                                        {item.icon === GrUserWorker && (
                                            <GrUserWorker
                                                className={cn(
                                                    'w-8 h-8',
                                                    item.color
                                                )}
                                            />
                                        )}
                                        {item.icon === FaBalanceScale && (
                                            <FaBalanceScale
                                                className={cn(
                                                    'w-8 h-8',
                                                    item.color
                                                )}
                                            />
                                        )}
                                        {item.icon === MdEmail && (
                                            <MdEmail
                                                className={cn(
                                                    'w-8 h-8',
                                                    item.color
                                                )}
                                            />
                                        )}
                                    </div>
                                    <div className="ml-2">
                                        <div className="font-semibold text-xl">
                                            {item.name}
                                        </div>
                                    </div>
                                </div>
                                <p className="pt-4 px-0 font-light text-lg -mt-2">
                                    {item.description}
                                </p>
                            </div>
                        </Link>
                    ))}
            </div>
        </div>
    );
};
