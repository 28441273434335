import React, { FormEvent, useState, ChangeEvent } from 'react';
import { setUserInLocalStorageWithResponseData } from '../../services';
import { createNewUser } from '../../api/users/authentication';
import { Link, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import TypewriterComponent from 'typewriter-effect';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import logoNeuronLab from '../../logo-neuronlab.png';

const Register = () => {
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [sector, setSector] = useState('');
    const [position, setPosition] = useState('');
    const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(prevShowPassword => !prevShowPassword);
    };

    const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        const enteredEmail = e.target.value;

        setEmail(enteredEmail);

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (enteredEmail.trim() === '') {
            setError(null);
        } else if (!emailRegex.test(enteredEmail)) {
            setError('Formato de e-mail inválido');
        } else {
            setError(null);
        }
    };

    const arePasswordsEqual = (password1: string, password2: string) => {
        return password1 === password2;
    };

    const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
    };

    const onPassword2Change = (e: ChangeEvent<HTMLInputElement>) => {
        const newPassword2 = e.target.value;
        setPassword2(newPassword2);

        if (!arePasswordsEqual(password, newPassword2)) {
            setError('As senhas não coincidem');
        } else {
            setError(null);
        }
    };

    const CNPJ_REGEX = /^(\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}|\d{14})$/;

    const isCnpjValid = (cnpj: string) => {
        return CNPJ_REGEX.test(cnpj);
    };

    const onCnpjChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newCnpj = e.target.value;
        setCnpj(newCnpj);

        if (!isCnpjValid(newCnpj)) {
            setError('Formato de CNPJ inválido');
        } else {
            setError(null);
        }
    };

    const onSectorChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newSector = e.target.value;
        setSector(newSector);

        if (newSector.trim() === '') {
            setError('Por favor, insira um setor válido');
        } else {
            setError(null);
        }
    };

    const onPositionChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newJobTitle = e.target.value;
        setPosition(newJobTitle);

        if (newJobTitle.trim() === '') {
            setError('Por favor, insira um cargo válido');
        } else {
            setError(null);
        }
    };

    const onRecaptchaChange = (value: string | null) => {
        setRecaptchaValue(value);
    };

    const onFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (
            name.trim() === '' ||
            email.trim() === '' ||
            password.trim() === '' ||
            password2.trim() === '' ||
            cnpj.trim() === '' ||
            sector.trim() === '' ||
            position.trim() === ''
        ) {
            setError('Por favor, preencha todos os campos!');
        } else if (!isValidEmail(email)) {
            setError('Por favor, insira um endereço de email válido!');
        } else if (!isCnpjValid(cnpj)) {
            setError('Formato de CNPJ inválido');
        } else if (sector.trim() === '') {
            setError('Por favor, insira um setor válido');
        } else if (position.trim() === '') {
            setError('Por favor, insira um cargo válido');
        } else if (!arePasswordsEqual(password, password2)) {
            setError('As senhas não coincidem');
        } else if (!recaptchaValue) {
            setError('Por favor, preencha o ReCAPTCHA.');
        } else {
            try {
                const response = await createNewUser({
                    name,
                    email,
                    password,
                    password2,
                    cnpj,
                    sector,
                    position,
                });

                if (response) {
                    setUserInLocalStorageWithResponseData(response.data);
                    navigate('/');
                }
            } catch (err) {
                if (err instanceof AxiosError && err.response) {
                    setError(err.response.data.error);
                }

                if (err instanceof Error) {
                    setError(err.message);
                }
            }
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-[#111827]">
            <div className="text-white font-bold py-20 text-left space-y-5 -mt-10 ml-10">
                <img
                    src={logoNeuronLab}
                    alt="Descrição da imagem"
                    className="h-200 w-60 object-cover mb-5"
                />
                <div className="text-xl sm:text-xl md:text-xl lg:text-7xl space-y-5 font-extrabold mt-7">
                    <h1>
                        Assistentes de IA para
                        <span>
                            <div
                                className="gradient-container mt-2 text-black"
                                style={{
                                    background:
                                        'linear-gradient(to right, #7ad9de, #03989E)',
                                    display: 'inline-block',
                                    padding: '5px',
                                    borderRadius: '4px',
                                    marginLeft: '10px',
                                }}
                            >
                                <TypewriterComponent
                                    options={{
                                        strings: [
                                            'Classificar.',
                                            'Analisar.',
                                            'Extrair.',
                                            'Contar.',
                                            'Medir.',
                                            'Detectar.',
                                            'Otimizar.',
                                            'Agrupar.',
                                            'Alertar.',
                                        ],
                                        autoStart: true,
                                        loop: true,
                                    }}
                                />
                            </div>
                        </span>
                    </h1>
                </div>
            </div>
            <div className="w-full max-w-2xl p-8 bg-[#192339] rounded-lg shadow-lg ml-14 mb-14 mt-14 mr-10">
                <h1 className="text-6xl font-semibold mb-6 text-[#B3D8D9] font-montserrat">
                    Crie sua conta!
                </h1>
                {error && (
                    <p className="bg-red-400 text-white px-4 py-2 rounded mt-1 mb-4">
                        {error}
                    </p>
                )}
                <form
                    onSubmit={onFormSubmit}
                    className="grid grid-cols-2 gap-4"
                >
                    <div>
                        <input
                            type="text"
                            placeholder="Nome Completo"
                            onChange={onNameChange}
                            className="bg-[#111827] block rounded-md border-[#192339] border-b-2 w-full text-xl text-white py-4 px-2 font-montserrat outline-none"
                        />
                        <input
                            type="email"
                            placeholder="Email"
                            onChange={onEmailChange}
                            className="bg-[#111827] block rounded-md border-[#192339] border-b-2 w-full text-xl text-white py-4 px-2 font-montserrat outline-none mt-4"
                        />
                        <input
                            type="text"
                            placeholder="CNPJ da Empresa"
                            onChange={onCnpjChange}
                            className="bg-[#111827] block rounded-md border-[#192339] border-b-2 w-full text-xl text-white py-4 px-2 font-montserrat outline-none mt-4"
                        />
                        <input
                            type="text"
                            placeholder="Setor da Empresa"
                            onChange={onSectorChange}
                            className="bg-[#111827] block rounded-md border-[#192339] border-b-2 w-full text-xl text-white py-4 px-2 font-montserrat outline-none mt-4"
                            autoComplete="off"
                        />
                        <input
                            type="text"
                            placeholder="Cargo que Ocupa"
                            onChange={onPositionChange}
                            className="bg-[#111827] block rounded-md border-[#192339] border-b-2 w-full text-xl text-white py-4 px-2 font-montserrat outline-none mt-4"
                            autoComplete="off"
                        />
                    </div>
                    <div>
                        <div className="relative w-full">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Senha"
                                onChange={onPasswordChange}
                                className="bg-[#111827] block rounded-md border-[#192339] border-b-2 w-full text-xl text-white py-4 px-2 font-montserrat outline-none"
                            />
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="absolute top-1/2 right-2 transform -translate-y-1/2 text-white text-sm mt-2 cursor-pointer"
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}{' '}
                            </button>
                        </div>
                        <div className="relative w-full">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Confirmar Senha"
                                onChange={onPassword2Change}
                                className="bg-[#111827] block rounded-md border-[#192339] border-b-2 w-full text-xl text-white py-4 px-2 font-montserrat outline-none mt-4"
                            />
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="absolute top-1/2 right-2 transform -translate-y-1/2 text-white text-sm mt-4 cursor-pointer"
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}{' '}
                            </button>
                        </div>
                        <ReCAPTCHA
                            className="mt-6"
                            sitekey="6Ld39SwpAAAAAIImNGHiWCjHd3lyUTbiQ9cBAXQf"
                            onChange={onRecaptchaChange}
                        />
                        <div className="flex items-center mt-10">
                            <input
                                type="submit"
                                value="Cadastrar"
                                className="bg-[#03989E] text-white py-6 px-8 rounded-lg font-bold cursor-pointer transition duration-300 hover:bg-[#047B84]"
                            />
                            <div className="ml-4">
                                <span className="text-white font-montserrat">
                                    Já tem conta?
                                </span>
                                <Link
                                    to="/login"
                                    className="ml-0 text-emerald-500 font-montserrat hover:underline"
                                >
                                    Faça login!
                                </Link>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Register;
