import React, { useEffect, useState, FC, ChangeEvent } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { HeadingAssistants } from './Global/HeadingAssistants';
import { Brain, Plus } from 'lucide-react';

import { REACT_APP_SERVER_URL, REACT_APP_API_URL_MS_DOCUMENT, REACT_APP_API_URL_MS_SPREADSHEET } from '../config/keys';
import { getUserFromLocalStorage } from "../services";
import InputDialogPopup from './Global/InputDialogPopup';

import {TagPopup} from './TagPopup';


interface SpreadsheetDetails {
  category: string;
  _id: string;
  author_id: string;
  instruction: string;
  data: [string, string][];
  date: string;
  __v: number;
}

interface PDFResponse {
  files: Array<{
    file_name: string;
    pages: Array<{
      page: number;
      text: string;
    }>;
    most_common_words: string[];
  }>;
  total_pages: number; // Adicionar total_pages à interface PDFResponse
}


interface UserInfo {
  userId: string; // Certifique-se de ter o campo userId na interface UserInfo
  name: string;
  availableCredits: number;
}

interface Annotation {
  label: string;
  text: string;
}

interface TagToken {
  tag: string;
  token: string;
}

const SpreadsheetsDetails = () => {
  const { id } = useParams();
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [documentDetails, setDocumentDetails] = useState<SpreadsheetDetails | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const desiredPlaceholder = userInfo ? `Olá, ${userInfo.name}. Agora que fui treinada, faça o upload dos arquivos XLSX (Planilha) para que eu possa te ajudar.` : "Qual XLSX (Planilha) você quer que eu analise?";
  const [placeholderText, setPlaceholderText] = useState("");
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [selectedFileIndex, setSelectedFileIndex] = useState(-1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPDFs, setSelectedPDFs] = useState<string[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<PDFResponse | null>(null);
  const [isUploadSuccessful, setIsUploadSuccessful] = useState(false);
  const [selectedText, setSelectedText] = useState<string>('');
  const [annotations, setAnnotations] = useState<Annotation[]>([]);
  const [processedData, setProcessedData] = useState<any[]>([]);
  const [openTagPopup, setOpenTagPopup] = useState<boolean>(false)

  const [tagTokens, setTagTokens] = useState<TagToken[]>([])
  const [selectedTextToken, setSelectedTextToken] = useState<string>("")
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${REACT_APP_SERVER_URL}/api/spreadsheet/get-project/${id}`);
        setDocumentDetails(response.data);
      } catch (error) {
        console.error('Error fetching document details:', error);
      }
    };

    fetchData();
  }, [id]);

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      setTimeout(() => {
        console.log("Prompt submitted:", prompt);
        setIsLoading(false);
      }, 2000);
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const placeholderInterval = setInterval(() => {
      if (placeholderIndex < desiredPlaceholder.length) {
        setPlaceholderText(desiredPlaceholder.slice(0, placeholderIndex + 1));
        setPlaceholderIndex(placeholderIndex + 1);
      } else {
        clearInterval(placeholderInterval);
        setIsButtonVisible(true);
      }
    }, 50);

    return () => {
      clearInterval(placeholderInterval);
    };
  }, [desiredPlaceholder, placeholderIndex]);

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');

    if (storedUserId) {
      axios
        .get<UserInfo>(`${REACT_APP_SERVER_URL}/api/users/infos/${JSON.parse(storedUserId)}`)
        .then((response) => {
          setUserInfo(response.data);
        })
        .catch((error) => {
          console.error('Erro ao buscar informações do usuário:', error);
        });
    }
  }, []);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setSelectedFiles(Array.from(files));
    }
  };

  const handleUpload = async () => {
    const endpoint = `${REACT_APP_API_URL_MS_SPREADSHEET}/api/upload-excel/`;
    const token = 'f32x1v3mYTI7HzPwjqsq5FyINwNhxRugFHf6A8z61dE';

    const formData = new FormData();
    selectedFiles && selectedFiles.forEach((file) => {
      formData.append('files', file);
    });

    const user = getUserFromLocalStorage();

    if (user && user.userId) {
      const userId = user.userId;

      if (!selectedFiles) return;

      const isValidFiles = Array.from(selectedFiles).every((file) =>
        file.type === 'xlsx' ||
        file.type === 'xls'
      );


      const formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append('files', selectedFiles[i], selectedFiles[i].name);
      }

      const headers = new Headers();
      headers.append(
        'Authorization',
        'Bearer f32x1v3mYTI7HzPwjqsq5FyINwNhxRugFHf6A8z61dE'
      );

      setLoading(true);

      try {
        const response = await axios.post(endpoint, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });

        console.log(response.data.data)
        console.log(documentDetails?._id)

        if (response.data.data) {
          setProcessedData(response.data.data);

          if (documentDetails) {
            const postUrl = `${REACT_APP_SERVER_URL}/api/spreadsheet/project/${documentDetails._id}`;
            const requestBody = {
              data: response.data.data
            };

            const headers = {
              Authorization: 'f32x1v3mYTI7HzPwjqsq5FyINwNhxRugFHf6A8z61dE'
            };

            try {
              const postResponse = await axios.post(postUrl, requestBody, { headers });

              console.log('POST request successful:', postResponse.data);
              console.log('Classifier:', postResponse.data?.classifierTagsResponse);

              if (postResponse && postResponse.data && postResponse.data.classifierTagsResponse) {
                const tgTk: TagToken[] = postResponse.data.classifierTagsResponse.similar_terms.map((st: any) => ({
                  tag: st.tag,
                  token: st.token
                }))
                setTagTokens(tgTk)
                setOpenTagPopup(true)
              }

            } catch (e) {
              console.log(e)
            }
          }

        }

      } catch (error) {
        console.error('Erro ao processar a solicitação', error);
      } finally {
        setLoading(false);
      }
    } else {
      console.error('userId não encontrado no localStorage');
    }
  };

  const handleAddTagToken = async (tag: string) => {
    setTagTokens(prev => {
      return [...prev, {
        tag: tag,
        token: selectedTextToken
      }]
    })

    if (documentDetails) {
      const documentId = documentDetails._id

      const updateResponse = await fetch(
        `${REACT_APP_SERVER_URL}/api/documents/insert-data/${documentId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            tag: tag,
            token: selectedTextToken
          }),
        }
      );

      console.log(updateResponse)

    }

    setSelectedTextToken("")

  }

  return (
    <>
      <div className="min-h-screen bg-[#111827] overflow-auto">
        <div className="mx-auto max-w-screen-xl h-full w-full">
          <div className="mt-1 mb-8 -ml-6">
            <div className="mb-12 ml-6 mt-14">
              <Link to="/assistants" className="text-white hover:text-[#57B4B9] transition duration-300 flex items-center font-montserrat font-bold text-sm">
                <span className="mr-1">&#8592;</span> Voltar
              </Link>
            </div>
            <div>
            </div>
            {documentDetails && (
              <HeadingAssistants
                title={`Assistente para ${documentDetails.instruction}`} // Use "Assistente para" + documentDetails.instruction as the title
                icon={Brain}
                iconColor="text-white"
                bgColor="bg-[#03989E]/50"
              />
            )}
          </div>
          <div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
              className="rounded-lg border w-full p-4 px-3 md:px-6 focus-within:shadow-sm grid grid-cols-12 gap-2"
            >
              <div className="col-span-12 lg:col-span-10 mt-4 mb-4 flex items-center">
                <div className="m-0 p-0 flex-grow">
                  <input
                    type="text"
                    className="border-0 outline-none focus-visible:ring-0 focus-visible:ring-transparent w-full p-[10px] bg-transparent placeholder-white font-montserrat text-base"
                    readOnly
                    placeholder={isUploadComplete ? `${userInfo ? `${userInfo.name}, já recebi os arquivos PDFs. Qual você quer que eu analise?` : ""}` : placeholderText}
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                  />
                </div>
                {isUploadComplete && (
                  <div className="ml-4">
                    <select
                      className="mb-4 border rounded p-2 w-[250px] mt-2 font-montserrat"
                      value={selectedFileIndex}
                      onChange={(e) => {
                        setSelectedFileIndex(Number(e.target.value));
                        setCurrentPage(1);
                      }}
                    >
                      <option className='font-montserrat' value={-1}>Selecione o PDF</option>
                      {selectedPDFs.map((pdfName, index) => (
                        <option key={index} value={index}>
                          {pdfName}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
              {!isUploadComplete && isButtonVisible && (
                <div className="col-span-12 lg:col-span-2 mt-4 ml-6 flex space-x-2">
                  <input
                    type="file"
                    accept=".xlsx, xls"
                    multiple
                    className="hidden"
                    onChange={handleFileChange}
                    id="fileInput"
                  />
                  <label
                    htmlFor="fileInput"
                    className="w-50 h-11 bg-[#03989E] hover:bg-[#03989E]/70 text-white text-base font-semibold rounded-lg p-2 cursor-pointer font-montserrat"
                  >
                    Selecionar
                  </label>
                  {selectedFiles && selectedFiles.length > 0 && (
                    <button
                      type="submit"
                      className="w-34 h-11 bg-white hover:bg-gray-100 text-[#03989E] text-base font-semibold rounded-lg p-2 cursor-pointer font-montserrat"
                      onClick={handleUpload}
                    >
                      {loading ? (
                        <svg className="animate-spin h-5 w-5 mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018 8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      ) : (
                        'Enviar'
                      )}
                    </button>
                  )}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
      <InputDialogPopup
        open={isPopupOpen}
        title="Adicionar novo Token"
        inputLabel="Nome da Tag/Rótulo:"
        content={`Informe a tag correspondente ao token: ${selectedTextToken}`}
        onSubmit={handleAddTagToken}
        handleClose={() => {
          setIsPopupOpen(false)
          setSelectedTextToken("")
        }}
      />
      {openTagPopup && <TagPopup
        open={openTagPopup}
        setOpen={setOpenTagPopup}
        processedData={processedData}
        tagTokens={tagTokens}
        setTagTokens={setTagTokens}
        update={true}
      />}

    </>
  );
};

type PropsHT = {
  token: string;
  tag: string;
}

const HighlightedToken: React.FC<PropsHT> = (props: PropsHT) => {
  return <span style={
    {
      position: 'relative',
      backgroundColor: 'rgb(200, 255, 240)',
      padding: '4px',
      paddingTop: '6px',
      borderRadius: '5px',


    }}>
    {props.token}
    <span style={{
      position: 'absolute',
      fontSize: '10px',
      top: '-6px',
      left: '4px',
      textAlign: 'center',
      width: `${props.tag.length * 9}px`,
      padding: '2px',
      height: '12px',
      fontWeight: 'normal',
      color: 'white',
      textTransform: 'uppercase',
      backgroundColor: '#0b5C66',
      lineHeight: '8px',
      borderRadius: '2px'


    }}>
      {props.tag}
    </span>
  </span>
}

const TextParagraph = (response: PDFResponse, selectedFileIndex: number, currentPage: number, tagTokens: TagToken[], setSelectedTextToken: React.Dispatch<React.SetStateAction<string>>) => {

  const text = response.files[selectedFileIndex].pages[currentPage - 1].text;
  let textSplitted: string[] = [text];


  tagTokens.forEach(tt => {
    let newTextSplitted: string[] = []
    const token = tt.token
    textSplitted.forEach((pos: string) => {
      const internalSplit: string[] = pos.split(new RegExp("(" + token + ")"))
      newTextSplitted = [...newTextSplitted, ...internalSplit]
    })
    textSplitted = [...newTextSplitted]
  })

  const textSplittedFiltered: string[] = textSplitted.filter(pos => pos.length !== 0)
  console.log(tagTokens)


  return (
    <p
      style={{
        marginTop: '10px',
        marginBottom: '10px',
        fontSize: '16px',
        lineHeight: '30px'
      }}

      onMouseLeave={
        () => {
          if (!window.getSelection()?.toString()) {
            setSelectedTextToken("")
          }
        }
      }

    >{textSplittedFiltered.map((textPart: string, i: number) => {
      if (tagTokens.map((tt) => tt.token).includes(textPart)) {
        const tag = tagTokens.filter(tt => tt.token == textPart)[0].tag
        return (
          <HighlightedToken key={i} token={textPart} tag={tag} />
        )
      } else {
        return (
          <span
            key={i}
            onMouseUp={
              () => {
                const selectedText: string = window.getSelection()?.toString() || ""
                setSelectedTextToken(selectedText)
              }

            }
          >


            {textPart}
          </span>
        )
      }
    })}</p>
  )

}



export default SpreadsheetsDetails;
