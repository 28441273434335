import React from 'react'
import { LandingNavbar } from "../components/Homepage/landing-navbar"
import Invoice from '../components/Invoice'

const InvoicePage = () => {
    return (
        <main className="h-full bg-[#111827] overflow-auto">
            <div className="mx-auto max-w-screen-xl h-full w-full">
                <LandingNavbar />
                <Invoice />
            </div>
          </main>
    )
}

export default InvoicePage