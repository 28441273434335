import {
    FormGroup,
    FormControlLabel,
    Checkbox,
    TextField,
    Typography,
    Radio,
    RadioGroup,
    Button
} from "@mui/material"
import { ArrowLeftCircle } from "lucide-react"
import { TableState } from "./localTypes"

export default (props: any) => {

    const radioButtonsCfg = [
        {
            text: " Associar rótulo a partir de outra célula",
            value: "other",
            name: "rb"
        },
        {
            text: " Adicionar rótulos em lote",
            value: "bulk",
            name: "rb"
        },
        {
            text: " Inserir rótulo manualmente",
            value: "type",
            name: "rb"
        }
    ]

    const message =
        props.tableState === TableState.WAITING_SELECT ? "Selecione uma célula para ser rotulada"
            : props.tableState === TableState.PRE_SETTINGS ? "Escolha uma das opções abaixo"
                : props.tableState === TableState.WAITING_TAG_CELL ? "Selecione o rótulo"
                    : props.tableState === TableState.WAITING_TYPE_TAG ? "Digite o nome do rótulo na caixa de texto"
                        : props.tableState === TableState.WAITING_BULK_TAG ? "Selecione uma das opções de Rotulagem em Lote"
                            : "NADA"

    return (
        <div className="w-full h-full bg-gray-100 p-8 flex flex-col">
            <FormGroup>
                <label className="text-sm font-montserrat">
                    <input
                        checked={props.firstRowHeader}
                        type="checkbox"
                        onChange={() => props.setFirstRowHeader(!props.firstRowHeader)}
                    />
                    {" Cabeçalho na Primeira Linha"}
                </label>
                <br />

                <div className="flex flex-row h-10 items-center text-gray-800">
                    <ArrowLeftCircle size={18} className={`${props.tableState === TableState.PRE_SETTINGS || props.tableState === TableState.WAITING_BULK_TAG ? "-rotate-90" : (props.tableState === TableState.WAITING_TYPE_TAG ? "-rotate-45" : "")} transition-all`} />
                    <p className="text-sm font-montserrat animate-pulse ml-1">
                        {message}
                    </p>
                </div>


                <br />

                {
                    radioButtonsCfg.map((rb, idx) =>
                        <label key={idx} className="text-sm font-montserrat">
                            <input
                                type="radio"
                                value={rb.value}
                                name={rb.name}
                                onChange={() => props.setSettingsOptions(rb.value)}
                                checked={props.settingsOptions === rb.value}
                            />
                            {rb.text}
                        </label>
                    )}

                <br />

                {props.tableState === TableState.WAITING_BULK_TAG && <>
                    <p className="text-sm font-montserrat animate-pulse mb-2 ml">
                        Opções de Rotulagem
                    </p>
                    <label className="text-sm font-montserrat">
                        <input
                            type="radio"
                            value={"colheader"}
                            name={"bulktype"}
                            disabled={!props.firstRowHeader}
                            checked={props.bulkOptions == "colheader"}
                            onChange={() => props.setBulkOptions("colheader")}
                        />
                        {" Utilizar cabeçalho da coluna e células restantes como Tokens"}
                    </label>
                    <label className="text-sm font-montserrat">
                        <input
                            type="radio"
                            value={"coltype"}
                            name={"bulktype"}
                            checked={props.bulkOptions == "coltype"}
                            onChange={() => props.setBulkOptions("coltype")}
                        />
                        {" Definir rótulo para toda uma coluna"}
                    </label>
                    <label className="text-sm font-montserrat">
                        <input
                            type="radio"
                            value={"colpair"}
                            name={"bulktype"}
                            checked={props.bulkOptions == "colpair"}
                            onChange={() => props.setBulkOptions("colpair")}
                        />
                        {" Associar células de duas colunas como pares Tag/Token"}
                    </label>
                    {props.bulkOptions && <div className="w-full flex flex-row justify-end">
                        <button
                            className="flex flex-col items-center px-4 py-2 text-xs w-24 bg-[#03989E] text-white rounded-lg hover:bg-[#03989E]/90 transition duration-300"
                            onClick={() => {
                                props.openBulkPopup()
                            }}
                        >
                            Prosseguir
                        </button>
                    </div>}
                </>}

                {<button
                    className="flex flex-col absolute bottom-4 right-4 items-center px-4 py-2 text-xs w-24 bg-[#03989E] text-white rounded-lg hover:bg-[#03989E]/90 transition duration-300"
                    onClick={() => {
                        if(props.update){
                            props.handleSave()
                        }else{
                            props.setIsCreateProjectPopupOpen(true)
                        }
                        
                    }}
                >
                    {props.update?"Atualizar":"Salvar"}
                </button> } 
                <button
                    className="flex flex-col absolute bottom-14 right-4 items-center px-4 py-2 text-xs w-24 bg-[#FAFAFA] hover:bg-slate-200 text-gray-600 rounded-lg transition duration-300"
                    onClick={() => {
                        props.handleDownload()
                    }}
                >
                    Download
                </button> 

            </FormGroup>
        </div>
    )
}