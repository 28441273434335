
enum TableState {
    PRE_SETTINGS,
    WAITING_SELECT,
    WAITING_TAG_CELL,
    WAITING_TYPE_TAG,
    WAITING_BULK_TAG
}


export {TableState}