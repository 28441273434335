import React, { useState, useEffect } from "react";
import axios from "axios";
import { Heading } from "../components/Global/Heading";
import { File, Plus } from 'lucide-react';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { AiFillTags } from 'react-icons/ai'
import { BiDetail } from 'react-icons/bi'
import { MdOutlineQuestionAnswer } from  'react-icons/md'

import { REACT_APP_SERVER_URL, REACT_APP_API_URL_MS_DOCUMENT } from '../config/keys';
import { getUserFromLocalStorage } from "../services";
import InputDialogPopup from "./Global/InputDialogPopup";

interface UserInfo {
  userId: string; // Certifique-se de ter o campo userId na interface UserInfo
  name: string;
  availableCredits: number;
}

interface Tool {
  name: string;
  icon: JSX.Element; // SVG icon represented as JSX
  creditsRequired: number;
  onClick: () => void; // Function to handle when the button is clicked
}

interface PDFResponse {
  files: Array<{
    file_name: string;
    pages: Array<{
      page: number;
      text: string;
    }>;
    most_common_words: string[];
  }>;
  total_pages: number; // Adicionar total_pages à interface PDFResponse
}

interface Annotation {
  label: string;
  text: string;
}

const Documents: React.FC = () => {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [placeholderText, setPlaceholderText] = useState("");
  const desiredPlaceholder = userInfo ? `Olá, ${userInfo.name}. Faça o upload dos arquivos PDF.` : "Qual PDF você quer que eu analise?";
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [response, setResponse] = useState<PDFResponse | null>(null);
  const [selectedFileIndex, setSelectedFileIndex] = useState(-1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isUploadSuccessful, setIsUploadSuccessful] = useState(false);
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [selectedPDFs, setSelectedPDFs] = useState<string[]>([]);
  const [selectedText, setSelectedText] = useState<string>('');
  const [selectedLabel, setSelectedLabel] = useState<string | null>(null);
  const [annotations, setAnnotations] = useState<Annotation[]>([]);
  const [labels, setLabels] = useState<string[]>(["Importante", "Destaque", "Observação"]);
  const [newLabel, setNewLabel] = useState<string>("");
  const [isAddingNewLabel, setIsAddingNewLabel] = useState(false);
  const [hasAnnotations, setHasAnnotations] = useState(false);
  const [isRotularTextoSelected, setIsRotularTextoSelected] = useState(false);


  const tools: Tool[] = [
    {
        name: 'Rotular',
        icon: <AiFillTags />,
        creditsRequired: 0,
        onClick: () => {
          // Logic to apply Tool 2
        },
      },
      
    {
      name: 'Resumir',
      icon: <BiDetail />,
      creditsRequired: 2,
      onClick: () => {
        // Logic to apply Tool 2
      },
    },
    {
      name: 'Perguntar',
      icon: <MdOutlineQuestionAnswer />,
      creditsRequired: 2,
      onClick: () => {
        // Logic to apply Tool 3
      },
    },
  ];

  useEffect(() => {
    const placeholderInterval = setInterval(() => {
      if (placeholderIndex < desiredPlaceholder.length) {
        setPlaceholderText(desiredPlaceholder.slice(0, placeholderIndex + 1));
        setPlaceholderIndex(placeholderIndex + 1);
      } else {
        clearInterval(placeholderInterval);
        setIsButtonVisible(true);
      }
    }, 50);

    return () => {
      clearInterval(placeholderInterval);
    };
  }, [desiredPlaceholder, placeholderIndex]);

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');

    if (storedUserId) {
      axios
        .get<UserInfo>(`${REACT_APP_SERVER_URL}/api/users/infos/${JSON.parse(storedUserId)}`)
        .then((response) => {
          setUserInfo(response.data);
        })
        .catch((error) => {
          console.error('Erro ao buscar informações do usuário:', error);
        });
    }
  }, []);

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      setTimeout(() => {
        console.log("Prompt submitted:", prompt);
        setIsLoading(false);
      }, 2000);
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFiles(event.target.files);
    }
  };

  const handleUpload = async () => {
    const user = getUserFromLocalStorage();
  
    if (user && user.userId) {
      const userId = user.userId;
  
      if (!selectedFiles) return;
  
      const isValidFiles = Array.from(selectedFiles).every(file =>
        file.type === 'application/pdf'
      );
  
      if (!isValidFiles) {
        alert('Por favor, adicione apenas arquivos PDF.');
        return;
      }
  
      const formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append('files', selectedFiles[i], selectedFiles[i].name);
      }
  
      const headers = new Headers();
      headers.append('Authorization', 'Bearer 7XT6jpcILSEMv-qN8r_sH0z76dTF1r_g7uEvXa__BMo');
  
      setLoading(true);
  
      try {
        const response = await fetch(`${REACT_APP_API_URL_MS_DOCUMENT}/api/extract-text-pdf`, {
          method: 'POST',
          headers: headers,
          body: formData,
          mode: 'cors',
        });
  
        if (response.ok) {
          const responseData = await response.json();
          setResponse(responseData);
          setSelectedFileIndex(-1);
          setCurrentPage(1);
          setIsUploadSuccessful(true);
          setIsUploadComplete(true);
  
          const selectedPDFNames = Array.from(selectedFiles).map(file => file.name);
          setSelectedPDFs(selectedPDFNames);
  
          const userIdWithoutQuotes = userId.replace(/^"(.*)"$/, '$1');
  
          const total_pages = responseData.total_pages;
          
          console.log(responseData)
          console.log(total_pages)

          try {
            const creditHistoryPayload = {
              creditsUsed: total_pages
            };
          
            const creditHistoryResponse = await axios.post(`${REACT_APP_SERVER_URL}/api/users/credit-history/${userIdWithoutQuotes}`, creditHistoryPayload);
            
            if (creditHistoryResponse && creditHistoryResponse.data) {
              console.log('Histórico de créditos atualizado com sucesso:', creditHistoryResponse.data);
            } else {
              console.error('Erro ao atualizar o histórico de créditos do usuário');
            }
          } catch (error) {
            console.error('Erro ao fazer a requisição de atualização de histórico de créditos:', error);
          }

  
          const updateResponse = await fetch(`${REACT_APP_SERVER_URL}/api/users/update-credits/${userIdWithoutQuotes}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json', 
            },
            body: JSON.stringify({
              decrementAmount: total_pages,
            }),
          });
  
          if (updateResponse.ok) {
            console.log('Créditos atualizados com sucesso');
          } else {
            console.error('Erro ao atualizar créditos do usuário');
          }
        } else {
          console.error('Erro no envio do PDF');
        }
      } catch (error) {
        console.error('Erro ao processar a solicitação', error);
      } finally {
        setLoading(false);
      }
    } else {
      console.error('userId não encontrado no localStorage');
    }
  };

  const [isTyping, setIsTyping] = useState(true);
  const [currentPrompt, setCurrentPrompt] = useState("");
  const typingSpeed = 100; // Velocidade de digitação em milissegundos por caractere

  const[isPopupOpen, setIsPopupOpen] = useState(false);


  useEffect(() => {
    if (isTyping) {
      const promptText = isUploadComplete
        ? `${userInfo ? `${userInfo.name}, já recebi os arquivos PDFs. Qual você quer que eu analise?` : ""}`
        : placeholderText;

      const type = () => {
        if (currentPrompt.length < promptText.length) {
          setCurrentPrompt(promptText.slice(0, currentPrompt.length + 1));
          setTimeout(type, typingSpeed);
        } else {
          setIsTyping(false);
        }
      };

      type();
    }
  }, [isTyping, isUploadComplete, userInfo, placeholderText, currentPrompt]);

  const handleTextSelection = () => {
    const selectedText = window.getSelection()?.toString();
    setSelectedText(selectedText || '');
  };

  const handleAddAnnotation = () => {
    if (selectedLabel && selectedText) {
      const newAnnotation = { label: selectedLabel, text: selectedText };
      setAnnotations([...annotations, newAnnotation]);
      setSelectedText('');
      setSelectedLabel(null);
      setHasAnnotations(true);
    }
  };
  

  const handleNewLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewLabel(event.target.value);
  };

  const handleAddNewLabel = () => {
    if (newLabel.trim() !== "") {
      setLabels([...labels, newLabel]);
      setSelectedLabel(newLabel);
      setNewLabel("");
      setIsAddingNewLabel(false); // Fechar o popup
    }
  };

  const applyAnnotations = (text: string) => {
    const formattedText = annotations.reduce((acc, annotation) => {
      if (annotation.label === "Importante") {
        return acc.replace(new RegExp(annotation.text, 'g'), `<mark>${annotation.text}</mark>`);
      }
      return acc;
    }, text);

    return { __html: formattedText };
  };

  const handleDownloadExcel = () => {
    const rows = annotations.map((annotation) => ({
      Rotulo: annotation.label,
      Texto: annotation.text,
    }));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(rows);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Anotacoes');

    XLSX.writeFile(workbook, 'Anotacoes.xlsx', { compression: true });
  };

  const openCreateProjectPopup = () => {
    if (annotations.length > 0) {
      setIsPopupOpen(true);
    }else{
      console.error("Nenhuma anotação para treinar o assistente.");
    }
  }
  
  const handleTrainAssistant = async (projectName : string) => {
    if (annotations.length > 0) {
      // Lógica para mostrar o popup e obter o nome do projeto do usuário
     // const projectName = window.prompt("Digite o nome do projeto:");
  
      if (projectName) {
        const formattedAnnotations = annotations.map((annotation) => [
          annotation.text,
          annotation.label,
        ]);
  
        const user = getUserFromLocalStorage();
        const userId = user?.userId?.replace(/^"(.*)"$/, '$1');
  
        if (!userId) {
          console.error("UserId não encontrado no localStorage.");
          return;
        }
  

        try {
          const response = await fetch(`${REACT_APP_SERVER_URL}/api/documents/create-project`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY0Zjc5MzBmY2RiZDVmYjZiYmD1MDcyMSIsIm5hbWUiOiJOb21lIGRvIFVzdcOhcmlvIiwiYXZhaWxhYmxlQ3JlZGl0cyI6NTAsImlhdCI6MTY5NDA2MTgwNiwiZXhwIjoxNzI1NjE4NzMyfQ._fssHXDNRo-b7YteXWawDU5ycXH-kmWFn2IlKpByR-k",
            },
            body: JSON.stringify({
              author_id: userId,
              instruction: projectName,
              data: formattedAnnotations,
            }),
          });
  
          if (response.ok) {
            console.log("Assistente treinado com sucesso!");
            // Você pode adicionar código adicional aqui, se necessário
          } else {
            console.error("Erro ao treinar assistente:", await response.text());
          }
        } catch (error) {
          console.error("Erro ao processar a solicitação:", error);
        }
      }
    } else {
      console.error("Nenhuma anotação para treinar o assistente.");
    }
  };
  
  
  const handleRotularTextoClick = () => {
    if (isRotularTextoSelected) {
      // Se já estiver selecionado, desselecione
      setIsRotularTextoSelected(false);
    } else {
      // Se não estiver selecionado, selecione
      setIsRotularTextoSelected(true);
    }
  };
  
  return (
    <>
    <div className="min-h-screen bg-[#111827] overflow-auto">
      <div className="mx-auto max-w-screen-xl h-full w-full">
        <div className="mt-1 mb-8 -ml-6">
          <div className="mb-12 ml-6 mt-14">
            <Link to="/" className="text-white hover:text-[#57B4B9] transition duration-300 flex items-center font-montserrat font-bold text-sm">
              <span className="mr-1">&#8592;</span> Voltar
            </Link>
          </div>
          <Heading
            title="Documentos PDF"
            description="Assistentes de IA para Documentos."
            icon={File}
            iconColor="text-[#EBA21C]"
            bgColor="bg-[#EBA21C]/10"
          />
        </div>
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
            className="rounded-lg border w-full p-4 px-3 md:px-6 focus-within:shadow-sm grid grid-cols-12 gap-2"
          >
            <div className="col-span-12 lg:col-span-10 mt-4 mb-4 flex items-center">
              <div className="m-0 p-0 flex-grow">
                <input
                  type="text"
                  className="border-0 outline-none focus-visible:ring-0 focus-visible:ring-transparent w-full p-[10px] bg-transparent placeholder-white font-montserrat text-base"
                  readOnly
                  placeholder={isUploadComplete ? `${userInfo ? `${userInfo.name}, já recebi os arquivos PDFs. Qual você quer que eu analise?` : ""}` : placeholderText}
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                />
              </div>
              {isUploadComplete && (
                <div className="ml-4">
                  <select
                    className="mb-4 border rounded p-2 w-[250px] mt-2 font-montserrat"
                    value={selectedFileIndex}
                    onChange={(e) => {
                      setSelectedFileIndex(Number(e.target.value));
                      setCurrentPage(1);
                    }}
                  >
                    <option className='font-montserrat' value={-1}>Selecione o PDF</option>
                    {selectedPDFs.map((pdfName, index) => (
                      <option key={index} value={index}>
                        {pdfName}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            {!isUploadComplete && isButtonVisible && (
              <div className="col-span-12 lg:col-span-2 mt-4 ml-6 flex space-x-2">
                <input
                  type="file"
                  accept=".pdf"
                  multiple
                  className="hidden"
                  onChange={handleFileChange}
                  id="fileInput"
                />
                <label
                  htmlFor="fileInput"
                  className="w-50 h-11 bg-[#03989E] hover:bg-[#03989E]/70 text-white text-base font-semibold rounded-lg p-2 cursor-pointer font-montserrat"
                >
                  Selecionar
                </label>
                {selectedFiles && selectedFiles.length > 0 && (
                  <button
                    type="submit"
                    className="w-34 h-11 bg-white hover:bg-gray-100 text-[#03989E] text-base font-semibold rounded-lg p-2 cursor-pointer font-montserrat"
                    onClick={handleUpload}
                  >
                    {loading ? (
                      <svg className="animate-spin h-5 w-5 mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018 8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      'Enviar'
                    )}
                  </button>
                )}
              </div>
            )}
          </form>
          {isUploadComplete && selectedFileIndex !== -1 && response && (
            <div className="mt-8 mb-14">
                <h2 className="text-2xl text-white font-semibold mb-4 font-montserrat">Texto Extraído do Arquivo PDF</h2>
                <div className="bg-white p-4 rounded-lg shadow">
                <h3 className="text-xl font-semibold mb-2 font-montserrat text-[#03989E]">
                    Pág {currentPage} - {selectedPDFs[selectedFileIndex]}
                </h3>
                <div>
                </div>
                <div>
                    <p onMouseUp={handleTextSelection} dangerouslySetInnerHTML={applyAnnotations(response.files[selectedFileIndex].pages[currentPage - 1].text)} />

                    {response.files[selectedFileIndex].pages.length > 1 && (
                    <div className="mt-4 flex justify-between">
                    <button
                        className="text-[#03989E] text-base font-semibold cursor-pointer font-montserrat"
                        onClick={() => {
                        if (currentPage > 1) {
                            setCurrentPage(currentPage - 1);
                        }
                        }}
                        disabled={currentPage === 1}
                    >
                        Página Anterior
                    </button>
                    <button
                        className="text-[#03989E] text-base font-semibold cursor-pointer font-montserrat"
                        onClick={() => {
                        if (currentPage < response.files[selectedFileIndex].pages.length) {
                            setCurrentPage(currentPage + 1);
                        }
                        }}
                        disabled={currentPage === response.files[selectedFileIndex].pages.length}
                    >
                        Próxima Página
                    </button>
                    </div>
                )}
                
                {selectedFileIndex !== -1 && (
                  <div className="flex justify-center mt-6 space-x-4">
                      {tools.map((tool, index) => (
                        <div key={index} className="text-center">
                          <button
                            className="flex flex-col items-center px-4 py-2 bg-[#03989E] text-white rounded-lg hover:bg-[#03989E]/90 transition duration-300"
                            onClick={() => {
                              handleRotularTextoClick(); // Chame a função aqui
                              tool.onClick();
                            }}
                          >
                            {tool.icon}
                            <span className="mt-1 text-white font-montserrat text-base">
                              {tool.name}
                            </span>
                            <span className="text-gray-200 font-montserrat text-xs">
                            {tool.creditsRequired} crédito{tool.creditsRequired === 1 ? '' : 's'}
                            </span>
                          </button>
                        </div>
                      ))}
                    </div>
              )}
               {isRotularTextoSelected && (
                <div className="mt-6 flex font-montserrat">
                  <select
                    className="border rounded p-2 w-full font-montserrat"
                    value={selectedLabel || ""}
                    onChange={(e) => setSelectedLabel(e.target.value)}
                  >
                    <option value="">Selecione um rótulo</option>
                    {labels.map((label, index) => (
                      <option key={index} value={label}>
                        {label}
                      </option>
                    ))}
                  </select>
                  <button
                    className="bg-[#ffffff] border text-black py-2 px-4 rounded ml-2 font-montserrat"
                    onClick={() => setIsAddingNewLabel(true)}
                  >
                    <Plus size={18} />
                  </button>
                  {isAddingNewLabel && (
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                      <div className="absolute inset-0 bg-gray-800 opacity-50"></div>
                      <div className="bg-white rounded-lg p-8 z-10">
                        <h3 className="text-lg font-semibold mb-4 font-montserrat">Adicionar Novo Rótulo</h3>
                        <input
                          type="text"
                          className="border rounded p-2 w-full mb-4 font-montserrat"
                          placeholder="Novo Rótulo"
                          value={newLabel}
                          onChange={handleNewLabelChange}
                        />
                        <div className="flex justify-end">
                          <button
                            className="bg-gray-300 hover:bg-gray-400 text-black py-2 px-4 rounded mr-2 font-montserrat"
                            onClick={() => setIsAddingNewLabel(false)}
                          >
                            Cancelar
                          </button>
                          <button
                            className="bg-[#03989E] hover:bg-[#03989E]/90 text-white py-2 px-4 rounded font-montserrat"
                            onClick={handleAddNewLabel}
                          >
                            Adicionar
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

                    {selectedLabel && selectedText && (
                      <button
                        className="bg-[#ffffff] border text-black py-1 px-2 rounded mt-3 font-montserrat"
                        onClick={handleAddAnnotation}
                      >
                        Adicionar
                      </button>
                    )}
                  </div>
                </div>
                <div>
                  {annotations.map((annotation, index) => (
                    <div key={index} className="bg-[#192339] p-2 mt-6 rounded-md">
                      <p className="font-semibold font-montserrat text-white">Rótulo: {annotation.label}</p>
                      <p className='font-montserrat text-white'>Texto: {annotation.text}</p>
                    </div>
                  ))}
                  {annotations.length > 0 && (
                    <div className="flex justify-center">
                                         <button
                      className="bg-[#FBFCF7] text-[#03989E] font-semibold font-montserrat py-1 px-4 rounded mt-4 flex justify-center"
                      onClick={handleDownloadExcel}
                    >
                      Download Excel
                    </button>
                    {hasAnnotations && (
                      <button
                        className="bg-[#03989E] text-white  font-bold font-montserrat py-1 px-4 rounded mt-4 ml-4 flex justify-center"
                        onClick={openCreateProjectPopup}
                      >
                        Salvar
                      </button>
                    )}
                    </div>

                  )}
                </div>
            </div>
            )}

        </div>
        
      </div>
    </div>
    <InputDialogPopup
        open={isPopupOpen}
        title="Adicionar Projeto"
        inputLabel="Nome do Projeto"
        //content={true}
        onSubmit={handleTrainAssistant}
        handleClose={() => setIsPopupOpen(false)}
      />
    </>
  );
};

export default Documents;

