import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import { Copy, X, Search, Download, Code } from 'lucide-react';
import { SiGooglemaps } from 'react-icons/si';
import axios from 'axios';
import * as XLSX from 'xlsx';
import Joyride from 'react-joyride';
import { useNavigate } from 'react-router-dom';
import { RiRobot2Line } from 'react-icons/ri';
import { MdUpload } from 'react-icons/md';

import { REACT_APP_SERVER_URL } from '../config/keys';
import { getUserFromLocalStorage } from '../services';
import ConfirmationPopup from './Global/ConfirmationPopup';

const API_URL = `${REACT_APP_SERVER_URL}/api/datastore/company-data`;

interface UserInfo {
    userId: string;
    name: string;
    availableCredits: number;
}

interface Activity {
    id: number;
    text: string;
    _id: string;
}

interface Member {
    role: {
        id: number;
        text: string;
    };
    since: string;
    person: {
        name: string;
        type: string;
        taxId: string;
        age: string;
        _id: string;
    };
    _id: string;
}

interface Address {
    country: {
        id: number;
        name: string;
    };
    municipality: number;
    street: string;
    number: string;
    details: string;
    district: string;
    city: string;
    state: string;
    zip: string;
    _id: string;
}

interface Registration {
    state: string;
    number: string;
    enabled: boolean;
    _id: string;
    status?: {
        id: number;
        text: string;
    };
}

interface CompanyInfo {
    mainActivity: {
        id: number;
        text: string;
    };
    _id: string;
    updated: string;
    taxId: string;
    name: string;
    alias: string | null;
    founded: string;
    equity: number;
    head: boolean;
    nature: {
        id: number;
        text: string;
        _id: string;
    };
    size: {
        id: number;
        acronym: string;
        text: string;
        _id: string;
    };
    statusDate: string;
    status: {
        id: number;
        text: string;
        _id: string;
    };
    address: Address;
    phones: {
        area: string;
        number: string;
        _id: string;
    }[];
    emails: {
        address: string;
        domain: string;
        _id: string;
    }[];
    sideActivities: Activity[];
    members: Member[];
    registrations: Registration[];
    originState?: string;
    author_id: string;
    situation: string;
    instruction: string;
    simples?: {
        optant: boolean;
        since: string;
    };
    simei?: {
        optant: boolean;
        since: string;
    };
    __v: number;
}

interface DownloadData {
    taxId: string;
    name: string;
    alias: string | null;
    address: string;
    details: string | null;
    district: string;
    zip: string;
    city: string;
    state: string;
    registrationsCount: number;
    lastDisabledRegistrationNumber: string | undefined;
    lastDisabledRegistrationStatus: string | undefined;
    lastDisabledRegistrationState: string | undefined;
    lastDisabledRegistrationSituation: string | undefined; // Added this line
    status: string | null;
    situation: string | null;
    optantSimples: string;
    optantSimei: string;
    creationDate: string;
}

interface DownloadMapsData {
    lat: string;
    lon: string;
    imageUrl: string;
    name: string;
    taxId: string;
}

const CompanySearch: React.FC = () => {
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [projectName, setProjectName] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [companies, setCompanies] = useState<CompanyInfo[]>([]);
    const [allCompanies, setAllCompanies] = useState<CompanyInfo[]>([]);
    const [download, setDownload] = useState<DownloadData[]>([]);
    const [downloadMaps, setDownloadMaps] = useState<DownloadMapsData[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [kpisData, setKpisData] = useState<any>({});
    const [tourFinished, setTourFinished] = useState(false);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [copiedText, setCopiedText] = useState('');
    const [companyIdToDelete, setCompanyIdToDelete] = useState<string | null>(
        null
    );
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const companiesPerPage = 3;
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [filteredCompanies, setFilteredCompanies] = useState<CompanyInfo[]>(
        []
    );

    const indexOfLastCompany = currentPage * companiesPerPage;
    const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
    const currentFilteredCompanies = filteredCompanies.slice(
        indexOfFirstCompany,
        indexOfLastCompany
    );

    const totalPages = Math.ceil(filteredCompanies.length / companiesPerPage);
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
    const [message, setMessage] = useState('');
    const [showSelectedMessage, setShowSelectedMessage] =
        useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {
        const filtered = companies.filter(company =>
            company.taxId.toLowerCase().includes(searchFilter.toLowerCase())
        );
        setFilteredCompanies(filtered);
    }, [companies, searchFilter]);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get<UserInfo>(
                    `${REACT_APP_SERVER_URL}/api/users/infos/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    setUserInfo(response.data);
                })
                .catch(error => {
                    console.error('Error fetching user information:', error);
                });
        }
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        axios
            .get<DownloadData[]>(
                `${REACT_APP_SERVER_URL}/api/datastore/download-company-data/${JSON.parse(
                    storedUserId!
                )}`
            )
            .then(response => {
                setDownload(response.data);
            })
            .catch(error => {
                console.error('Error fetching user information:', error);
            });
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const yyyy = today.getFullYear();
        const formattedToday = `${dd}/${mm}/${yyyy}`;

        axios
            .get<DownloadMapsData[]>(
                `${REACT_APP_SERVER_URL}/api/datastore/maps-data/${JSON.parse(
                    storedUserId!
                )}?startDate=${formattedToday}`
            )
            .then(response => {
                setDownloadMaps(response.data);
            })
            .catch(error => {
                console.error('Error fetching user information:', error);
            });
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        axios
            .get<CompanyInfo[]>(
                `${REACT_APP_SERVER_URL}/api/datastore/company-data/${JSON.parse(
                    storedUserId!
                )}`
            )
            .then(response => {
                console.log('Company Data:', response.data);
                setAllCompanies(response.data);
                setCompanies(response.data);
            })
            .catch(error => {
                console.error('Error fetching user information:', error);
            });
    }, []);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');

        if (storedUserId) {
            axios
                .get(
                    `${REACT_APP_SERVER_URL}/api/datastore/kpis-company-data/${JSON.parse(
                        storedUserId
                    )}`
                )
                .then(response => {
                    console.log('KPIs Data:', response.data);
                    setKpisData(response.data);
                })
                .catch(error => {
                    console.error('Error fetching KPIs data:', error);
                });
        }
    }, []);

    const handleNextPage = () => {
        setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    };

    const handlePrevPage = () => {
        setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    };

    const handleSearchClick = async () => {
        const user = getUserFromLocalStorage();

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');

            const sanitizedCNPJs = searchTerm
                .split(',')
                .map(cnpj => cnpj.replace(/\D/g, ''))
                .filter(Boolean);

            const formattedCNPJs = sanitizedCNPJs.map(cnpj =>
                cnpj.match(/^(\d{2})\.?(\d{3})\.?(\d{3})\/?(\d{4})-?(\d{2})$/)
                    ? cnpj.replace(
                          /^(\d{2})\.?(\d{3})\.?(\d{3})\/?(\d{4})-?(\d{2})$/,
                          '$1$2$3$4$5'
                      )
                    : cnpj
            );

            try {
                setLoading(true);
                const response = await axios.get(API_URL, {
                    params: {
                        cnpjs: formattedCNPJs.join(','),
                        author_id: userId,
                        instruction: projectName,
                    },
                });

                setLoading(false);
                window.location.reload();

                console.log('Response:', response.data);
            } catch (error) {
                console.error('Request error:', error);
                setLoading(false);
            }
        }
    };

    const handlePasteClick = () => {
        if (textareaRef.current) {
            navigator.clipboard.readText().then(pastedText => {
                const formattedText = pastedText
                    .trim()
                    .split('\n')
                    .map(cnpj => cnpj.trim())
                    .join(',\n');
                setSearchTerm(formattedText);
            });
        }
    };

    const handleDownloadExcel = () => {
        const rows = download.map(company => {
            return {
                DATA: company.creationDate || 'N/A',
                'RAZÃO SOCIAL': company.name || 'N/A',
                'NOME DE FANTASIA': company.alias || 'N/A',
                CNPJ: company.taxId || 'N/A',
                'ENDEREÇO COM NÚMERO': company.address || 'N/A',
                COMPLEMENTO: company.details || 'N/A',
                BAIRRO: company.district || 'N/A',
                CEP: company.zip || 'N/A',
                CIDADE: company.city || 'N/A',
                UF: company.state || 'N/A',
                'SITUAÇÃO CADASTRAL': company.status || 'N/A',
                'QUANTIDADE DE I.E': company.registrationsCount || 'N/A',
                'NÚMERO DA INSCRIÇÃO ESTADUAL':
                    company.lastDisabledRegistrationNumber || 'N/A',
                'STATUS DA INSCRIÇÃO':
                    company.lastDisabledRegistrationStatus || 'N/A',
                'UF DA IE': company.lastDisabledRegistrationState || 'N/A',
                'SITUAÇÃO DO CNPJ': company.situation || 'N/A',
                'OPTANTE SIMPLES': company.optantSimples || 'N/A',
                'OPTANTE SIMEI': company.optantSimei || 'N/A',
            };
        });

        const workbook = XLSX.utils.book_new();
        // Adicionando primeira aba
        const worksheet1 = XLSX.utils.json_to_sheet(rows);
        XLSX.utils.book_append_sheet(workbook, worksheet1, 'Informações');

        XLSX.writeFile(workbook, 'CNPJ.xlsx', { compression: true });
    };

    const handleDeleteCompany = (companyId: string) => {
        setCompanyIdToDelete(companyId);
        setShowConfirmationPopup(true);
    };

    const handleCancelDelete = () => {
        setCompanyIdToDelete(null);
        setShowConfirmationPopup(false);
    };

    const handleConfirmDelete = async () => {
        try {
            console.log('Deleting company with ID:', companyIdToDelete);
            await axios.delete(
                `${REACT_APP_SERVER_URL}/api/datastore/delete-company-data`,
                {
                    params: {
                        _id: companyIdToDelete,
                    },
                }
            );

            console.log('Company deleted successfully');
        } catch (error) {
            console.error('Error deleting company:', error);
        } finally {
            setCompanyIdToDelete(null);
            setShowConfirmationPopup(false);
            window.location.reload();
        }
    };

    const [userId, setUserId] = useState<string | null>(null);
    const [showPopup, setShowPopup] = useState<boolean>(false);

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            setUserId(JSON.parse(storedUserId));
        }
    }, []);

    const handleShowPopup = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleCopyClick = (text: string) => {
        navigator.clipboard.writeText(text);
        setCopiedText(`Texto copiado: ${text}`);

        // Limpar a mensagem após alguns segundos (opcional)
        setTimeout(() => {
            setCopiedText('');
        }, 3000);
    };

    const handleStarClick = (companyId: string) => {
        // Faça a requisição para o endpoint usando axios
        axios
            .get(
                `${REACT_APP_SERVER_URL}/api/datastore/company-map-data/${companyId}`
            )
            .then(response => {
                // Lide com a resposta como necessário
                console.log('Resposta do servidor:', response.data);

                // Aqui você pode adicionar lógica para exibir o mapa ou realizar outras ações com os dados da resposta

                // Redirecione para a nova rota em uma nova aba
                const newTab = window.open(
                    `/company-map/${companyId}`,
                    '_blank'
                );
                if (newTab) {
                    newTab.focus();
                }
            })
            .catch(error => {
                // Lide com o erro, se houver algum
                console.error('Erro na requisição:', error);
            });
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFiles(event.target.files);
        setShowSelectedMessage(true);
    };

    const handleSearchClickFile = async () => {
        const start = new Date().getTime();

        const user = getUserFromLocalStorage();

        if (user && user.userId) {
            const userId = user.userId.replace(/^"(.*)"$/, '$1');

            try {
                if (!selectedFiles || selectedFiles.length === 0) {
                    console.error('Nenhum arquivo selecionado.');
                    return;
                }

                setLoading(true);
                setShowSelectedMessage(false);

                const formData = new FormData();

                for (let i = 0; i < selectedFiles.length; i++) {
                    formData.append('excelFiles', selectedFiles[i]);
                }

                const endpoint = `${REACT_APP_SERVER_URL}/api/datastore/create-container-file-excel?author_id=${userId}`;

                const response = await axios.post(endpoint, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                setMessage(response.data.message); // Atualiza a mensagem com a resposta
            } catch (error) {
                console.error('Erro durante o envio de arquivos:', error);
                setMessage('Erro durante o envio de arquivos.'); // Define uma mensagem de erro padrão
            } finally {
                setLoading(false);
                setTimeout(() => {
                    window.location.reload();
                }, 2500);
            }
        }
    };

    const showMessage = (message: string) => {
        setMessage(message);
        setTimeout(() => {
            setMessage('');
        }, 2000);
    };
    return (
        <div className="min-h-screen bg-[#111827] py-10">
            <div>
                <Link
                    to="/"
                    className="text-white hover:text-[#39A2A7]/80 font-semibold text-sm font-montserrat"
                >
                    &#8592; Voltar
                </Link>
            </div>
            <div className="flex mt-8 space-x-4">
                <div className="flex flex-col items-center space-y-4">
                    <div className="bg-[#1f2937] rounded-lg p-6 w-[350px] h-full">
                        <div className="mb-2">
                            <p className="text-2xl text-white font-semibold cursor-default font-montserrat rounded">
                                Assistente de IA para <br />
                                <span className="bg-[#02989E] px-2 py-1 animate-pulse font-montserrat rounded text-black text-2xl">
                                    CNPJ
                                </span>
                            </p>
                        </div>
                        <p className="mb-4 text-sm font-montserrat text-white">
                            Monitore dados de pessoas jurídicas.
                        </p>
                        <div className="flex items-center mb-2">
                            <textarea
                                ref={textareaRef}
                                id="searchTerm"
                                name="searchTerm"
                                value={searchTerm}
                                onChange={e => setSearchTerm(e.target.value)}
                                className="flex-1 w-72 h-96 p-2 border bg-[#1f2937] rounded focus:outline-none font-montserrat text-white"
                                placeholder="Cole os CNPJs aqui"
                            ></textarea>
                            <button
                                onClick={handlePasteClick}
                                className="ml-2 bg-gray-200 text-black px-2 py-2 rounded hover:bg-gray-300 focus:outline-none focus:ring focus:border-blue-300 font-montserrat flex items-center"
                            >
                                <Copy size={10} className="font-montserrat" />
                            </button>
                        </div>
                        <button
                            onClick={() => handleSearchClick()}
                            id="search-button"
                            className={`mt-3 bg-[#111827] text-white px-6 py-2 rounded font-medium hover:bg-[#111827]/50 focus:outline-none focus:ring focus:border-blue-300 font-montserrat ${
                                loading ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                            disabled={loading}
                        >
                            {loading ? 'Aguarde...' : <Search size={24} />}
                        </button>
                        <button
                            type="button"
                            id="download"
                            className="mt-2 ml-3 bg-[#111827] text-white px-6 py-2 rounded font-medium hover:bg-[#111827]/50 focus:outline-none focus:ring focus:border-blue-300 font-montserrat"
                            onClick={handleDownloadExcel}
                        >
                            <Download />
                        </button>
                        <button
                            type="button"
                            id="updated"
                            className="mt-2 ml-3 bg-[#111827] text-white px-6 py-2 rounded font-medium hover:bg-[#111827]/50 focus:outline-none focus:ring focus:border-blue-300 font-montserrat"
                            onClick={handleShowPopup}
                        >
                            <Code />
                        </button>
                    </div>

                    <div className="bg-[#1f2937] rounded-lg p-6 w-[350px] h-full">
                        <div className="mb-4">
                            <p className="text-2xl text-white font-semibold cursor-default font-montserrat rounded">
                                Upload em Massa
                            </p>
                        </div>
                        {message && (
                            <div className="mb-2 text-base font-light font-montserrat text-yellow-300 italic transition-opacity duration-2000 ease-in-out opacity-100">
                                {message}
                            </div>
                        )}
                        <div className="flex flex-col items-center mb-2">
                            <label
                                htmlFor="fileInput"
                                className="cursor-pointer w-full"
                            >
                                <div
                                    className="bg-[#1f2937] border-dashed border border-white py-2 px-4 rounded-md text-white flex items-center justify-center font-montserrat"
                                    id="inputDocument"
                                >
                                    Selecionar Arquivos Excel
                                </div>
                            </label>
                            <input
                                type="file"
                                accept=".xls, .xlsx"
                                multiple
                                className="hidden"
                                id="fileInput"
                                onChange={handleFileChange}
                            />
                            {showSelectedMessage &&
                                selectedFiles &&
                                selectedFiles.length > 0 && (
                                    <p className="mt-2 text-base font-light font-montserrat text-yellow-300 italic">
                                        {`${selectedFiles.length} ${
                                            selectedFiles.length === 1
                                                ? 'documento selecionado'
                                                : 'documentos selecionados'
                                        }`}
                                    </p>
                                )}
                        </div>

                        <button
                            onClick={() => {
                                handleSearchClickFile();
                                showMessage('Aguarde...');
                            }}
                            id="search-button"
                            className={`mt-2 bg-[#111827] text-white px-10 py-2 rounded font-medium hover:bg-[#111827]/50 focus:outline-none focus:ring focus:border-blue-300 font-montserrat`}
                        >
                            <MdUpload size={20} />
                        </button>
                    </div>
                </div>
                <div>
                    <div
                        className="flex justify-center -mt-14 text-sm font-montserrat ml-6"
                        id="kpis"
                    >
                        <div>
                            <div className="mx-0 md:max-w-8xl lg:max-w-8xl xl:max-w-12xl">
                                <div className="grid grid-cols-1 md:grid-cols-6 gap-4">
                                    <div className="col-span-1 bg-[#1f2937] text-white p-4 rounded-lg shadow-md border border-[#2c3848]">
                                        <p className="text-lg text-muted-foreground font-montserrat ">
                                            CNPJs
                                        </p>
                                        <p className="text-xl font-semibold">
                                            {kpisData.count_cnpj || '-'}
                                        </p>
                                    </div>
                                    <div className="col-span-1 bg-[#1f2937] text-white p-4 rounded-lg shadow-md border border-[#2c3848]">
                                        <p className="text-lg font-montserrat">
                                            Ativos
                                        </p>
                                        <p className="text-xl font-semibold">
                                            {kpisData.count_ativa || '-'}
                                        </p>
                                    </div>
                                    <div className="col-span-1 bg-[#1f2937] text-white p-4 rounded-lg shadow-md border border-[#2c3848]">
                                        <p className="text-lg font-montserrat">
                                            Inaptos
                                        </p>
                                        <p className="text-xl font-semibold">
                                            {kpisData.count_inapta || '-'}
                                        </p>
                                    </div>
                                    <div className="col-span-1 bg-[#1f2937] text-white p-4 rounded-lg shadow-md border border-[#2c3848]">
                                        <p className="text-lg font-montserrat">
                                            Incrições
                                        </p>
                                        <p className="text-xl font-semibold">
                                            {kpisData.total_registros || '-'}
                                        </p>
                                    </div>
                                    <div className="col-span-1 bg-[#1f2937] text-white p-4 rounded-lg shadow-md border border-[#2c3848]">
                                        <p className="text-lg font-montserrat">
                                            Habilitados
                                        </p>
                                        <p className="text-xl font-semibold">
                                            {kpisData.count_enabled || '-'}
                                        </p>
                                    </div>
                                    <div className="col-span-1 bg-[#1f2937] text-white p-4 rounded-lg shadow-md border border-[#2c3848]">
                                        <p className="text-lg font-montserrat overflow-hidden overflow-ellipsis max-w-full whitespace-nowrap">
                                            Não Habilitados
                                        </p>
                                        <p className="text-xl font-semibold">
                                            {kpisData.count_disabled || '-'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 mb-0">
                        <input
                            type="text"
                            id="search"
                            value={searchFilter}
                            onChange={e => setSearchFilter(e.target.value)}
                            placeholder="Pesquisar CNPJ..."
                            className="w-full ml-5 h-8 px-4 pr-10 text-sm placeholder-gray-400 bg-[#1f2937] border rounded focus:outline-none focus:border-[#39A2A7] text-white"
                        />
                    </div>
                    <div
                        className="container mx-auto p-4 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-12"
                        id="companies"
                    >
                        {currentFilteredCompanies.map(company => (
                            <div
                                key={company._id}
                                className="bg-[#1f2937] text-white rounded-lg p-6 cursor-pointer mr-0 transition-transform hover:scale-105 w-96 md:w-72 h-auto -mt-0"
                            >
                                <div className="flex items-center">
                                    <div
                                        className="cursor-pointer flex items-center"
                                        onClick={() =>
                                            handleStarClick(company._id)
                                        }
                                        style={{
                                            pointerEvents: 'none',
                                            opacity: 0.5,
                                        }}
                                    >
                                        <SiGooglemaps
                                            size={20}
                                            className="text-yellow-500 hover:text-yellow-700 mb-0 -mt-2"
                                        />
                                        <span className="text-xs text-gray-200 font-montserrat mr-0 ml-0">
                                            Mapa
                                        </span>
                                    </div>

                                    <X
                                        size={24}
                                        className="cursor-pointer text-red-500 hover:text-red-700 mb-0 -mt-2 ml-40"
                                        onClick={() =>
                                            handleDeleteCompany(company._id)
                                        }
                                    />
                                </div>
                                <hr className="my-2 border-t border-gray-300" />
                                <div className="relative">
                                    <div className="flex items-center">
                                        <div className="w-full">
                                            <p
                                                className="text-sm font-medium font-montserrat mb-2 overflow-hidden overflow-ellipsis max-w-full whitespace-nowrap"
                                                onClick={() =>
                                                    handleCopyClick(
                                                        company.name || 'N/A'
                                                    )
                                                }
                                            >
                                                {company.name || 'N/A'}
                                            </p>
                                            <p
                                                className="text-gray-400 text-xs font-montserrat mb-0"
                                                onClick={() =>
                                                    handleCopyClick(
                                                        company.taxId || 'N/A'
                                                    )
                                                }
                                            >
                                                CNPJ: {company.taxId || 'N/A'}
                                            </p>
                                            <p
                                                className="text-gray-400 text-xs font-montserrat mb-0"
                                                onClick={() =>
                                                    handleCopyClick(
                                                        company.founded || 'N/A'
                                                    )
                                                }
                                            >
                                                Fundação:{' '}
                                                {company.founded || 'N/A'}
                                            </p>
                                            <p
                                                className="text-gray-400 text-xs font-montserrat mb-0"
                                                onClick={() =>
                                                    handleCopyClick(
                                                        company.nature?.text ||
                                                            'N/A'
                                                    )
                                                }
                                            >
                                                Tipo:{' '}
                                                {company.nature?.text || 'N/A'}
                                            </p>
                                            <p
                                                className="text-gray-400 text-xs font-montserrat mb-0"
                                                onClick={() =>
                                                    handleCopyClick(
                                                        `${
                                                            company.address
                                                                ?.city || 'N/A'
                                                        } - ${
                                                            company.originState ||
                                                            'N/A'
                                                        }`
                                                    )
                                                }
                                            >
                                                {`${
                                                    company.address?.city ||
                                                    'N/A'
                                                } - ${
                                                    company.originState || 'N/A'
                                                }`}
                                            </p>
                                            <p
                                                className="text-gray-400 text-xs font-montserrat mb-0 overflow-hidden overflow-ellipsis max-w-full whitespace-nowrap"
                                                onClick={() =>
                                                    handleCopyClick(
                                                        company.emails[0]
                                                            ?.address || 'N/A'
                                                    )
                                                }
                                            >
                                                Endereço de E-mail:{' '}
                                                {company.emails[0]?.address ||
                                                    'N/A'}
                                            </p>
                                            <p
                                                className="text-gray-400 text-xs font-montserrat mb-3"
                                                onClick={() =>
                                                    handleCopyClick(
                                                        company.status?.text ||
                                                            'N/A'
                                                    )
                                                }
                                            >
                                                Status:{' '}
                                                {company.status?.text || 'N/A'}
                                            </p>
                                            <p
                                                className="text-gray-400 text-xs font-montserrat mb-3"
                                                onClick={() =>
                                                    handleCopyClick(
                                                        `Atividade 1: ${
                                                            company
                                                                .sideActivities[0]
                                                                ?.text || 'N/A'
                                                        }`
                                                    )
                                                }
                                            >
                                                Atividade 1:{' '}
                                                {company.sideActivities[0]
                                                    ?.text || 'N/A'}
                                            </p>
                                            <p
                                                className="text-gray-400 text-xs font-montserrat mb-0"
                                                onClick={() =>
                                                    handleCopyClick(
                                                        `${
                                                            company.simples &&
                                                            company.simples
                                                                .optant
                                                                ? 'Optante'
                                                                : 'Não Optante'
                                                        }`
                                                    )
                                                }
                                            >
                                                <span className="font-bold text-xs">
                                                    Simples:
                                                </span>{' '}
                                                <span
                                                    className={`${
                                                        company.simples &&
                                                        company.simples.optant
                                                            ? 'bg-green-500 text-black px-1 py-0.5 rounded'
                                                            : 'bg-red-500 text-white px-1 py-0.5 rounded'
                                                    } text-xs font-montserrat font-semibold`}
                                                >
                                                    {company.simples &&
                                                    company.simples.optant
                                                        ? 'Optante'
                                                        : 'Não Optante'}
                                                </span>
                                            </p>
                                            <p
                                                className="text-gray-400 text-xs font-montserrat mb-0 mt-2"
                                                onClick={() =>
                                                    handleCopyClick(
                                                        `${
                                                            company.simei &&
                                                            company.simei.optant
                                                                ? 'Enquadrado'
                                                                : 'Não Enquadrado'
                                                        }`
                                                    )
                                                }
                                            >
                                                <span className="font-bold">
                                                    Simei:
                                                </span>{' '}
                                                <span
                                                    className={`${
                                                        company.simei &&
                                                        company.simei.optant
                                                            ? 'bg-green-500 text-black px-1 py-0.5 rounded'
                                                            : 'bg-red-500 text-white px-1 py-0.5 rounded'
                                                    } text-xs font-montserrat font-semibold`}
                                                >
                                                    {company.simei &&
                                                    company.simei.optant
                                                        ? 'Enquadrado'
                                                        : 'Não Enquadrado'}
                                                </span>
                                            </p>
                                            {company.registrations.length >
                                            0 ? (
                                                <div>
                                                    {company.registrations
                                                        .length === 1 ? (
                                                        <p
                                                            className="text-gray-100 text-xs font-montserrat font-bold mb-2 mt-4 -ml-2"
                                                            onClick={() =>
                                                                handleCopyClick(
                                                                    `Total de Inscrição: ${company.registrations.length}`
                                                                )
                                                            }
                                                        >
                                                            <span className=" text-white px-2 py-1 rounded">
                                                                Total de
                                                                Inscrição:{' '}
                                                                {
                                                                    company
                                                                        .registrations
                                                                        .length
                                                                }
                                                            </span>
                                                        </p>
                                                    ) : (
                                                        <p
                                                            className="text-gray-100 text-xs font-montserrat font-bold mb-2 -ml-2 mt-4"
                                                            onClick={() =>
                                                                handleCopyClick(
                                                                    `Total de Inscrições: ${company.registrations.length}`
                                                                )
                                                            }
                                                        >
                                                            <span className=" text-white px-2 py-1 rounded">
                                                                Total de
                                                                Inscrições:{' '}
                                                                {
                                                                    company
                                                                        .registrations
                                                                        .length
                                                                }
                                                            </span>
                                                        </p>
                                                    )}
                                                    {company.registrations.map(
                                                        (
                                                            registration,
                                                            index
                                                        ) => (
                                                            <div
                                                                key={index}
                                                                className={`mb-2 p-2 rounded ${
                                                                    registration.enabled
                                                                        ? 'bg-[#d6d7da]'
                                                                        : 'bg-[#d6d7da]'
                                                                }`}
                                                            >
                                                                <p
                                                                    className="text-xs font-montserrat text-black font-medium"
                                                                    onClick={() =>
                                                                        handleCopyClick(
                                                                            `Estado da Inscrição: ${
                                                                                registration.state ||
                                                                                'N/A'
                                                                            }`
                                                                        )
                                                                    }
                                                                >
                                                                    Estado da
                                                                    Inscrição:{' '}
                                                                    {registration.state ||
                                                                        'N/A'}
                                                                </p>
                                                                <p
                                                                    className="text-xs font-montserrat text-black font-medium"
                                                                    onClick={() =>
                                                                        handleCopyClick(
                                                                            `Número da Inscrição: ${
                                                                                registration.number ||
                                                                                'N/A'
                                                                            }`
                                                                        )
                                                                    }
                                                                >
                                                                    Número da
                                                                    Inscrição:{' '}
                                                                    {registration.number ||
                                                                        'N/A'}
                                                                </p>
                                                                <p
                                                                    className="text-xs font-montserrat text-black font-medium"
                                                                    onClick={() =>
                                                                        handleCopyClick(
                                                                            `Status da Inscrição: ${
                                                                                registration.enabled
                                                                                    ? 'Habilitado'
                                                                                    : 'Não habilitado'
                                                                            }`
                                                                        )
                                                                    }
                                                                >
                                                                    Status da
                                                                    Inscrição:
                                                                    <span
                                                                        className={`inline-block px-1 py-0.5 rounded ml-1 ${
                                                                            registration.enabled
                                                                                ? 'bg-green-500 text-black'
                                                                                : 'bg-red-500 text-white'
                                                                        }`}
                                                                    >
                                                                        {registration.enabled
                                                                            ? 'Habilitado'
                                                                            : 'Não habilitado'}
                                                                    </span>
                                                                </p>
                                                                {company.situation !==
                                                                    '' && (
                                                                    <div className="border border-solid border-blue-400 p-2 mb-2 mt-2 rounded">
                                                                        <p
                                                                            className="text-xs font-montserrat text-black font-medium"
                                                                            onClick={() =>
                                                                                handleCopyClick(
                                                                                    `Situação: ${company.situation}`
                                                                                )
                                                                            }
                                                                        >
                                                                            Situação:{' '}
                                                                            <strong>
                                                                                {
                                                                                    company.situation
                                                                                }
                                                                            </strong>
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            ) : (
                                                <p className="text-gray-300 text-xs font-montserrat mb-1">
                                                    Nenhum registro encontrado
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {copiedText && (
                            <div className="fixed bottom-4 right-4 bg-[#03689E] text-white font-semibold px-4 py-2 rounded">
                                {copiedText}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex justify-center mt-4 ml-62 text-gray-100 text-sm font-montserrat">
                <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    Anterior
                </button>
                <span className="mx-2 text-gray-100 text-xs font-montserrat">
                    {currentPage}
                </span>
                <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    Próxima
                </button>
            </div>
            {!tourFinished && (
                <Joyride
                    steps={[
                        {
                            target: '#searchTerm',
                            content: (
                                <div>
                                    <p>Bem-vindo ao Monitor de CNPJ com IA!</p>
                                    <br />
                                    <p>
                                        Na primeira etapa, cole um ou vários
                                        números de CNPJ no campo de texto
                                        abaixo.
                                    </p>
                                </div>
                            ),
                            placement: 'bottom',
                        },
                        {
                            target: '#search-button', // Substitua '.search-button' pela classe ou id do seu botão de pesquisa
                            content: (
                                <div>
                                    <p>
                                        Agora, clique no botão de pesquisa para
                                        iniciar a busca.
                                    </p>
                                </div>
                            ),
                        },
                        {
                            target: '#companies',
                            content: (
                                <div>
                                    <p>Visualize os resultados na dashboard.</p>
                                </div>
                            ),
                            placement: 'bottom',
                        },

                        {
                            target: '#download',
                            content: (
                                <div>
                                    <p>
                                        Para obter os dados, clique no botão de
                                        download.
                                    </p>
                                    <br />
                                    <p>
                                        Isso permitirá que você baixe as
                                        informações em um arquivo Excel.
                                    </p>
                                </div>
                            ),
                        },
                        {
                            target: '#updated',
                            content: (
                                <div>
                                    <p>
                                        Analise CNPJs que passaram por
                                        atualizações cadastrais.
                                    </p>
                                    <br />
                                    <p>
                                        Ao clicar neste botão, você poderá
                                        verificar os CNPJs que foram
                                        recentemente atualizados, apresentando
                                        mudanças em suas informações cadastrais.
                                    </p>
                                </div>
                            ),
                        },
                        {
                            target: '#search',
                            content: (
                                <div>
                                    <p>
                                        Realize pesquisas nos CNPJs salvos na
                                        sua base de dados.
                                    </p>
                                </div>
                            ),
                        },
                        {
                            target: '#kpis',
                            content: (
                                <div>
                                    <p>
                                        Indicadores com informações importantes
                                        dos CNPJs salvos na sua base de dados.
                                    </p>
                                </div>
                            ),
                        },
                    ]}
                    run={!tourFinished}
                    continuous
                    showSkipButton
                    callback={data => {
                        if (
                            data.status === 'finished' ||
                            data.status === 'skipped'
                        ) {
                            setTourFinished(true);
                        }
                    }}
                    styles={{
                        options: {
                            overlayColor: 'rgba(17, 24, 39, 0.9)',
                            primaryColor: '#39A2A7',
                            textColor: '#000000',
                            width: 400,
                            zIndex: 1000,
                        },
                        tooltip: {
                            fontFamily: 'Montserrat',
                        },
                        buttonNext: {
                            backgroundColor: '#39A2A7',
                            color: '#FFFFFF',
                            border: 'none',
                            borderRadius: '4px',
                            marginLeft: '0',
                        },
                    }}
                    locale={{
                        next: 'Próximo',
                        last: 'Último',
                        skip: 'Pular',
                        back: 'Anterior',
                    }}
                />
            )}
            {showConfirmationPopup && (
                <ConfirmationPopup
                    message="Tem certeza de que deseja excluir esta empresa?"
                    onCancel={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />
            )}
            {showPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-[#192339] bg-opacity-85">
                    <div className="relative w-full max-w-2xl p-6 rounded-xl shadow-xl bg-gradient-to-b from-[#0E1621] to-[#192339] max-h-[80vh] overflow-y-auto">
                        <h2 className="text-[#57B4B9] font-montserrat text-2xl font-semibold mb-2">
                            Documentação da API
                        </h2>
                        <p className="text-gray-300 font-montserrat text-sm mb-4">
                            Este endpoint permite consultar e atualizar
                            informações de empresas com base no número de CNPJ e
                            ID do usuário.
                        </p>

                        <div className="relative mb-4">
                            <div className="bg-gray-800 p-4 rounded-lg">
                                <h3 className="text-[#57B4B9] font-montserrat text-lg font-semibold mb-2">
                                    Base URL
                                </h3>
                                <p className="text-gray-300 font-montserrat text-sm">
                                    https://trial-plataform-server.braveocean-99c2da72.brazilsouth.azurecontainerapps.io/api/integration
                                </p>
                            </div>
                        </div>

                        <div className="relative mb-4">
                            <div className="bg-gray-800 p-4 rounded-lg">
                                <h3 className="text-[#57B4B9] font-montserrat text-lg font-semibold mb-2">
                                    Endpoint
                                </h3>
                                <p className="text-gray-300 font-montserrat text-sm">
                                    `GET /company`
                                </p>
                            </div>
                        </div>

                        <div className="relative mb-4">
                            <div className="bg-gray-800 p-4 rounded-lg">
                                <h3 className="text-[#57B4B9] font-montserrat text-lg font-semibold mb-2">
                                    Bearer Token
                                </h3>
                                <p className="text-gray-300 font-montserrat text-sm">
                                    f32x1v3mYTI7HzPwjqsq5FyINwNhxRugFHf6A8z61dE
                                </p>
                            </div>
                        </div>

                        <div className="relative mb-4">
                            <div className="bg-gray-800 p-4 rounded-lg">
                                <h3 className="text-[#57B4B9] font-montserrat text-lg font-semibold mb-2">
                                    Parâmetros de Consulta
                                </h3>
                                <ul className="list-disc pl-5 text-gray-300 font-montserrat text-sm">
                                    <li>
                                        <strong>cnpjs:</strong> Lista de CNPJs
                                        separados por vírgula (ex.:
                                        `12345678000195,98765432000196`)
                                    </li>
                                    <li className='mt-2'>
                                        <strong>user_id:</strong>
                                        <span className="block mt-1 px-3 py-2 bg-blue-600 text-white rounded-md font-montserrat text-sm font-semibold">
                                            {userId}
                                        </span>
                                        <p className="mt-1 text-gray-300 font-montserrat text-xs">
                                            Este é o ID do usuário que deve ser
                                            utilizado na requisição.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="relative mb-4">
                            <div className="bg-gray-800 p-4 rounded-lg">
                                <h3 className="text-[#57B4B9] font-montserrat text-lg font-semibold mb-2">
                                    Resposta
                                </h3>
                                <p className="text-gray-300 font-montserrat text-sm">
                                    A resposta incluirá uma lista de dados da
                                    empresa ou erros. A estrutura dos dados é a
                                    seguinte:
                                </p>
                                <ul className="list-disc pl-5 mt-2 text-gray-300 font-montserrat text-sm">
                                    <li>
                                        <strong>_id:</strong> O identificador
                                        único para a entrada de dados da empresa
                                    </li>
                                    <li className='mt-2'>
                                        <strong>user_id:</strong> O ID do
                                        usuário associado aos dados da empresa
                                        (ex.: `{userId}`)
                                    </li>
                                    <li className='mt-2'>
                                        <strong>active:</strong> Se os dados da
                                        empresa estão ativos
                                    </li>
                                    <li className='mt-2'>
                                        <strong>cnpj:</strong> O CNPJ da empresa
                                    </li>
                                    <li className='mt-2'>
                                        <strong>position:</strong> A posição
                                        geográfica (latitude e longitude) da
                                        empresa
                                    </li>
                                    <li className='mt-2'>
                                        <strong>cccInfo:</strong> Informações
                                        recuperadas do serviço CCC
                                    </li>
                                    <li className='mt-2'>
                                        <strong>rfInfo:</strong> Informações
                                        recuperadas do serviço RF
                                    </li>
                                    <li className='mt-2'>
                                        <strong>simplesInfo:</strong>{' '}
                                        Informações recuperadas do serviço
                                        Simples
                                    </li>
                                    <li className='mt-2'>
                                        <strong>date:</strong> A data em que os
                                        dados da empresa foram criados
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="relative mb-4">
                            <div className="bg-gray-800 p-4 rounded-lg">
                                <h3 className="text-[#57B4B9] font-montserrat text-lg font-semibold mb-2">
                                    Tratamento de Erros
                                </h3>
                                <p className="text-gray-300 font-montserrat text-sm">
                                    Se houver erros com CNPJs específicos, a
                                    resposta incluirá detalhes desses erros.
                                    Erros comuns incluem:
                                </p>
                                <ul className="list-disc pl-5 mt-2 text-gray-300 font-montserrat text-sm">
                                    <li className='mt-2'>
                                        <strong>
                                            Formato de CNPJ inválido:
                                        </strong>{' '}
                                        Se o formato do CNPJ estiver incorreto
                                    </li>
                                    <li className='mt-2'>
                                        <strong>Usuário não encontrado:</strong>{' '}
                                        Se o ID do usuário fornecido não existir
                                    </li>
                                    <li className='mt-2'>
                                        <strong>
                                            Erro interno do servidor:
                                        </strong>{' '}
                                        Se houver um problema com o servidor
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <button
                            onClick={handleClosePopup}
                            className="mt-4 px-6 py-3 text-white bg-red-600 hover:bg-red-700 rounded-lg font-montserrat text-base font-semibold"
                        >
                            Fechar
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CompanySearch;
