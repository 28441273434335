import React, { useEffect, useState, useRef } from 'react';

interface TypingEffectProps {
  text: string;
  onTypingComplete?: () => void;
  typingSpeed?: number;
}

const TypingEffect: React.FC<TypingEffectProps> = ({
  text,
  onTypingComplete,
  typingSpeed = 50,
}) => {
  const [displayText, setDisplayText] = useState<string>('');
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const displayRef = useRef<HTMLDivElement>(null);

  const preprocessText = (inputText: string) => {
    // Add double line breaks when encountering "{" or "}"
    return inputText.replace(/[{}]/g, '$&');
  };

  useEffect(() => {
    const preprocessedText = preprocessText(text);

    const typingInterval = setInterval(() => {
      if (currentIndex < preprocessedText.length) {
        const char = preprocessedText[currentIndex];
        if (char === '\n') {
          setDisplayText((prevText) => prevText + '<br/>'); // Use <br/> for line breaks
        } else {
          setDisplayText((prevText) => prevText + char);
        }
        setCurrentIndex((prevIndex) => prevIndex + 1);
      } else {
        clearInterval(typingInterval);
        onTypingComplete && onTypingComplete();
      }
    }, typingSpeed);

    return () => {
      clearInterval(typingInterval);
    };
  }, [text, currentIndex, typingSpeed, onTypingComplete]);

  return (
    <div
      ref={displayRef}
      style={{ maxHeight: '200px', overflowY: 'auto' }}
      dangerouslySetInnerHTML={{ __html: displayText }}
    />
  );
};

export default TypingEffect;
